import React, { useEffect, useState } from "react";
import { SmokOption } from "../helper/shared.model";

export interface CustomSelectProps<T extends SmokOption> {
  placeholder?: string;
  value?: T;
  onChange?: (value?: T) => void;
  options?: T[];
}

function CustomSelect<T extends SmokOption>(props: CustomSelectProps<T>) {
  const getSelectedOption = (options?: T[], value?: T) => {
    if (value === undefined) return undefined;

    let selectedOption = options?.find((o) => o._id === value._id);

    return selectedOption;
  };

  const [selectedOption, setSelectedOption] = useState<T | undefined>(
    getSelectedOption(props.options, props.value)
  );

  useEffect(() => {
    setSelectedOption(getSelectedOption(props.options, props.value));
  }, [props.value]);

  useEffect(() => {
    setSelectedOption(getSelectedOption(props.options, props.value));
  }, [props.options]);

  return (
    <div
      className="dropdown show lh-1 rounded"
      style={{ backgroundColor: "#f5f5f5" }}
    >
      <a
        className="custom-dropdown-toggle text-decoration-none text-secondary p-3 position-relative d-block"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {selectedOption ? (
          selectedOption?._label
        ) : (
          <span className="text-muted">{props.placeholder}</span>
        )}
      </a>
      <div
        className="dropdown-menu custom-dropdown-item"
        aria-labelledby="dropdownMenuButton"
        style={{
          maxHeight: "300px",
          overflowY: "scroll",
        }}
      >
        {props.options?.map((_option) => (
          <a
            key={_option._id}
            className="dropdown-item py-3"
            onClick={() => {
              setSelectedOption(_option);
              props.onChange && props.onChange(_option);
            }}
          >
            {_option._label}
          </a>
        ))}
      </div>
    </div>
  );
}

export default CustomSelect;
