import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { smokConstants } from "../../../../constant/constants";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions } from "../../../../helper/spinner.reducer";
import { getBrandPath } from "../../brand/screen/Brand";
import { Product } from "../../store/model/product.model";
import { productService } from "../../store/service/product.service";
import NearestShop from "./NearestShop";
import { storePath } from "../../store/screen/Store";

function SpecialProducts() {
  const [products, setProducts] = useState<Product[]>([]);
  const { dispatch } = useContext(SpinnerContext);
  let navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: actions.BLOCK,
    });

    productService
      .getProducts({
        is_featured_product: true,
      })
      .then((_products) => {
        setProducts(
          _products.results
            .filter(
              (x) =>
                x.featured_product_image !== null &&
                x.featured_product_image !== undefined
            )
            .slice(0, 2)
        );

        dispatch({
          type: actions.UNBLOCK,
        });
      });
  }, []);

  if (products.length === 0) {
    return <></>;
  }

  return (
    <>
      <NearestShop />
      <section>
        <div className="container container-xl">
          <h4 className="text-center mt-8 fs-18 text-uppercase font-weight-500">
            Онлайн шоп
          </h4>
          <Link to={storePath}>
            <p className="mb-8 fs-18 text-center brand-gradient font-weight-bolder text-uppercase">
              худалдан авах
            </p>
          </Link>
        </div>
      </section>
      <section>
        <div className="container container-lg-full">
          <div className="row px-5 px-sm-0">
            {products.map((_product, index) => (
              <div
                key={_product.sku}
                className={`col-12 col-md-6 px-0 px-md-3 mb-8 mb-sm-0 cursor-pointer`}
                onClick={() => {
                  navigate(getBrandPath(_product.sku));
                }}
              >
                <h4 className="text-center fs-14 mb-4 text-decoration-underline text-uppercase font-weight-500">
                  {_product.name}
                </h4>
                <div
                  className={`card border-0 banner banner-01 hover-zoom-in hover-shine border-radius ${
                    index % 2 === 0 ? "ml-md-3" : "mr-md-3"
                  }`}
                >
                  <img
                    src={smokConstants.getAbsoluteUrl(
                      _product.featured_product_image
                    )}
                  />
                  <div className="card-img-overlay d-inline-flex flex-column justify-content-end bg-overlay-gradient-02">
                    <div>
                      <a className="btn btn-link btn-light bg-transparent text-white hover-white border-0 p-0 fs-16 font-weight-600">
                        Дэлгэрэнгүй
                        <svg className="ml-2 icon icon-arrow-right">
                          <use xlinkHref="#icon-arrow-right" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default SpecialProducts;
