export interface Coupon {
  id: string;
  type: CouponType;
  sale_amount: number | null;
  sale_percentage: number | null;
  stock: number;
  start_date: Date;
  end_date: Date;
}

export interface CouponWriteUI extends Coupon {
  start_moment: moment.Moment;
  end_moment: moment.Moment;
}

export interface CouponQuery {
  id?: string;
  type?: string;
  dates?: moment.Moment[];
  start_date__gte?: Date;
  end_date__lte?: Date;
}

export enum CouponType {
  Fixed = "FI",
  Percentage = "PE",
}
