import { useState } from "react";
import { smokConstants } from "../constant/constants";

export interface CrossFadingImageProps {
  firstUrl: string;
  secondUrl: string;
  height: string;
}

function CrossFadingImage(props: CrossFadingImageProps) {
  const [first, setFirst] = useState(true);

  if (props.secondUrl == null) {
    return (
      <img
        className="mx-auto"
        src={smokConstants.getAbsoluteUrl(props.firstUrl)}
        style={{
          height: props.height,
          width: 'auto',
        }}
      />
    );
  }

  return (
    <div
      className="position-relative text-center hover-shine"
      onMouseOver={(e) => {
        setFirst(false);
        e.stopPropagation();
      }}
      onMouseOut={(e) => {
        setFirst(true);
        e.stopPropagation();
      }}
      style={{
        height: props.height,
        width: 'auto',
      }}
    >
      <img
        className="animation-opacity mx-auto"
        src={smokConstants.getAbsoluteUrl(props.firstUrl)}
        style={{
          height: props.height,
          width: 'auto',
          opacity: first ? 1 : 0,
        }}
      />
      <img
        className="animation-opacity mx-auto"
        src={smokConstants.getAbsoluteUrl(props.secondUrl)}
        style={{
          height: props.height,
          opacity: first ? 0 : 1,
          width: 'auto',
        }}
      />
    </div>
  );
}

export default CrossFadingImage;
