import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CartProductCounter, {
  CartProductCounterType,
} from "../../../../component/CartProductCounter";
import CustomDropDown from "../../../../component/CustomDropDown";
import { smokConstants } from "../../../../constant/constants";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import storageService from "../../../../helper/storage";
import { utils } from "../../../../helper/utils";
import { refreshCart } from "../../cart/screen/Cart";
import { getStockLabel } from "../../home/component/ProductItem";
import { checkoutPath } from "../../order/screen/Checkout";
import StorePay from "../component/StorePay";
import { CartProduct } from "../model/cart.model";
import { SmokImageReadUI, SmokImageType } from "../model/image.model";
import {
  BundledProductReadUI,
  ProductFormValue,
  ProductReadUI,
  ProductType,
  ProductVariationReadUI,
} from "../model/product.model";
import { CartContext } from "../service/cart.context";
import { cartService } from "../service/cart.service";
import { imageService } from "../service/image.service";
import { productService } from "../service/product.service";

export const productDetailPath = `/store/products/:sku`;

export const getProductDetailPath = (sku: string) =>
  productDetailPath.replaceAll(":sku", sku);

function ProductDetail() {
  const { sku } = useParams();
  const [product, setProduct] = useState<ProductReadUI>();
  const [productVariation, setProductVariation] =
    useState<ProductVariationReadUI>();
  const [images, setImages] = useState<SmokImageReadUI[]>([]);
  const { dispatch } = useContext(CartContext);
  const [form] = useForm();
  const [bundledProducts, setBundledProducts] = useState<
    BundledProductReadUI[]
  >([]);
  const freeShippingMin = storageService.getSetting("shipping-min");
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const [checkout, setCheckout] = useState(false);
  let navigate = useNavigate();
  const [fetching, setFetching] = useState(false);

  const onVariationSelected = (
    value: ProductVariationReadUI,
    product: ProductReadUI
  ) => {
    if (value && product?.type === ProductType.Variation) {
      dispatchSpinner({
        type: spinnerAction.BLOCK,
      });

      form.setFieldValue("count", 1);
      setProductVariation(value);
      setFetching(true);

      imageService
        .getImages({
          type: SmokImageType.Varation,
          product_id: value.product.sku,
          variation_id: value.variation.id,
        })
        .then((_images) => {
          setImages(_images);
        })
        .finally(() => {
          setFetching(false);
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    }
  };

  useEffect(() => {
    if (sku) {
      fetchImages(sku);

      dispatchSpinner({
        type: spinnerAction.BLOCK,
      });

      productService
        .getProduct(sku)
        .then((_product) => {
          setProduct(_product);

          if (_product.type === ProductType.Variation) {
            let variation = _product.product_variations.find(
              (x) => x.is_primary === true
            );

            if (variation) {
              form.setFieldsValue({
                variation: variation,
              });

              onVariationSelected(variation, _product);
            }

            dispatchSpinner({
              type: spinnerAction.UNBLOCK,
            });
          } else if (_product.type === ProductType.Bundled) {
            productService
              .getBundledProducts(sku)
              .then((bundledProducts) => {
                setBundledProducts(bundledProducts);

                let variations = bundledProducts.map((bundledProduct) => {
                  return bundledProduct.ref_product.product_variations?.find(
                    (x) => x.is_primary === true
                  );
                });

                form.setFieldsValue({ variations });
              })
              .finally(() => {
                dispatchSpinner({
                  type: spinnerAction.UNBLOCK,
                });
              });
          } else {
            dispatchSpinner({
              type: spinnerAction.UNBLOCK,
            });
          }
        })
        .catch(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    }
  }, [sku]);

  const fetchImages = (sku: string) => {
    setFetching(true);

    imageService
      .getImages({ type: SmokImageType.Product, product_id: sku })
      .then((_images) => {
        setImages(_images);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const getDropDownFormItemName = (_product: ProductReadUI, index?: number) => {
    if (product?.type === ProductType.Bundled) {
      return ["variations", index!];
    } else {
      return "variation";
    }
  };

  const getVariations = (_product: ProductReadUI, index?: number) => {
    _product?.product_variations.forEach((x) => {
      x._description = <span>Үлдэгдэл: {getStockLabel(x.stock)}</span>;
    });

    _product?.product_variations.forEach((_productVariation) => {
      if (_productVariation?.image) {
        _productVariation._image = smokConstants.getAbsoluteUrl(
          _productVariation?.image
        );
      }
    });

    return (
      <Form.Item
        name={getDropDownFormItemName(_product, index)}
        className="w-100"
        rules={[
          {
            required: true,
            message: "Бүтээгдэхүүний төрлийг сонгоно уу.",
          },
        ]}
      >
        <CustomDropDown
          magnify
          placeholder="Бүтээгдэхүүний төрөл"
          options={_product?.product_variations}
          onChange={(value) => {
            if (value && product) onVariationSelected(value, product);
          }}
        />
      </Form.Item>
    );
  };

  const getBundledProducts = () => {
    let _bundledProducts: BundledProductReadUI[] = [];

    bundledProducts.forEach((bundledProduct) => {
      if (bundledProduct.ref_product.type === ProductType.Variation) {
        for (let index = 0; index < bundledProduct.count; index++) {
          _bundledProducts.push(bundledProduct);
        }
      } else if (bundledProduct.ref_product.type === ProductType.Regular) {
        _bundledProducts.push(bundledProduct);
      }
    });

    return (
      <ul className="list-unstyled mb-0">
        {_bundledProducts.map((_bundledProduct, index) => (
          <li className="mb-4" key={_bundledProduct.id}>
            <div className="media align-items-center border rounded p-3 px-5">
              <div className="media-body d-flex justify-content-between">
                <div className="w-100">
                  <a className="text-decoration-none d-block mb-1">
                    {_bundledProduct.ref_product.name}
                  </a>

                  <div className="mb-2">
                    {utils.isProductWithSale(_bundledProduct.ref_product) ? (
                      <span>
                        <span className="fs-13 font-weight-500 text-decoration-through text-body pr-1">
                          {utils.formatCurrency(
                            _bundledProduct.ref_product.price
                          )}
                        </span>
                        <span className="text-secondary font-weight-bold ml-1">
                          {utils.formatCurrency(
                            _bundledProduct.ref_product.sale_price
                          )}
                        </span>
                      </span>
                    ) : (
                      <span className="text-secondary font-weight-bold ml-1">
                        {utils.formatCurrency(
                          _bundledProduct.ref_product.price
                        )}
                      </span>
                    )}
                  </div>
                  {_bundledProduct.ref_product.type ===
                    ProductType.Variation && (
                    <div className="d-flex align-items-center flex-wrap lh-12 mb-2">
                      {getVariations(_bundledProduct.ref_product, index)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const addCartProduct = (value: ProductFormValue) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    if (product?.type === ProductType.Variation) {
      if (value.count > value.variation.stock) {
        form.setFields([
          {
            name: "variation",
            errors: [
              `Үлдэгдэл хүрэлцэхгүй байна. Хамгийн ихдээ: ${value.variation?.stock} ширхэгийг авах боломжтой.`,
            ],
          },
        ]);

        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });

        return;
      }

      let cartProduct: CartProduct = {
        product_id: product.sku,
        product_variation_id: Number(value.variation.id),
        count: value.count,
        type: ProductType.Variation,
      };

      cartService
        .addCartProduct(cartProduct)
        .then((x) => {
          refreshCart(dispatchSpinner, dispatch);
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    } else if (product?.type === ProductType.Bundled) {
      let cartProducts: CartProduct[] = [
        {
          product_id: product.sku,
          count: value.count,
          type: ProductType.Bundled,
        },
      ];

      let inSufficient = false;
      let regularProducts = bundledProducts.filter(
        (x) => x.ref_product.type === ProductType.Regular
      );

      regularProducts.forEach((_bundledProduct) => {
        cartProducts.push({
          product_id: _bundledProduct.ref_product_id,
          bundled_product_id: _bundledProduct.id!,
          count: _bundledProduct.count * value.count,
          type: ProductType.Bundled,
        });
      });

      let variationProductCount = 0;

      bundledProducts.forEach((bp) => {
        if (bp.ref_product.type === ProductType.Variation) {
          variationProductCount += bp.count;
        }
      });

      if (value.variations !== undefined) {
        let _variations = value.variations.filter((v) => v !== undefined);

        if (variationProductCount != _variations.length) {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });

          return;
        }

        _variations?.forEach((variation, index) => {
          if (variation) {
            let _bundledProduct = bundledProducts.find(
              (x) => x.ref_product_id === variation.product.sku
            );

            if (_bundledProduct) {
              if (value.count > variation?.stock) {
                form.setFields([
                  {
                    name: ["variations", index],
                    errors: [
                      `Үлдэгдэл хүрэлцэхгүй байна. Хамгийн ихдээ: ${variation?.stock} ширхэгийг авах боломжтой.`,
                    ],
                  },
                ]);

                inSufficient = true;
              }

              cartProducts.push({
                product_id: _bundledProduct.ref_product_id,
                product_variation_id: Number(variation.id),
                bundled_product_id: _bundledProduct.id!,
                count: value.count,
                type: ProductType.Bundled,
              });
            }
          }
        });
      }

      if (inSufficient) {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });

        return;
      }

      cartService
        .bulkAddCartProducts(cartProducts)
        .then((x) => {
          refreshCart(dispatchSpinner, dispatch);
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    } else if (product?.type === ProductType.Regular) {
      if (product.stock < value.count) {
        form.setFields([
          {
            name: "count",
            errors: [
              `Үлдэгдэл хүрэлцэхгүй байна. Хамгийн ихдээ: ${product?.stock} ширхэгийг авах боломжтой.`,
            ],
          },
        ]);

        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });

        return;
      }

      let cartProduct: CartProduct = {
        product_id: product.sku,
        count: value.count,
        type: ProductType.Regular,
      };

      cartService
        .addCartProduct(cartProduct)
        .then((x) => {
          refreshCart(dispatchSpinner, dispatch);
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    }

    if (checkout) {
      navigate(checkoutPath);
    }
  };

  const getStock = () => {
    if (product?.type === ProductType.Regular) {
      return product?.stock;
    } else if (product?.type === ProductType.Variation) {
      if (productVariation === undefined) {
        return 0;
      }

      return productVariation?.stock;
    }
  };

  const getImages = () => {
    if (fetching === true) {
      return <></>;
    }

    return (
      <div className="galleries-product galleries-product-01 position-relative d-flex">
        <div
          className="slick-slider slider-for mx-0 pl-xl-5"
          data-slick-options={JSON.stringify({
            slidesToShow: 1,
            vertical: true,
            autoplay: false,
            dots: false,
            arrows: false,
            asNavFor: ".slider-nav",
            responsive: [{ breakpoint: 1200, settings: { vertical: false } }],
          })}
        >
          {images.map((image, index) => (
            <div className="box px-0" key={image.id}>
              <div className="card p-0 rounded-0 border-0">
                <img
                  src={smokConstants.getAbsoluteUrl(image.image)}
                  alt="product gallery"
                  className="w-100"
                  onLoad={() => {
                    if (images.length === index + 1) {
                      utils.initSlickSlider();
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <div
          className="slick-slider slider-nav mx-n1 mx-xl-0"
          data-slick-options={JSON.stringify({
            slidesToShow: images.length,
            vertical: true,
            autoplay: false,
            dots: false,
            arrows: false,
            asNavFor: ".slider-for",
            focusOnSelect: true,
            responsive: [{ breakpoint: 1200, settings: { vertical: false } }],
          })}
        >
          {images.map((image, index) => (
            <div className="box px-1 px-xl-0 py-2 pt-xl-0" key={image.id}>
              <img
                src={smokConstants.getAbsoluteUrl(image.image)}
                className="w-100"
                onLoad={() => {
                  if (index + 1 === images.length) {
                    utils.initSlickSlider();
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="py-11 product-details-layout-2">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 mb-8 mb-md-0 primary-gallery summary-sticky pr-xl-9"
              id="summary-sticky"
            >
              <div className="primary-summary-inner">{getImages()}</div>
            </div>
            <div className="col-md-6">
              {product && (
                <p className="d-flex align-items-center mb-3">
                  {utils.isProductWithSale(product) ? (
                    <span className="text-line-through">
                      {utils.formatCurrency(product.price)}
                    </span>
                  ) : (
                    <span className="fs-24 text-secondary font-weight-bold">
                      {utils.formatCurrency(product.price)}
                    </span>
                  )}
                  {utils.isProductWithSale(product) && (
                    <span className="fs-24 text-secondary font-weight-bold ml-3">
                      {utils.formatCurrency(product.sale_price)}
                    </span>
                  )}
                  {utils.isProductWithSale(product) && (
                    <span className="badge badge-primary fs-16 ml-4 font-weight-600 px-3">
                      {`${utils.getSalePercentage(product)}% OFF`}
                    </span>
                  )}
                </p>
              )}
              <h2 className="fs-24 mb-4">{product?.name}</h2>
              <p className="mb-4">{product?.short_description}</p>
              <ul className="list-unstyled mb-4">
                {product?.type === ProductType.Regular && (
                  <li className="row mb-1">
                    <span className="d-block col-4 col-lg-2 text-secondary font-weight-600 fs-14">
                      Үлдэгдэл:
                    </span>
                    <span className="d-block col-8 col-lg-10">
                      {getStockLabel(product.stock)}
                    </span>
                  </li>
                )}
                <li className="row">
                  <span className="d-block col-4 col-lg-2 text-secondary font-weight-600 fs-14">
                    Ангилал:
                  </span>
                  <span className="d-block col-8 col-lg-10">
                    {product?.category.name}
                  </span>
                </li>
              </ul>
              <Form
                form={form}
                onFinish={(value) => addCartProduct(value)}
                initialValues={{ count: 1 }}
              >
                {product?.type === ProductType.Variation && (
                  <div className="mb-4">
                    <label
                      className="text-secondary font-weight-600 mb-3"
                      htmlFor="number"
                    >
                      Төрөл:
                    </label>
                    {getVariations(product)}
                  </div>
                )}
                {product?.type === ProductType.Bundled && (
                  <div className="mb-4">
                    <label
                      className="text-secondary font-weight-600 mb-3"
                      htmlFor="number"
                    >
                      Багц бүтээгдэхүүнүүд:
                    </label>
                    {getBundledProducts()}
                  </div>
                )}
                <div className="row align-items-end no-gutters mx-n2">
                  <div className="col-sm-4 form-group px-2 mb-3">
                    <label
                      className="text-secondary font-weight-600 mb-3"
                      htmlFor="number"
                    >
                      Тоо:
                    </label>
                    <Form.Item name="count">
                      <CartProductCounter
                        max={getStock()}
                        type={CartProductCounterType.ProductDetail}
                        disabled={false}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-sm-8 mb-3 w-100 px-2">
                    <button
                      disabled={getStock() == 0}
                      onClick={() => setCheckout(false)}
                      type="submit"
                      className="btn btn-lg fs-18 btn-secondary btn-block h-60 bg-hover-primary border-0"
                    >
                      Сагслах
                    </button>
                  </div>
                </div>
                <button
                  disabled={getStock() == 0}
                  onClick={() => setCheckout(true)}
                  type="submit"
                  className="btn btn-lg fs-18 btn-secondary btn-block h-60 bg-hover-primary border-0 mb-6"
                >
                  Худалдан авах
                </button>
              </Form>
              <p className="mb-5">
                <span className="text-secondary">
                  <svg className="icon icon-Package fs-20 mr-2 align-text-bottom">
                    <use xlinkHref="#icon-Package" />
                  </svg>
                  Үнэгүй хүргэлт:
                </span>{" "}
                (
                {utils.formatCurrency(
                  Number(freeShippingMin?.value),
                  undefined,
                  undefined,
                  0
                )}{" "}
                дээш)
              </p>
              {product && <StorePay
                price={
                  utils.isProductWithSale(product)
                    ? product.sale_price!
                    : product.price
                }
              />}
            </div>
          </div>
        </div>
      </section>
      <section className="pt-10 pt-lg-12 pb-10 pb-md-6 pb-lg-11 border-top">
        <div className="container">
          <div className="collapse-tabs">
            <ul
              className="nav nav-pills mb-5 justify-content-center d-md-flex d-none"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active show fs-lg-34 fs-24 lh-129 font-weight-600 p-0"
                  id="pills-detail-tab"
                  data-toggle="pill"
                  href="#pills-detail"
                  role="tab"
                  aria-controls="pills-detail"
                  aria-selected="false"
                >
                  Бүтээгдэхүүний дэлгэрэнгүй
                </a>
              </li>
            </ul>
            <div className="tab-content bg-white-md shadow-none py-md-5 p-0">
              <div id="collapse-tabs-accordion-01">
                <div
                  className="tab-pane tab-pane-parent fade show active"
                  id="pills-detail"
                  role="tabpanel"
                >
                  <div className="card border-0 bg-transparent">
                    <div
                      className="card-header border-0 d-block d-md-none bg-transparent px-0 py-1"
                      id="headingDetails-01"
                    >
                      <h5 className="mb-0">
                        <button
                          className="btn lh-2 fs-18 py-1 px-6 shadow-none w-100 collapse-parent border text-primary"
                          data-toggle="false"
                          data-target="#detail-collapse-01"
                          aria-expanded="true"
                          aria-controls="detail-collapse-01"
                        >
                          Бүтээгдэхүүний дэлгэрэнгүй
                        </button>
                      </h5>
                    </div>
                    <div
                      id="detail-collapse-01"
                      className="collapsible collapse show"
                      aria-labelledby="headingDetails-01"
                      data-parent="#collapse-tabs-accordion-01"
                    >
                      <div
                        id="accordion-style-01"
                        className="accordion accordion-01 border-md-0 border p-md-0 p-6"
                      >
                        {product?.description && (
                          <p
                            className="text-justify"
                            style={{
                              fontSize: "15px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: product?.description,
                            }}
                          ></p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductDetail;
