import { Autocomplete } from "@react-google-maps/api";
import { useEffect, useState } from "react";

export interface CurrentLocationSearchBoxProps {
  onPlaceSelected: (value: google.maps.LatLngLiteral) => void;
  onLocationClicked: () => void;
  reset: boolean;
}

function CurrentLocationSearchBox(props: CurrentLocationSearchBoxProps) {
  const [reset, setReset] = useState<string>("false");
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();

  const onLoad = (ref: any) => setAutocomplete(ref);

  const onPlaceChanged = () => {
    let location = autocomplete?.getPlace().geometry?.location;

    if (location) {
      props.onPlaceSelected({
        lat: location.lat(),
        lng: location.lng(),
      });
    }
  };

  useEffect(() => {
    if (props.reset){
      setReset('true')
    } else {
      setReset('false');
    }
  }, [props.reset])
  

  return (
    <div className="p-4 text-light">
      <div className="row no-gutters">
        <Autocomplete
          key={reset}
          className="col-md-5 h-48px"
          options={{
            componentRestrictions: { country: "mn" },
          }}
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Та хайх хаягаа оруулна уу."
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              maxWidth: `400px`,
              width: "calc(100% - 40px)",
              height: `35px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
            }}
          />
        </Autocomplete>
        <div className="col-md-7">
          <button
            className="btn btn-sm btn-secondary px-3"
            onClick={props.onLocationClicked}
          >
            Одоогийн байршлыг олох
          </button>
        </div>
      </div>
    </div>
  );
}

export default CurrentLocationSearchBox;
