import React from "react";

function BackToTop() {
  return (
    <div className="position-fixed pos-fixed-bottom-right p-6 z-index-10">
      <a
        href="#"
        className="gtf-back-to-top text-decoration-none bg-white text-primary hover-white bg-hover-primary shadow p-0 w-48px h-48px rounded-circle fs-20 d-flex align-items-center justify-content-center"
        title="Back To Top"
      >
        <i className="fal fa-arrow-up"></i>
      </a>
    </div>
  );
}

export default BackToTop;
