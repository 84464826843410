import React from "react";
import { Link } from "react-router-dom";

export const privacyPolicyPath = "/privacy-policy";

function PrivaryPolicy() {
  return (
    <section className="py-md-11 pb-lg-13">
      <div className="container px-xxl-12 px-4 mt-lg-0 mt-8">
        <h1 className="fs-40 lh-13 mb-5">Нууцлалын журам</h1>
        <h5>НУУЦЛАЛЫН БОДЛОГО</h5>
        <p className="mb-4">
          <ol>
            <li>
              ТАНКО ХХК-ний салбар www.smok.mn онлайн дэлгүүрээр үйлчлүүлж
              байгаа хэрэглэгчдийнхээ мэдээллийн нууцлалыг нэн тэргүүнд тавих ба
              халдашгүй байдлыг бүхий л сүлжээний харилцаанд бүрэн ханган
              ажиллана. Нууцлалын бодлого гэдэгт SMOK.MN онлайн дэлгүүрт
              бүртгүүлсэн таны бүртгэлийн мэдээллийн нууцлалт, ашиглалт болон
              гуравдагч байгууллагад ашиглуулах нөхцөлүүдийг тусгасан болно.
              Энэхүү Мэдээллийн нууцлалын нөхцөлүүд нь манай www.smok.mn сайт
              дотор байрлах гуравдагч байгууллагын веб сайт, тэдний линкнүүдэд
              хамаарахгүй болно. Хэрвээ та манай сайтаар дамжин өөр веб сайт руу
              орж, тэнд бүртгэлийн мэдээллээ оруулсан бол энэ нь манай
              байгууллагын Мэдээллийн нууцлалын нөхцөлд хамаарахгүй болно.
            </li>
          </ol>
        </p>
        <h5>ТАНЫ ХУВИЙН МЭДЭЭЛЭЛ</h5>
        <p className="mb-4">
          <ol>
            <li>
              Таны манай веб хуудсанд оруулсан мэдээлэл манай мэдээллийн санд
              аюулгүйгээр хадгалагдах болно. Та өөрөө оруулсан мэдээллээ хэдийд
              ч харах, өөрчлөх эрхтэй. 
            </li>
            <li>
              Таны оруулсан мэдээллийг бид зөвхөн тантай холбоо барих, захиалгыг
              хүргэж өгөх, танд илүү тохиромжтой гэж үзсэн бүтээгдэхүүн
              үйлчилгээг санал болгох, таны худалдан авалтын түүх дээр үндэслэн
              илүү хөнгөлөлт урамшуулал санал болгох, цааш цаашдын үйлчилгээгээ
              сайжруулахад ашиглана.
            </li>
            <li>
              Таны захиалгатай холбоотой бүтээгдэхүүнийг хүргүүлэхдээ бид
              гуравдагч хүргэлтийн үйлчилгээ эрхлэгч компаниар гүйцэтгүүлдэг
              бөгөөд систем автоматаар таны мэйл хаяг, утасны дугаар, нэр, овог,
              дүүрэг, хороо, дэлгэрэнгүй хаяг, худалдан авсан бүтээгдэхүүний
              жагсаалт, үнийн дүн гэх мэдээллийг дамжуулж хүргэлтийн хүсэлт
              үүсгэдэг болно. Уг үйлдэл нь нууцлагдсан API ашиглан гүйцэтгэдэг
              бөгөөд систем хооронд мэдээлэл алдагдахаас сэргийлсэн болно.
            </li>
            <li>
              Захиалга баталгаажуулах буюу төлбөр төлөлтэй холбоотой төлбөрийн
              системийн үйлчилгээ үзүүлэгчид таны хувийн мэдээлэл
              дамжуулагддаггүй бөгөөд та өөрийн мэдээллээ тухайн төлбөр
              төлөгчийн систем дээр оруулах болно. Бид таны төлбөрийн системтэй
              холбоотой нууцлалтай мэдээлэл (Пин код, картын дугаар гэх мэт),
              төлбөр гүйцэтгэх нууц үг зэргийг авдаггүй бөгөөд манай системээр
              дамждаггүй, хадгалдаггүй болно.
            </li>
            <li>
              Та мэдээллээ устгуулахыг хүсэхээс бусад тохиолдолд таны хувийн
              мэдээлэл болон захиалгын түүхийг хугацаагүйгээр бид өөрсдийн
              системдээ хадгалах болно. Та хэдийд ч өөрийн бүртгэлээр нэвтрэн 
              <Link to={"/user/profile"}>хувийн мэдээлэл хэсэгт</Link> хандан
              харах, өөрчлөх боломжтой.
            </li>
          </ol>
        </p>
        <h5>МЭДЭЭЛЭЛИЙН ХАМГААЛАЛТ</h5>
        <p className="mb-4">
          <ol>
            <li>
              Бид таны оруулсан хувийн мэдээллийг чандлан хадгалж хэн нэгэн
              гуравдагч этгээдэд дамжуулахгүй бөгөөд манай мэдээллийн сан нь
              гадны халдлагуудаас хамгаалагдсан. Мөн та өөрөө мэдээллийн аюулгүй
              байдлаа хангах үүднээс гуравдагч этгээдэд нэвтрэх нэр, нууц үгээ
              дамжуулахаас зайлсхийж, www.smok.mn онлайн дэлгүүрээр үйлчлүүлээд
              гарахдаа бүртгэлээсээ гарах буюу баруун дээд талын /ГАРАХ/ товчин
              дээр дарж гарч байна уу.
            </li>
          </ol>
        </p>
        <h5>МЭДЭЭЛЭЛД ГУРАВДАГЧ ЭТГЭЭД НЭВТРЭХ АШИГЛАХ</h5>
        <p className="mb-4">
          <ol>
            <li>
              Доор дурдсанаас бусад нөхцөлөөр таны хувийн мэдээлэлийг бусдад
              дамжуулахгүй.
            </li>
            <li>
              Хүргэлтийн үйлчилгээ гүйцэтгэгч компанид мэдээллийг дамжуулж
              захиалгын хүргэлтийг гүйцэтгүүлэх
            </li>
            <li>
              Луйврын гүйлгээг олж илрүүлэх зорилгоор банкнуудад дамжуулах
            </li>
            <li>
              Луйврын гүйлгээг олж илрүүлэх зорилгоор цагдаа, хууль хяналтын
              байгууллагад дамжуулах
            </li>
            <li>
              Хууль бус үйлдэл хийн, эрэн сурвалжлагдаж байгаа хэрэглэгчийн
              мэдээллийг хуульд заасны дагуу шүүхийн шийдвэрийг үндэслэн засгийн
              газрын мөрдлөг, цагдаагийн байгууллага зэрэг газруудад гаргаж
              өгнө.
            </li>
            <li>
              Мөн хууль хяналтын байгууллагын албан ёсны шаардлага баримт дээр
              үндэслэн хамтран ажиллаж байгаа нөхцөлөөс бусад ямар ч нөхцөлд
              мэдээллийн нууцыг бусдад дамжуулахгүй чандлан хадгална.
            </li>
          </ol>
        </p>
        <h5>САЙТ БОЛОН МЭДЭЭЛЭЛИЙН НУУЦЛАЛД ӨӨРЧЛӨЛТ ОРУУЛАХ</h5>
        <p className="mb-4">
          <ol>
            <li>
              Бид ТАНКО ХХК-ний салбар www.smok.mn онлайн дэлгүүрийн нууцлалын
              баталгаа болон веб-ийн зарим хэсэгт ямар нэгэн анхааруулгатайгаар
              буюу анхааруулгагүйгээр өөрчлөлт оруулах эрхтэй бөгөөд та манай
              веб-ээр үйлчлүүлэх болгондоо манай веб-ийн нууцлалын бодлого буюу
              үйлчилгээний нөхцөлтэй байнга танилцаж байхын хүсье.
            </li>
            <li>
              Та өөрийн бүртгэл, түүнтэй холбоотой мэдээлэл болох захиалгын
              түүх, мэйл хаяг, утасны дугаар, хаяг, төлбөрийн хэрэгсэл ашиглалт
              гэх мэт мэдээллээ манай системээс устгуулахыг хүсвэл info@tanco.mn
              хаягт өөрийн бүртгэлтэй хаягаас мэйл хүсэлт илгээнэ үү. Үүнтэй
              холбоотой бид тантай утсаар холбогдож баталгаажуулсны үндсэн дээр
              ажлын 2 өдөрт багтаан таны хүсэлтийг биелүүлж тантай холбоотой
              бүхий л мэдээллийг системээс устгах болно.
            </li>
          </ol>
        </p>
      </div>
    </section>
  );
}

export default PrivaryPolicy;
