import React from "react";
import { utils } from "../../../../helper/utils";

export interface StorePayProps {
  price: number;
}

function StorePay(props: StorePayProps) {
  return (
    <div>
      <div
        data-toggle="modal"
        data-target="#storepay-modal"
        style={{
          cursor: "pointer",
          display: "table",
          width: "100%",
          background: "#0f6bc91c",
          padding: "10px 15px",
          lineHeight: "1.35",
          border: "solid 1px #1973cf3b",
          margin: "15px 0",
          borderRadius: "3px",
          fontSize: "12px",
          color: "#4a4b4a",
          fontWeight: 400,
        }}
      >
        <div style={{ display: "table-cell" }}>
          <img
            src="/glowing/images/store-pay-logo.png"
            alt=""
            style={{ height: "16px", marginBottom: "10px" }}
          />
          <br />
          Ямарч хүү, шимтгэлгүй{" "}
          <b>
            {utils.formatCurrency(
              Number(props?.price) / 4,
              undefined,
              undefined,
              0
            )} x 4
          </b>{" "}
          нөхцлөөр хуваан төлөх боломжтой.
        </div>
        <div
          style={{
            paddingLeft: "10px",
            fontSize: "28px",
            lineHeight: 1,
            verticalAlign: "middle",
            color: "#2075ec",
            display: "table-cell",
          }}
        >
          <svg className="icon icon-help">
            <use xlinkHref="#icon-help" />
          </svg>
        </div>
      </div>
      <div
        className="modal storepay-modal"
        id="storepay-modal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <img src="/glowing/images/store-pay-modal.png" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StorePay;
