import React from "react";

function SocialNetworkLinks() {
  return (
    <>
      <li className="list-inline-item lh-1 mr-4">
        <a
          target="_blank"
          href="https://www.facebook.com/SmokMongoliaExclusive"
        >
          <i className="fab fa-facebook-f" style={{ color: "#2E58B2" }} />
        </a>
      </li>
      <li className="list-inline-item lh-1">
        <a target="_blank" href="https://www.instagram.com/smokmongolia/">
          <i className="fab fa-instagram" style={{ color: "#DD4B39" }} />
        </a>
      </li>
    </>
  );
}

export default SocialNetworkLinks;
