import moment from "moment";
import { smokConstants } from "../constant/constants";
import { CouponType } from "../module/private/order/model/coupon.model";
import { OrderStatus } from "../module/private/order/model/order.model";
import { Page } from "../module/private/settings/model/settings.model";
import {
  Product,
  ProductType,
} from "../module/private/store/model/product.model";

function formatCurrency(
  value: number | undefined,
  prefix = "₮",
  suffix = "",
  fractionDigits = 2
) {
  value = Number(value ?? 0);
  let formatted;

  if (fractionDigits > 0) {
    formatted = value.toFixed(fractionDigits);
  } else {
    formatted = `${value}.`;
  }

  formatted = formatted.replace(/\d(?=(\d{3})+\.)/g, "$&,");

  if (fractionDigits == 0) {
    formatted = formatted.substring(0, formatted.length - 1);
  }

  return `${prefix}${formatted}${suffix}`;
}

function formatDate(date: Date): string {
  return moment(date).format(smokConstants.DATE_FORMAT);
}

function formatDateTime(date: Date): string {
  return moment(date).format(smokConstants.DATE_TIME_FORMAT);
}

function formatOrderStatus(status: OrderStatus): string {
  switch (status) {
    case OrderStatus.New:
      return "Шинэ захиалга үүслээ.";
    case OrderStatus.Callback:
      return "Төлбөр хүлээгдэж байна.";
    case OrderStatus.Approved:
      return "Төлбөр баталгаажлаа.";
    case OrderStatus.Declined:
      return "Төлбөр амжилтгүй.";
    case OrderStatus.PickPackFailed:
      return "Хүргэлтийн захиалгыг үүсгэхэд алдаа гарлаа.";
    case OrderStatus.PickPackSuccess:
      return "Хүргэлтийн захиалгыг амжилттай үүсгэлээ.";
    case OrderStatus.EbarimtFailed:
      return "И-баримт үүсгэхэд алдаа гарлаа.";
    case OrderStatus.Completed:
      return "И-баримтыг амжилттай илгээлээ.";
    case OrderStatus.ReadyToDeliver:
      return "Хүргэлтэнд гарахад бэлэн боллоо.";
    case OrderStatus.Shipped:
      return "Хүргэлтэнд гарсан.";
    case OrderStatus.Delivered:
      return "Хүргэгдсэн.";
    case OrderStatus.Cancelled:
      return "Цуцласан";
    default:
      return "Алдаатай";
  }
}

function getFileName(url: string): string {
  return url.split("/").pop() ?? "";
}

function getSalePercentage(_product: Product): number {
  return (
    100 -
    Math.ceil((Number(_product.sale_price) * 100) / Number(_product.price))
  );
}

function isProductWithSale(_product: Product): boolean {
  const nowDate = new Date();

  if (!_product.sale_price) {
    return false;
  }

  if (_product.sale_start_date && _product.sale_end_date) {
    if (
      moment(_product.sale_start_date).toDate() <= nowDate &&
      moment(_product.sale_end_date).toDate() >= nowDate
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

function formatProductType(type: ProductType): string {
  switch (type) {
    default:
    case ProductType.Regular:
      return "Жирийн";
    case ProductType.Variation:
      return "Олон төрөлт";
    case ProductType.Bundled:
      return "Багц";
  }
}

function initSlickSlider() {
  setTimeout(() => {
    (window as any).app.slickSlider.init();
  });
}

function initAll() {
  setTimeout(() => {
    (window as any).app.init();
  }, 500);
}

function formatDestination(destination?: number) {
  if (!destination) {
    return "0.0";
  } else {
    return (destination / 1000)?.toFixed(1);
  }
}

function formatCouponType(type: CouponType): string {
  switch (type) {
    case CouponType.Fixed:
      return "Үнийн дүнгээр хямдруулах";
    case CouponType.Percentage:
      return "Хувиар хямдруулах";
  }
}

function getHtml(pages: Page[], key: string): string {
  let _page = pages.find((_page) => _page.key === key);
  return _page ? _page?.html : "";
}

function calcCrow(lat1: number, lon1: number, lat2: number, lon2: number) {
  let R = 6371; // km
  let dLat = toRad(lat2 - lat1);
  let dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d;
}

function toRad(value: number) {
  return (value * Math.PI) / 180;
}

const getStep = (status: OrderStatus): number => {
  switch (status) {
    case OrderStatus.New:
      return 1;
    case OrderStatus.Callback:
      return 2;
    case OrderStatus.Approved:
      return 3;
    case OrderStatus.Declined:
      return 2;
    case OrderStatus.PickPackSuccess:
      return 4;
    case OrderStatus.PickPackFailed:
      return 3;
    case OrderStatus.Completed:
      return 5;
    case OrderStatus.ReadyToDeliver:
      return 6;
    case OrderStatus.EbarimtFailed:
      return 4;
    default:
      return 0;
  }
};

declare global {
  interface Window {
    app: any;
  }
}

export const utils = {
  isProductWithSale,
  formatCurrency,
  formatDate,
  formatDateTime,
  getFileName,
  getSalePercentage,
  initSlickSlider,
  initAll,
  formatDestination,
  getHtml,
  formatOrderStatus,
  formatCouponType,
  formatProductType,
  calcCrow,
  getStep,
};
