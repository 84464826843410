import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { smokToast } from "../helper/toast";
import { SmokRegistration } from "../module/private/user/model/auth.model";
import { authService } from "../module/private/user/service/auth.service";
import { actions as spinnerAction } from "../helper/spinner.reducer";
import { useContext, useState } from "react";
import { SpinnerContext } from "../helper/spinner.context";
import { Tab } from "./AuthWrapper";
import storageService from "../helper/storage";
import SocialButtons from "./SocialButtons";

export interface SignUpProps {
  onChangeTab: (tab: Tab) => void;
  onComplete: () => void;
}

function SignUp(props: SignUpProps) {
  let [form] = useForm();
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const [alert, setAlert] = useState(false);

  const onFinish = (values: SmokRegistration) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    authService
      .signUp(values)
      .then((x) => {
        smokToast.success(
          "Хэрэглэгчийн амжилттай бүртгэлээ. И-мэйл хаягаа баталгаажуулна уу."
        );

        storageService.clearUserInfoId();

        setAlert(true);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  return (
    <div>
      <h4 className="fs-34 text-center mb-6">Бүртгүүлэх</h4>
      <p className="text-center fs-16 mb-7">
        Та бүртгэлтэй юу?{" "}
        <a
          className="text-secondary border-bottom text-decoration-none"
          onClick={() => props.onChangeTab(Tab.SignIn)}
        >
          Нэвтрэх
        </a>
      </p>
      {alert && (
        <div className="alert alert-warning" role="alert">
          И-мэйл хаягаа баталгаажуулна уу.
        </div>
      )}
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          className="mb-6"
          rules={[
            {
              required: true,
              message: "И-мэйл хаягийг оруулна уу.",
            },
          ]}
        >
          <input
            type="email"
            className="form-control border-0"
            placeholder="И-мэйл хаяг"
          />
        </Form.Item>
        <Form.Item
          name="password"
          className="mb-6"
          rules={[
            {
              required: true,
              message: "Нууц үгийг оруулна уу.",
            },
          ]}
        >
          <input
            type="password"
            className="form-control border-0"
            placeholder="Нууц үг"
          />
        </Form.Item>
        <Form.Item
          name="re_password"
          className="mb-6"
          rules={[
            {
              required: true,
              message: "Нууц үгийг давтан оруулна уу.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Нууц үгээс өөр байна."));
              },
            }),
          ]}
        >
          <input
            type="password"
            className="form-control border-0"
            placeholder="Нууц үг давтах"
          />
        </Form.Item>
        <button
          type="submit"
          className="btn btn-secondary btn-block bg-hover-primary border-hover-primary"
        >
          Бүртгүүлэх
        </button>
        <div className="border-bottom mt-6" />
        <div className="text-center mt-n2 lh-1 mb-4">
          <span className="fs-14 bg-white lh-1 mt-n2 px-4">
            сошиал хаягуудаар бүртгүүлэх
          </span>
        </div>
      </Form>
      <SocialButtons onComplete={props.onComplete} />
    </div>
  );
}

export default SignUp;
