import "moment";
import { SmokOption } from "../../../../helper/shared.model";
import { VariationReadUI } from "./variation.model";

export interface Category {
  id: number;
  name: string;
}

export enum ProductType {
  Regular = "RE",
  Variation = "VA",
  Bundled = "BU",
  Coupon = "CO",
}

export interface ProductQuery {
  types?: ProductType[];
  type__in?: string;
  name__icontains?: string;
  category_id?: number;
  page?: number;
  is_featured_product?: boolean;
  is_special_offer?: boolean;
}

export interface BundledProduct {
  id?: number;
  product_id: string;
  ref_product_id: string;
  count: number;
}

export interface BundledProductReadUI extends BundledProduct {
  key?: number;
  product: ProductReadUI;
  ref_product: ProductReadUI;
}

export interface Product {
  sku: string;
  category: Category;
  type: ProductType;
  name: string;
  image: string;
  image2: string;
  is_featured_product: boolean;
  is_special_offer: boolean;
  featured_product_image: string;
  special_offer_image: string;
  special_offer_image2: string;
  is_published: boolean;
  is_private: boolean;
  description: string;
  short_description: string;
  product_order: number;
  price: number;
  sale_price?: number;
  sale_start_date: Date | null;
  sale_end_date: Date | null;
  category_id: number;
  created_date: Date;
  stock: number;
  is_brand_product: boolean;
}

export interface ProductWriteUI extends Product {
  sale_dates?: (moment.Moment | undefined)[];
}

export interface ProductReadUI extends Product {
  key: string;
  index: number;
  exists?: boolean;
  product_variations: ProductVariationReadUI[];
}

export interface ProductVariation {
  id?: number;
  image?: string;
  is_published: boolean;
  is_private: boolean;
  is_primary: boolean;
  stock: number;
}

export interface ProductVariationWriteUI extends ProductVariation {
  variation_id: number;
  product_id: string;
}

export interface ProductVariationReadUI extends ProductVariation, SmokOption {
  key: number;
  variation: VariationReadUI;
  product: ProductReadUI;
  stock: number;
}

export interface ProductVariationQuery {
  variation_id?: number;
  product_id?: string;
  is_published?: boolean;
  is_private?: boolean;
  is_primary?: boolean;
}

export interface ProductFormValue {
  variation: ProductVariationReadUI;
  variations: ProductVariationReadUI[];
  count: number;
}
