import { SmokStore } from "../model/store.model";

export interface StoreLocationProps {
  key?: string;
  store: SmokStore;
  onClick: () => void;
}

function StoreLocation(props: StoreLocationProps) {
  return (
    <div className="py-2 px-4 store-location">
      <span className="fs-16 text-secondary font-weight-600">
        {props.store.name}
      </span>
      <div className="row mt-2">
        <div className="col-6 align-self-center">
          <span className="fs-13 font-weight-400">
            {props.store.distance.toFixed(3)} км
          </span>
        </div>
        <div className="col-6">
          <a
            className="btn btn-secondary btn-block btn-sm bg-hover-primary border-hover-primary"
            onClick={props.onClick}
          >
            Байршил харах
          </a>
        </div>
      </div>
    </div>
  );
}

export default StoreLocation;
