import { useContext } from "react";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import { smokToast } from "../../../../helper/toast";
import { lotteryService } from "../service/lottery.service";

const LotteryQrReader = () => {
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);

  const handleScan = (b_code: string | null) => {
    if (b_code != null && /^[A-Z0-9]{32}$/.test(b_code)) {
      dispatchSpinner({
        type: spinnerAction.BLOCK,
      });

      lotteryService
        .getLotteryRecord(b_code)
        .then((record) => {
          console.log(record);

          smokToast.success(
            `${record.name} төхөөрөмжийн B кодыг амжилттай уншлаа.`
          );
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    }
  };

  const handleError = (error: any) => {
    smokToast.error("B кодыг уншихад алдаа гарлаа.");
  };

  return (
    <>
    </>
  );
};

export default LotteryQrReader;
