import { useEffect, useRef } from "react";
import storageService from "../helper/storage";

export interface AgeWarningProps {}

function AgeWarning(props: AgeWarningProps) {
  let openLinkRef = useRef<HTMLAnchorElement>(null);
  let closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let show = storageService.getShowAgeRestriction();

    if (show) {
      openLinkRef.current?.click();
    }
  }, []);

  const onOk = () => {
    storageService.setShowAgeRestriction(false);
    closeButtonRef.current?.click();
  };

  const onCancel = () => {
    window.location.href = "https://google.mn";
  };

  return (
    <>
      <a ref={openLinkRef} data-toggle="modal" data-target="#age-restriction" />
      <div
        className="modal"
        id="age-restriction"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <button
          ref={closeButtonRef}
          type="button"
          className="close d-none"
          data-dismiss="modal"
        ></button>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center py-6 px-8">
              <img
                style={{
                  width: "150px",
                }}
                src="/glowing/images/smok-logo-black-2.png"
                alt="SMOK"
                className="mb-8"
              />
              <h5>Та 21 хүрсэн үү?</h5>
              <div className="row mt-8">
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block"
                    onClick={() => onOk()}
                  >
                    Тийм
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-outline-dark btn-block"
                    onClick={() => onCancel()}
                  >
                    Үгүй
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgeWarning;
