import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { helper } from "../helper/helper";
import { MenuItem } from "../helper/shared.model";
import storageService from "../helper/storage";
import { emptyBrandPath } from "../module/private/brand/screen/Brand";
import { getHomeTabPath, homePath } from "../module/private/home/screen/Home";
import { CartContext } from "../module/private/store/service/cart.context";
import { authService } from "../module/private/user/service/auth.service";
import { Tab } from "./AuthWrapper";
import { aboutUsPath } from "../module/private/about-us/screen/AboutUs";

export interface HeaderProps {
  menus: MenuItem[];
}

function Header(props: HeaderProps) {
  const location = useLocation();
  const fixedHeaderPaths = [
    homePath,
    getHomeTabPath(Tab.SignIn),
    getHomeTabPath(Tab.SignUp),
    getHomeTabPath(Tab.ResetPassword),
    emptyBrandPath,
    aboutUsPath
  ];

  const isSmallDevice = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const { state } = useContext(CartContext);

  function getMenus() {
    return props.menus
      .filter((x) => x.view !== "slider-menu")
      .map((item, index) => {
        if (item.children.length > 0) {
          return (
            <li
              key={index}
              aria-haspopup="true"
              aria-expanded="false"
              className="nav-item dropdown-item-home dropdown py-2 py-xl-7 sticky-py-xl-6 px-0 px-xl-5"
            >
              {item.path ? (
                <Link
                  className="nav-link dropdown-toggle p-0"
                  to={item.path}
                  data-toggle="dropdown"
                >
                  {item.title}
                  <span className="caret" />
                </Link>
              ) : (
                <a>{item.title}</a>
              )}
              <div className="dropdown-menu x-animated x-fadeInUp pt-3 pb-0 pb-xl-3 mw-260px">
                <div className="row px-7">
                  <div className="col-6">
                    <ul className="p-0">
                      {item.children.map((child, index) => {
                        return (
                          <li
                            key={index}
                            className="dropdown-item px-0"
                          >
                            <Link className="dropdown-link" to={child.path!}>
                              {child.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          );
        } else {
          return (
            <li
              key={index}
              aria-haspopup="true"
              aria-expanded="false"
              className="nav-item py-2 py-xl-7 sticky-py-xl-6 px-0 px-xl-5"
            >
              <Link className="nav-link p-0" to={item.path!}>
                {item.title}
              </Link>
            </li>
          );
        }
      });
  }

  return (
    <header
      className={`main-header navbar-light dark header-sticky header-sticky-smart ${
        fixedHeaderPaths.find((x) => location.pathname.startsWith(x)) &&
        isSmallDevice
          ? "position-absolute fixed-top"
          : "navbar-fixed"
      }`}
    >
      <div className="sticky-area">
        <div className="container container-xxl">
          <nav className="navbar navbar-expand-xl px-0 d-block">
            <div className="d-none d-xl-block">
              <div className="d-flex align-items-center flex-nowrap">
                <div className="w-50">
                  <div className="d-flex mt-3 mt-xl-0 align-items-center w-100">
                    <Link className="navbar-brand d-inline-block py-0" to="/">
                      <img
                        src="/glowing/images/smok-logo-white.png"
                        alt="SMOK"
                      />
                    </Link>
                  </div>
                </div>
                <div className="flex-shrink-0 mx-auto">
                  <ul className="navbar-nav hover-menu main-menu px-0 mx-xl-n5">
                    {getMenus()}
                  </ul>
                </div>
                <div className="w-50">
                  <div className="d-flex align-items-center justify-content-end">
                    <ul className="navbar-nav flex-row justify-content-xl-end d-flex flex-wrap text-body py-0 navbar-right">
                      {!helper.isAuthenticated() ? (
                        <li className="nav-item py-2 py-xl-7 sticky-py-xl-6 px-0 px-xl-1">
                          <a
                            className="nav-link"
                            data-toggle="modal"
                            data-target="#sign-in"
                          >
                            <svg className="icon icon-user-light">
                              <use xlinkHref="#icon-user-light" />
                            </svg>
                          </a>
                        </li>
                      ) : (
                        <li
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="nav-item dropdown-item-home dropdown py-2 py-xl-7 sticky-py-xl-6 px-0 px-xl-1"
                        >
                          <a
                            className="nav-link dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            <svg className="icon icon-user-light">
                              <use xlinkHref="#icon-user-light" />
                            </svg>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right x-animated x-fadeInUp pt-3 pb-0 pb-xl-3 mw-260px">
                            <div className="px-7">
                              <h4 className="dropdown-header text-light fs-18 mb-3 lh-1 px-0">
                                {storageService.getUser()?.email}
                              </h4>
                              <ul className="p-0">
                                {props.menus
                                  .find((x) => x.view === "slider-menu")
                                  ?.children.map((menuItem, index) => (
                                    <li
                                      key={index}
                                      className="dropdown-item px-0"
                                    >
                                      <Link
                                        className="dropdown-link"
                                        to={menuItem.path ?? ""}
                                      >
                                        {menuItem.title}
                                      </Link>
                                    </li>
                                  ))}
                                <li className="dropdown-item px-0">
                                  <a
                                    className="dropdown-link"
                                    onClick={() => {
                                      authService.logout();
                                      window.location.reload();
                                    }}
                                  >
                                    Гарах
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      )}
                      <li className="nav-item py-2 py-xl-7 sticky-py-xl-6 px-0 px-xl-1">
                        <a
                          className="nav-link position-relative menu-cart d-inline-flex align-items-center px-xs-0"
                          data-canvas="true"
                          data-canvas-options='{"container":".cart-canvas"}'
                        >
                          <svg className="icon icon-cart">
                            <use xlinkHref="#icon-cart" />
                          </svg>
                          <span className="position-absolute number">
                            {
                              state.cartProducts?.filter(
                                (x) => x.bundled_product_id === null
                              ).length
                            }
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-header navbar-right d-flex align-items-center d-xl-none">
              <button
                className="navbar-toggler border-0 mx-3 canvas-toggle"
                type="button"
                data-canvas="true"
                data-canvas-options='{"width":"250px","container":".sidenav"}'
              >
                <span className="fs-24 toggle-icon" />
              </button>
              <div className="mx-auto">
                <a
                  className="navbar-brand d-inline-block mr-0 py-5"
                  href="../index.html"
                >
                  <img src="/glowing/images/smok-logo-white.png" alt="SMOK" />
                </a>
              </div>
              <div className="nav-item">
                <a
                  className="nav-link position-relative menu-cart d-inline-flex align-items-center"
                  data-canvas="true"
                  data-canvas-options='{"container":".cart-canvas"}'
                >
                  <svg className="icon icon-cart text-light">
                    <use xlinkHref="#icon-cart" />
                  </svg>
                  <span className="position-absolute number">
                    {
                      state.cartProducts?.filter(
                        (x) => x.bundled_product_id === null
                      ).length
                    }
                  </span>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
