import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import { helper } from "../../../../helper/helper";
import storageService from "../../../../helper/storage";
import { smokToast } from "../../../../helper/toast";
import {
  CartProduct,
  CartProductReadUI,
  CartSummary,
} from "../model/cart.model";
import { ProductType } from "../model/product.model";

const CART_URL = "/carts/";
const SUMMARY_CART_URL = CART_URL + "summary/";
const CLEAR_CART_URL = CART_URL + "clear/";
const BULK_CART_URL = CART_URL + "bulk-create/";

async function getCartProducts(): Promise<CartProductReadUI[]> {
  let cartProducts: CartProductReadUI[] = await api.get(
    smokConstants.getOrderUrl(CART_URL),
    !helper.isAuthenticated()
      ? {
          params: {
            user_info_id: storageService.getUserInfoId(),
          },
        }
      : {}
  );

  return cartProducts;
}

async function addCartProduct(cartProduct: CartProduct): Promise<CartProduct> {
  setUserInfoId(cartProduct);

  let _cartProduct: CartProduct = await api.post(
    smokConstants.getOrderUrl(CART_URL),
    cartProduct
  );

  smokToast.success(
    `${
      cartProduct.type === ProductType.Coupon ? "Купон" : "Бүтээгдэхүүн"
    } сагсанд амжилттай нэмэгдлээ.`
  );

  return _cartProduct;
}

async function bulkAddCartProducts(
  cartProducts: CartProduct[]
): Promise<CartProduct[]> {
  cartProducts.forEach((cp) => {
    setUserInfoId(cp);
  });

  let _cartProducts: CartProduct[] = await api.post(
    smokConstants.getOrderUrl(BULK_CART_URL),
    cartProducts
  );

  smokToast.success("Багц бүтээгдэхүүнүүдийг сагсанд амжилттай нэмэгдлээ.");

  return _cartProducts;
}

function editCartProduct(cartProduct: CartProduct): Promise<CartProduct> {
  setUserInfoId(cartProduct);

  return api.put(
    smokConstants.getOrderUrl(`${CART_URL}${cartProduct.id}/`),
    cartProduct
  );
}

async function deleteCartProduct(cartProduct: CartProduct): Promise<void> {
  setUserInfoId(cartProduct);

  await api.delete(
    smokConstants.getOrderUrl(`${CART_URL}${cartProduct.id}/`),
    !helper.isAuthenticated()
      ? {
          params: {
            user_info_id: storageService.getUserInfoId(),
          },
        }
      : {}
  );

  smokToast.success(
    `${
      cartProduct.type === ProductType.Coupon ? "Купон" : "Бүтээгдэхүүн"
    } сагснаас амжилттай устгалаа.`
  );
}

function setUserInfoId(cartProduct: CartProduct): CartProduct {
  let userInfoId = storageService.getUserInfoId();

  if (!helper.isAuthenticated() && userInfoId !== null) {
    cartProduct.user_info_id = userInfoId;
  } else {
    cartProduct.user_info_id = "00000000-0000-0000-0000-000000000000";
  }

  return cartProduct;
}

function getCartSummary(): Promise<CartSummary> {
  return api.get(
    smokConstants.getOrderUrl(SUMMARY_CART_URL),
    !helper.isAuthenticated()
      ? {
          params: {
            user_info_id: storageService.getUserInfoId(),
          },
        }
      : {}
  );
}

function clearCart(): Promise<void> {
  return api.delete(
    smokConstants.getOrderUrl(CLEAR_CART_URL),
    !helper.isAuthenticated()
      ? {
          params: {
            user_info_id: storageService.getUserInfoId(),
          },
        }
      : {}
  );
}

export const cartService = {
  addCartProduct,
  editCartProduct,
  deleteCartProduct,
  getCartProducts,
  bulkAddCartProducts,
  getCartSummary,
  clearCart,
};
