import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { smokConstants } from "../../../../constant/constants";
import { helper } from "../../../../helper/helper";
import { Pagination } from "../../../../helper/shared.model";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import { utils } from "../../../../helper/utils";
import { homePath } from "../../home/screen/Home";
import { CouponType } from "../../order/model/coupon.model";
import {
  Coupon,
  Order,
  OrderProduct,
  OrderQuery,
} from "../../order/model/order.model";
import { orderService } from "../../order/service/order.service";
import SmokPagination from "../../store/component/SmokPagination";

export const userOrdersPath = "/user/orders";

function UserOrders() {
  let navigate = useNavigate();
  const [orders, setOrders] = useState<Pagination<Order>>();
  const [orderProducts, setOrderProducts] = useState<OrderProduct[]>([]);
  const [orderCoupon, setOrderCoupon] = useState<Coupon>();
  const [query, setQuery] = useState<OrderQuery>({ page: 1 });
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);

  useEffect(() => {
    if (!helper.isAuthenticated()) {
      navigate(homePath);
    }

    getOrders(query);
  }, []);

  const getOrders = (_query: OrderQuery) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    setQuery(_query);

    orderService
      .getOrders(_query)
      .then((_orders) => {
        setOrders(_orders);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  const showOrderProducts = (orderId: number) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    orderService
      .getOrderProducts(orderId)
      .then((_orderProducts) => {
        setOrderProducts(_orderProducts);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  return (
    <section className="pb-lg-13 pb-11">
      <div className="container">
        <h2 className="text-center my-9">Хэрэглэгчийн захиалгууд</h2>
        <div className="table-responsive">
          <table className="table" style={{ color: "#000" }}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Огноо</th>
                <th scope="col">Бүтээгдэхүүнүүд</th>
                <th scope="col">Төлөв</th>
                <th scope="col">Купон</th>
                <th scope="col">Нийт дүн</th>
              </tr>
            </thead>
            <tbody>
              {orders?.results.map((_order) => (
                <tr key={_order.id}>
                  <th scope="row">{_order.id}</th>
                  <td>{utils.formatDateTime(_order.created_date!)}</td>
                  <td>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#order-products"
                      onClick={() => showOrderProducts(_order.id!)}
                    >
                      Харах
                    </a>
                  </td>
                  <td>{utils.formatOrderStatus(_order.status!)}</td>
                  <td>
                    {_order.coupon_id ? (
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#order-coupon"
                        onClick={() => setOrderCoupon(_order.coupon)}
                      >
                        Харах
                      </a>
                    ) : (
                      "Байхгүй"
                    )}
                  </td>
                  <td>{utils.formatCurrency(_order.total_amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {orders && (
            <SmokPagination
              current={query.page ?? 1}
              count={orders?.count}
              pageSize={orders.page_size}
              onChange={(page) => getOrders({ ...query, page })}
            />
          )}
        </div>
      </div>

      <div
        className="modal order-products"
        id="order-products"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-6 pt-6">
              <h4>Захиалгын бүтээгдэхүүнүүд</h4>

              <button
                type="button"
                className="close opacity-10 fs-32 pt-1 position-absolute"
                data-dismiss="modal"
                aria-label="Close"
                style={{ right: "30px" }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body px-md-6">
              <div className="table-responsive">
                <table className="table" style={{ color: "#000" }}>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Зураг</th>
                      <th scope="col">Бүтээгдэхүүн код</th>
                      <th scope="col">Бүтээгдэхүүн төрөл</th>
                      <th scope="col">Үнэ</th>
                      <th scope="col">Тоо</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderProducts?.map((_orderProduct, index) => (
                      <tr
                        key={_orderProduct.id}
                        className={
                          _orderProduct.bundled_product ? "opacity-4" : ""
                        }
                      >
                        <th scope="row">
                          {_orderProduct.bundled_product ? "" : index + 1}
                        </th>
                        <td>
                          <img
                            src={smokConstants.getAbsoluteUrl(
                              _orderProduct.product.image
                            )}
                            style={{
                              height: "50px",
                            }}
                          />
                        </td>
                        <td>{_orderProduct.product.sku}</td>
                        <td>{utils.formatProductType(_orderProduct.type)}</td>
                        <td>{utils.formatCurrency(_orderProduct.price)}</td>
                        <td>{_orderProduct.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal order-coupon"
        id="order-coupon"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{
              width: "700px",
            }}
          >
            <div className="modal-header border-0 px-6 pt-6">
              <h4>Захиалгын купон</h4>

              <button
                type="button"
                className="close opacity-10 fs-32 pt-1 position-absolute"
                data-dismiss="modal"
                aria-label="Close"
                style={{ right: "30px" }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            {orderCoupon && (
              <div className="modal-body px-md-6">
                <div className="table-responsive">
                  <table className="table" style={{ color: "#000" }}>
                    <thead>
                      <tr>
                        <th scope="col">Код</th>
                        <th scope="col">Төрөл</th>
                        <th scope="col">Хямдралын хувь</th>
                        <th scope="col">Хямдралын дүн</th>
                        <th scope="col">Хямдарсан дүн</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{orderCoupon.id}</th>
                        <td>{utils.formatCouponType(orderCoupon.type)}</td>
                        <td>{orderCoupon.sale_percentage}</td>
                        <td>{orderCoupon.sale_amount}</td>
                        <td>
                          {orderCoupon.type === CouponType.Fixed
                            ? utils.formatCurrency(orderCoupon.sale_amount)
                            : utils.formatCurrency(
                                (orderCoupon.sale_amount *
                                  orderCoupon.sale_percentage) /
                                  100
                              )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserOrders;
