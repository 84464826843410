export interface SpinnerState {
  spinning?: boolean;
}

export interface SpinnerAction {
  type: string;
}

export const initialState = {
  spinning: false,
};

export const actions = {
  BLOCK: "BLOCK",
  UNBLOCK: "UNBLOCK",
};

export const reducer = (
  state: SpinnerState,
  action: SpinnerAction
): SpinnerState => {
  switch (action.type) {
    case actions.BLOCK:
      return { spinning: true };
    case actions.UNBLOCK:
      return { spinning: false };
    default:
      return state;
  }
};
