import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { helper } from "../../../../helper/helper";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import { smokToast } from "../../../../helper/toast";
import { homePath } from "../../home/screen/Home";
import UserInfoForm from "../../order/component/UserInfoForm";
import { UserInfo } from "../../order/model/order.model";
import { NewPassword } from "../model/auth.model";
import { authService } from "../service/auth.service";

export const userProfilePath = "/user/profile";

function UserProfile() {
  let navigate = useNavigate();
  const [userInfoForm] = useForm();
  const [resetPasswordForm] = useForm();
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);

  useEffect(() => {
    if (!helper.isAuthenticated()) {
      navigate(homePath);
    }
  }, [])
  

  const onFinish = (userInfo: UserInfo) => {
    smokToast.success("Хэрэглэгчийн мэдээлэл амжилттай хадгалагдлаа.");
  };

  const onResetPassword = (values: NewPassword) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    authService
      .setNewPassword(values)
      .then((x) => {
        smokToast.success("Нууц үгийг амжилттай шинэчиллээ.");
        userInfoForm.resetFields();
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  return (
    <section className="pb-lg-13 pb-11">
      <div className="container">
        <h2 className="text-center my-9">Хувийн мэдээлэл</h2>
        <div className="row">
          <div className="col-lg-8 pr-xl-15">
            <h4 className="fs-24 pt-1 mb-4">Хүргэлтийн мэдээлэл</h4>
            <UserInfoForm onFinish={onFinish} form={userInfoForm} />

            <div className="row mt-6">
              <div className="col-md-4">
                <button
                  type="submit"
                  className="btn btn-secondary bg-hover-primary border-hover-primary px-7 w-100"
                  onClick={() => userInfoForm.submit()}
                >
                  Хадгалах
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-5"></hr>
        <div className="row">
          <div className="col-lg-8 pr-xl-15">
            <h4 className="fs-24 pt-1 mb-4">Нууц үг</h4>
            <Form form={resetPasswordForm} onFinish={onResetPassword}>
              <label
                htmlFor="street-address"
                className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase"
              >
                Нууц үг
              </label>
              <Form.Item
                name="current_password"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Нууц үгийг оруулна уу.",
                  },
                ]}
              >
                <input
                  type="password"
                  className="form-control border-0"
                  placeholder="Нууц үг"
                />
              </Form.Item>
              <label
                htmlFor="street-address"
                className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase"
              >
                Шинэ нууц үг
              </label>
              <Form.Item
                name="new_password"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Шинэ нууц үгийг оруулна уу.",
                  },
                ]}
              >
                <input
                  type="password"
                  className="form-control border-0"
                  placeholder="Шинэ нууц үг"
                />
              </Form.Item>
              <label
                htmlFor="street-address"
                className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase"
              >
                Нууц үг давтах
              </label>
              <Form.Item
                name="re_new_password"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Шинэ нууц үгийг давтан оруулна уу.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error("Нууц үгээс өөр байна."));
                    },
                  }),
                ]}
              >
                <input
                  type="password"
                  className="form-control border-0"
                  placeholder="Шинэ нууц үг давтах"
                />
              </Form.Item>
            </Form>
            <div className="row mt-6">
              <div className="col-md-4">
                <button
                  type="submit"
                  className="btn btn-secondary bg-hover-primary border-hover-primary px-7 w-100"
                  onClick={() => resetPasswordForm.submit()}
                >
                  Хадгалах
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserProfile;
