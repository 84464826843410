import React from "react";

function SearchPopup() {
  return (
    <div className="mfp-hide search-popup mfp-with-anim" id="search-popup">
      <form>
        <div className="input-group position-relative">
          <div className="input-group-prepend d-none d-md-block">
            <select
              className="selectpicker"
              name="category"
              id="region-01"
              data-style="btn-lg letter-spacing-0 border-0 border-bottom border-2x bg-transparent text-white border-white fs-24 pl-0 shadow-none text-capitalize font-weight-normal rounded-0"
            >
              <option value="">All Categories</option>
              <option value="chairs">Chairs</option>
              <option value="stands">Stands</option>
              <option value="tables">Tables</option>
              <option value="accessory">Accessory</option>
            </select>
          </div>
          <input
            type="text"
            id="keyword"
            name="keyword"
            className="form-control border-0 border-bottom border-2x bg-transparent text-white border-white fs-24 form-control-lg"
            placeholder="Search Something..."
          />
          <div className="input-group-append position-absolute pos-fixed-right-center">
            <button
              className="input-group-text bg-transparent border-0 text-white fs-30 px-0 btn-lg"
              type="submit"
            >
              <i className="far fa-search" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SearchPopup;
