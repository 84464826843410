import { Step, Stepper } from "react-form-stepper";
import { Link } from "react-router-dom";
import { utils } from "../../../../helper/utils";
import { contactUsPath } from "../../contact-us/screen/ContactUs";
import { homePath } from "../../home/screen/Home";
import { OrderStatus } from "../model/order.model";

export interface OrderStatusStepperProps {
  activeStep: number;
  success: boolean;
}

function OrderStatusStepper(props: OrderStatusStepperProps) {
  const stepperStyleConfig = {
    activeBgColor: props.success ? "#e0e0e0" : "#e74c3c",
    activeTextColor: "#ffffff",
    completedBgColor: "#28a745",
    completedTextColor: "#ffffff",
    inactiveBgColor: "#e0e0e0",
    inactiveTextColor: "#ffffff",
    size: "3em",
    circleFontSize: "12px",
    labelFontSize: "12px",
    borderRadius: "50%",
    fontWeight: "600",
  };

  const connectorStyleConfig = {
    disabledColor: "#bdbdbd50",
    activeColor: "#ed1d24",
    completedColor: "#a10308",
    size: 1,
    stepSize: "3em",
    style: "solid",
  };

  return (
    <div>
      {props.activeStep === utils.getStep(OrderStatus.ReadyToDeliver) && (
        <div className="alert alert-success fs-12 mt-6">
          <p className="mb-0 text-center">
            Баримтыг таны захиалга үүсгэсэн и-мэйл рүү илгээлээ. И-мэйл хаягаа
            шалгана уу. <Link to={homePath}>Нүүр хуудас руу буцах</Link>
          </p>
        </div>
      )}
      {props.success === false && (
        <div className="alert alert-danger fs-12 mt-6">
          <p className="mb-0 text-center">
            Захиалгын явцад алдаа гарлаа.{" "}
            <Link to={contactUsPath}>Холбоо барих дугаар</Link> руу залгаж
            ажилтантай холбогдоно уу.{" "}
            <Link to={homePath}>Нүүр хуудас руу буцах</Link>
          </p>
        </div>
      )}
      <Stepper
        activeStep={props.activeStep}
        disabled
        className="stepper my-2 px-0 table-responsive"
        stepClassName="step"
        styleConfig={stepperStyleConfig}
        connectorStyleConfig={connectorStyleConfig}
      >
        <Step label={utils.formatOrderStatus(OrderStatus.New)} />
        <Step label={utils.formatOrderStatus(OrderStatus.Callback)} />
        <Step
          label={
            utils.getStep(OrderStatus.Declined) === props.activeStep &&
            !props.success
              ? utils.formatOrderStatus(OrderStatus.Declined)
              : utils.formatOrderStatus(OrderStatus.Approved)
          }
        />
        <Step
          label={
            utils.getStep(OrderStatus.EbarimtFailed) === props.activeStep &&
            !props.success
              ? utils.formatOrderStatus(OrderStatus.EbarimtFailed)
              : utils.formatOrderStatus(OrderStatus.Completed)
          }
        />
        <Step label={utils.formatOrderStatus(OrderStatus.ReadyToDeliver)} />
      </Stepper>
    </div>
  );
}

export default OrderStatusStepper;
