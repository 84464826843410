import { Form } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { Resend } from "../../../private/user/model/auth.model";
import { authService } from "../../../private/user/service/auth.service";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import { smokToast } from "../../../../helper/toast";
import { useNavigate } from "react-router-dom";
import { getHomeTabPath, homePath } from "../../../private/home/screen/Home";
import { Tab } from "../../../../component/AuthWrapper";

export const userActivationPath = "/users/activate/:uid/:token";

function UserActivation() {
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [isSuccessful, setSuccessful] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (uid && token) {
      dispatchSpinner({
        type: spinnerAction.BLOCK,
      });

      authService
        .activate({ uid, token })
        .then((response) => {
          setSuccessful(true);
        })
        .catch((err) => {
          setSuccessful(false);
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    } else {
      smokToast.success("Хэрэглэгчийг идэвхжүүлэхэд алдаа гарлаа.");
    }
  }, [uid, token]);

  const onFinish = (values: Resend) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    authService
      .resendActivation(values)
      .then((response) => {
        smokToast.success("И-мэйл рүү идэвхжүүлэх мэйлийг амжилттай илгээлээ.");
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  return (
    <div className="modal-content">
      <div className="modal-body px-md-9 pb-8">
        <h4 className="text-center py-6 mb-0">Хэрэглэгч идэвхжүүлэх</h4>
        {isSuccessful == true && (
          <div>
            <div className="alert alert-success">
              <p className="mb-0">
                Хэрэглэгчийг амжилттай идэвхжүүллээ.
                <br />
                Та нэвтрэх хуудсаар и-мэйл, нууц үгээрээ нэвтрэн орно уу.
              </p>
            </div>
            <button
              type="submit"
              className="btn btn-secondary btn-block bg-hover-primary border-hover-primary"
              onClick={() => navigate(getHomeTabPath(Tab.SignIn))}
            >
              Нэврэх хуудас руу үсрэх
            </button>
          </div>
        )}
        {isSuccessful == false && (
          <div>
            <div className="alert alert-danger" role="alert">
              Хэрэглэгчийг идэвхжүүлэхэд алдаа гарлаа.
              <br />
              <br />
              Та доорх талбарт и-мэйлээ оруулж дахин идэвхжүүлэх линкийг
              и-мэйлдээ хүлээн аваарай.
            </div>
            <Form onFinish={onFinish}>
              <Form.Item
                name="email"
                className="mb-6"
                rules={[
                  {
                    required: true,
                    message: "И-мэйл хаягийг оруулна уу.",
                  },
                ]}
              >
                <input
                  type="email"
                  className="form-control border-0"
                  placeholder="И-мэйл хаяг"
                />
              </Form.Item>
              <button
                type="submit"
                className="btn btn-secondary btn-block bg-hover-primary border-hover-primary"
              >
                Үргэлжлүүлэх
              </button>
            </Form>
          </div>
        )}
        {isSuccessful === undefined && (
          <div className="text-center">Уншиж байна...</div>
        )}
      </div>
    </div>
  );
}

export default UserActivation;
