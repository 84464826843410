import { ReactNode } from "react";

export interface CustomCheckBoxProps {
  children: ReactNode;
  onChange?: (value: boolean) => void;
  value?: boolean;
}

function CustomCheckBox(props: CustomCheckBoxProps) {
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        id="termsAndCondition"
        checked={props.value}
        onChange={(e) => props.onChange && props.onChange(e.target.checked)}
      />
      <label className="custom-control-label" htmlFor="termsAndCondition">
        {props.children}
      </label>
    </div>
  );
}

export default CustomCheckBox;
