import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import {
  SmokImageQuery,
  SmokImageReadUI,
  SmokImageWriteUI
} from "../model/image.model";

function uploadImage(image: SmokImageWriteUI): Promise<SmokImageReadUI> {
  let formData = new FormData();

  formData.append("type", image.type);
  formData.append("image", image.image);

  if (image.product_id) {
    formData.append("product_id", image.product_id);
  }

  if (image.variation_id) {
    formData.append("variation_id", image.variation_id.toString());
  }

  return api.post(smokConstants.getProductUrl("/images/"), formData, {
    headers: { "content-type": "multipart/form-data" },
  });
}

function getImages(params: SmokImageQuery): Promise<SmokImageReadUI[]> {
  return api.get(smokConstants.getProductUrl("/images/"), { params });
}

function deleteImage(imageId: number): Promise<SmokImageReadUI> {
  return api.delete(smokConstants.getProductUrl(`/images/${imageId}/`));
}

export const imageService = {
  uploadImage,
  getImages,
  deleteImage,
};
