import { Form } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import CustomSelect from "../../../../component/CustomSelect";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import storageService from "../../../../helper/storage";
import { UserInfoWriteUI } from "../model/order.model";
import { District, Quarter } from "../model/pick-pack.model";
import { orderService } from "../service/order.service";
import { pickPackService } from "../service/pick-pack.service";

export interface UserInfoProps {
  form: FormInstance;
  onFinish: (userInfo: UserInfoWriteUI) => void;
}

function UserInfoForm(props: UserInfoProps) {
  const [districts, setDistricts] = useState<District[]>([]);
  const [khoroos, setKhoroos] = useState<Quarter[]>([]);
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);

  const getKhoroos = (district?: District) => {
    if (district) setKhoroos(district.quarter);
  };

  const [userInfo, setUserInfo] = useState<UserInfoWriteUI | undefined>(
    undefined
  );

  useEffect(() => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    pickPackService
      .getDistricts()
      .then((districts) => {
        setDistricts(districts);

        let userUuid = storageService.getUserInfoId();

        if (userUuid) {
          orderService
            .getUserInfo(userUuid)
            .then((_userInfo) => {
              if (_userInfo) {
                let district = districts.find(
                  (d) => d.code === _userInfo.district_id
                );

                getKhoroos(district);

                let temp = {
                  ..._userInfo,
                  district: district,
                  khoroo: district?.quarter.find(
                    (q) => q.code === _userInfo.khoroo_id
                  ),
                };

                setUserInfo(temp);
                props.form.setFieldsValue(temp);
              }
            })
            .finally(() => {
              dispatchSpinner({
                type: spinnerAction.UNBLOCK,
              });
            });
        } else {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        }
      })
      .catch(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  }, []);

  const onFinish = async (value: UserInfoWriteUI) => {
    let _userInfo;

    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    if (value.id) {
      _userInfo = await orderService.editUserInfo(value);
    } else {
      _userInfo = await orderService.addUserInfo(value);
    }

    dispatchSpinner({
      type: spinnerAction.UNBLOCK,
    });

    props.onFinish(_userInfo);
  };

  return (
    <Form form={props.form} onFinish={onFinish}>
      <div className="mb-3">
        <label className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">
          нэр <span className="text-danger">*</span>
        </label>
        <div className="row">
          <Form.Item name="id" hidden>
            <input hidden type="text" />
          </Form.Item>
          <div className="col-md-6 mb-md-0 mb-4">
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Нэрийг оруулна уу.",
                },
              ]}
            >
              <input
                type="text"
                className="form-control border-0"
                placeholder="Нэр"
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Овгийг оруулна уу.",
                },
              ]}
            >
              <input
                type="text"
                className="form-control border-0"
                placeholder="Овог"
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">
          Мэдээлэл <span className="text-danger">*</span>
        </label>
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-4">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "И-мэйл хаягийг оруулна уу.",
                },
              ]}
            >
              <input
                type="email"
                className="form-control border-0"
                placeholder="И-мэйл хаяг"
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Утасны дугаарыг оруулна уу.",
                },
                {
                  pattern: /\d{8}/,
                  message: "Утасны дугаарыг 8 оронтой байх ёстой.",
                },
              ]}
            >
              <input
                type="tel"
                maxLength={8}
                className="form-control border-0"
                placeholder="Утасны дугаар"
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-4">
            <label
              htmlFor="street-address"
              className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase"
            >
              Дүүрэг <span className="text-danger">*</span>
            </label>
            <Form.Item
              name="district"
              rules={[
                {
                  required: true,
                  message: "Дүүргийг сонгоно уу.",
                },
              ]}
            >
              <CustomSelect<District>
                placeholder="Дүүргийг сонгоно уу."
                options={districts}
                onChange={(district) => {
                  if (district) getKhoroos(district);
                }}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <label
              htmlFor="apt"
              className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase"
            >
              Хороо <span className="text-danger">*</span>
            </label>
            <Form.Item
              name="khoroo"
              rules={[
                {
                  required: true,
                  message: "Хороог сонгоно уу.",
                },
              ]}
            >
              <CustomSelect<Quarter>
                placeholder="Хороог сонгоно уу."
                options={khoroos}
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="row">
          <div className="col-md-12 mb-md-0 mb-4">
            <label
              htmlFor="city"
              className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase"
            >
              Дэлгэрэнгүй хаяг <span className="text-danger">*</span>
            </label>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: "Дэлгэрэнгүй хаягийг оруулна уу.",
                },
              ]}
            >
              <textarea className="form-control border-0" />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <div className="row">
          <div className="col-md-12 mb-md-0 mb-4">
            <label
              htmlFor="city"
              className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase"
            >
              Нэмэлт мэдээлэл <span className="text-danger">*</span>{" "}
              <span className="fs-12 letter-spacing-0 text-lowercase text-muted font-weight-500">
                (орцны хаалганы код гэх мэт мэдээлэл оруулна.)
              </span>
            </label>
            <Form.Item
              name="additional_info"
              rules={[
                {
                  pattern: /.{3,}/,
                  message:
                    "Нэмэлт мэдээллийн тэмдэгтийн тоо 3 - аас их байх ёстой.",
                },
                {
                  required: true,
                  message: "Нэмэлт мэдээллийг оруулна уу.",
                },
              ]}
            >
              <textarea className="form-control border-0" />
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default UserInfoForm;
