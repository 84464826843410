import React from "react";
import storageService from "../../../../helper/storage";
import { utils } from "../../../../helper/utils";

interface InfoBox {
  index?: number;
  title: string;
  description?: string;
  bulletPoints?: string[];
}

export interface InfoBoxItemProps {
  key: string;
  infoBox: InfoBox;
}

function InfoBoxItem(props: InfoBoxItemProps) {
  return (
    <div className="col-md-6">
      <div className="card bg-transparent border-0 align-items-center text-center mb-xl-0 mb-6">
        <div className="card-img">
          <img
            src={`/glowing/images/info-box-${props.infoBox.index}.png`}
            style={{
              width: "80px",
            }}
          />
        </div>
        <div className="card-body text-center">
          <h3 className="fs-18 mb-3">{props.infoBox.title}</h3>
          {props.infoBox.description && (
            <p className="font-weight-400 fs-14">{props.infoBox.description}</p>
          )}
          {props.infoBox.bulletPoints &&
            props.infoBox.bulletPoints?.length > 0 && (
              <ul
                className="font-weight-400 fs-14 p-0"
                style={{
                  listStyleType: "none",
                }}
              >
                {props.infoBox.bulletPoints?.map((bp, index) => (
                  <li key={bp}>{bp}</li>
                ))}
              </ul>
            )}
        </div>
      </div>
    </div>
  );
}

function InfoBoxes() {
  const freeShippingMin = storageService.getSetting("shipping-min");

  const infoBoxes: InfoBox[] = [
    {
      title: "Хүргэлт үнэгүй",
      description:
        utils.formatCurrency(
          Number(freeShippingMin?.value),
          '',
          undefined,
          0
        ) + " төгрөгөөс дээш хүргэлт үнэгүй",
    },
    {
      title: "Төлбөрийн уян хатан байдал",
      bulletPoints: ["Бүх төрлийн карт", "SocialPay", "QPay"],
    },
  ];

  return (
    <section className="mt-8">
      <div className="container container-xl">
        <div className="row">
          {infoBoxes.map((_infoBox, _index) => (
            <InfoBoxItem
              key={_infoBox.title}
              infoBox={{ ..._infoBox, index: _index + 1 }}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default InfoBoxes;
