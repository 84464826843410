import React from "react";
import { Link } from "react-router-dom";
import storageService from "../../../../helper/storage";
import { utils } from "../../../../helper/utils";
import { privacyPolicyPath } from "../../privacy-policy/PrivaryPolicy";

export const termsAndConditionPath = "/terms-and-condition";

function TermsAndCondition() {
  const freeShippingMin = storageService.getSetting("shipping-min");
  const shippingFee = storageService.getSetting("shipping-fee");
  
  return (
    <section className="py-md-11 pb-lg-13">
      <div className="container px-xxl-12 px-4 mt-lg-0 mt-8">
        <h1 className="fs-40 lh-13 mb-5">Үйлчилгээний нөхцөл</h1>
        <h5>ЕРӨНХИЙ ЗААЛТ</h5>
        <p className="mb-4">
          <ol>
            <li>
              SMOK.MN нь ТАНКО ХХК-ийн интернэт худалдааны албан ёсны вэбсайт
              бөгөөд энэхүү нөхцөл, журам нь уг вебсайтаар үйлчлүүлэх, худалдан
              авалт хийхтэй холбоотой үүсэх харилцааг зохицуулахад оршино.
            </li>
            <li>
              Энэхүү нөхцөл нь хэрэглэгч худалдан авалт хийх,
              SMOK.MN-ээр үйлчлүүлэхээсээ өмнө хүлээн зөвшөөрч баталгаажуулсны
              үндсэн дээр хэрэгжинэ.
            </li>
            <li>
              Энэхүү үйлчилгээний нөхцлийн хэрэгжилтэнд ТАНКО ХХК болон
              хэрэглэгч хамтран хяналт тавина.
            </li>
          </ol>
        </p>
        <h5>ВЕБСАЙТ БА ТҮҮНИЙ ҮЙЛ АЖИЛЛАГАА</h5>
        <p className="mb-4">
          <ol>
            <li>
              SMOK.MN худалдааны тэмдэгтүүд нь ТАНКО ХХК, түүний групп компани
              өмч бөгөөд энэхүү веб сайтын лого, нэр бусад загварыг хуулах,
              олшруулах, дуурайх, өөр бусад ямар ч зүйлд ашиглахыг хориглоно.
            </li>
            <li>
              Хэрэглэгч SMOK.MN талаар санал гомдол, шинэ санаа, шинэ бараа
              борлуулах хүсэлт, шүүмж зэргийг вебийн журмын дагуу чөлөөтэй
              илэрхийлэх, илгээх эрхтэй.
            </li>
          </ol>
        </p>
        <h5>SMOK.MN ХЭРЭГЛЭГЧ БА ГИШҮҮНЧЛЭЛ</h5>
        <p className="mb-4">
          <ol>
            <li>
              Та манай онлайн шоп-д бүртгүүлэн ашиглах боломжтой. Ингэснээр
              хүргэлтийн хаягаа хадгалж дараа дараачийн худалдан авалтад
              ашиглах, захиалгыг түүхийг үзэх, урт хугацаанд худалдан авалтаас
              хамаарсан урамшуулал хүртэх боломж бүрдэнэ.
            </li>
            <li>
              Хэрэглэгчийн мэдээллийн бүрэн бүтэн болон үнэн зөв байдлыг SMOK.MN
              цаг тухай бүрд шалгаж байх ба эдгээр мэдээлэл нууц байж, нууцлалыг
              SMOK.MN бүрэн хариуцна.
            </li>
            <li>
              Хэрэглэгчийн мэдээллийн үнэн зөв, бодит байдалд хэрэглэгч бүрэн
              хариуцлага хүлээнэ.
            </li>
            <li>
              Нэг хэрэглэгч нэг л мэйл хаягаар гишүүн болох эрхтэй. Ашиглагдсан
              мэйл хаяг дахин өөр гишүүнчлэлд ашиглагдахгүй.
            </li>
            <li>
              Хэрэглэгч утасны дугаар, хүргэлтийн хаягийг алдаатай буруу
              оруулснаас хүргэлт хоцрох, цуцлах эрсдэл үүссэн бол хэрэглэгч
              бүрэн хариуцна.Шинээр бүртгүүлж вебийн гишүүн болмогц тухайн
              хэрэглэгчид Хэрэглэгчийн профайл үүснэ. Хэрэглэгчийн профайлд
              худалдан авалтын түүх, өөрийн мэдээлэл, хүргэлтийн хаяг,
              хуримтлагдсан бонус зэрэг багтах бөгөөд картын ямар ч мэдээлэл
              хадгалагдахгүй.
            </li>
            <li>
              Хэрэглэгч нэг удаа худалдан авалт хийхдээ бүртгэлгүй ашиглах
              боломжтой байх ба энэ тохиолдолд хэрэглэгчийн өмнөх болон
              дараачийн захиалгууд нэгтгэгдэн хөтлөгдөхгүй.
            </li>
          </ol>
        </p>
        <h5>ҮНЭ, ТӨЛБӨР ТООЦОО</h5>
        <p className="mb-4">
          <ol>
            <li>
              Барааны үнэ тогтмол шинэчлэгдэж байх ба барааны үнэ дараах
              байдлаар худалдаалагдана.
            </li>
            <li>
              Бараа бүтээгдэхүүн нь бусад салбар дэлгүүрүүдийн үнэтэй ижил буюу
              хямд үнээр борлуулагдана.
            </li>
            <li>
              Та зөвхөн төлбөр төлсний дараа захиалга баталгаажих ба QPAY,
              SOCIAL PAY, VISA, MASTER, UNIONPAY зэрэг төлбөрийн хэрэгслүүдээс
              сонгон төлбөр төлөх боломжтой.
            </li>
            <li>
              Захиалгын үнийн дүн {utils.formatCurrency(Number(freeShippingMin?.value), undefined, undefined, 0)} - с бага бол хүргэлтийн төлбөр {utils.formatCurrency(Number(shippingFee?.value), undefined, undefined, 0)} - р 
              нэмэгдэнэ.
            </li>
            <li>
              Мөн захиалгын үнийн дүнгээс үл хамаарч хүргэлтийн нэмэлт төлбөр
              тооцогдох боломжтой. Хүргэлтийн хаяг буюу дүүрэг, хорооноос
              хамаарч хотын төвөөс зайтай бүсүүдэд нэмэлт хүргэлтийн төлбөр
              нэмэгдэх ба уг төлбөр нь харилцан адилгүй байна. Та бүтээгдэхүүнээ
              сагсанд нэмж хүргэлтийн хаяг оруулах замаар тухайн бүсийн одоо
              мөрдөгдөж буй хүргэлтийн тарифыг харах боломжтой.
            </li>
          </ol>
        </p>
        <h5>ВЭБСАЙТ БОЛОН ХҮРГЭЛТИЙН ЦАГИЙН ХУВААРЬ</h5>
        <p className="mb-4">
          <ol>
            <li>Вэбсайтын ажиллагаа: 24цаг</li>
            <li>
              Хүргэлт:
              <ol>
                <li>
                  Өглөөний 10:00 цагаас өмнө баталгаажсан захиалгыг тухайн өдрийн
                  18:00-00:00 цагийн хооронд
                </li>
                <li>
                  Өглөөний 10:00 цагаас хойших захиалгыг маргааш өдрийн
                  10:00-00:00 цагийн хооронд тус тус хүргэнэ.
                </li>
                <li>
                  Хүргэлт зөвхөн Улаанбаатар хотын төвийн 6 дүүрэгт хамаарна.
                </li>
              </ol>
            </li>
            <li>
              Ажлын цаг гэдэгт нийтээр амрах баярын өдрүүд, давтагдашгүй хүчин
              зүйлсийн нөлөөллөөр үйлчилгээ үзүүлэх боломжгүй болсноос бусад
              тохиолдолд
            </li>
          </ol>
        </p>
        <h5>БАРИМТЖУУЛАЛТ</h5>
        <p className="mb-4">
          <ol>
            <li>
              Бүх гүйлгээ, захиалгын мэдээлэл, и-баримтыг мэйлээр илгээнэ.
            </li>
            <li>Худалдан авагч барааг шалгаж хүлээн авах үүрэгтэй.</li>
            <li>
              Төлбөр төлсөн баримт нь бараа бүтээгдэхүүний худалдан авалт хийсэн
              эцсийн баталгаа болно.
            </li>
          </ol>
        </p>
        <h5>БАРАА БУЦААХ НӨХЦӨЛ</h5>
        <p className="mb-4">
          <ol>
            <li>
              Худалдан авсан бараа нь үйлдвэрийг доголдолтой эсвэл захиалсан
              бараа биш, зөрүүтэй бол худалдан авагч шалгасаны үндсэн дээр
              тухайн үед буцаах боломжтой.
            </li>
            <li>
              Бусад тохиолдолд худалдан авагч барааг буцаах боломжгүй тул сайтар
              шалган авах шаардлагатай ба шалгаж аваагүйн улмаас гарсан асуудлыг
              ТАНКО ХХК хариуцахгүй болно.
            </li>
            <li>
              Зайлшгүй шалтгаанаар хэрэглэгчийн хүсэлтээр хүргэгдээгүй, лацыг
              хөндөөгүй барааг буцаах бол үйл ажиллагааны зардал, гүйлгээний
              шимтгэлийн хураамж зэргийг багтаан 20% төлбөр суутгана.
            </li>
          </ol>
        </p>
        <h5>БУСАД ЗААЛТ</h5>
        <p className="mb-4">
          <ol>
            <li>
              Худалдан авагчийн мэдээлэл болон төлбөрийн талаар ямар нэг асуудал
              эсвэл тодруулга гарвал веб оператор хэрэглэгчийн утас эсвэл мэйл
              рүү холбогдож лавлагаа хийж болно.
            </li>
            <li>
              Хэрэглэгч санал хүсэлт, асуулт, лавлагааг мэйл (info@tanco.mn)
              болон чат ашиглан мөн +976 7507-1111 утсаар холбогдон лавлаж
              болно.
            </li>
            <li>
              Хэрэглэгчийн нууцлал, дата ашиглалттай холбоотой нөхцөлийг{" "}
              <Link to={privacyPolicyPath}>энэ</Link> хэсгээс уншиж танилцах
              боломжтой.
            </li>
          </ol>
        </p>
      </div>
    </section>
  );
}

export default TermsAndCondition;
