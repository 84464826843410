import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import { Pagination } from "../../../../helper/shared.model";
import storageService from "../../../../helper/storage";
import {
  LoanCredit,
  Order,
  OrderProduct,
  OrderQuery,
  UserInfo,
  UserInfoWriteUI,
} from "../model/order.model";

const USER_INFO_URL = "/user-infos/";
const ORDER_URL = "/orders/";
const ORDER_PRODUCT_URL = "/order-products/";

function getOrders(params?: OrderQuery): Promise<Pagination<Order>> {
  if (params?.dates) {
    params = {
      ...params,
      created_date__gte: params?.dates[0].toDate(),
      created_date__lte: params?.dates[1].toDate(),
      dates: undefined,
    };
  }

  let userInfoId = storageService.getUserInfoId();

  return api.get(`${smokConstants.getOrderUrl(ORDER_URL)}`, {
    params: { ...params, user_info_id: userInfoId },
  });
}

function getOrderProducts(order_id: number): Promise<OrderProduct[]> {
  return api.get(`${smokConstants.getOrderUrl(ORDER_PRODUCT_URL)}`, {
    params: {
      order_id,
    },
  });
}

function getUserInfo(uuid: string): Promise<UserInfo | undefined> {
  return api.get(`${smokConstants.getUserInfoUrl(USER_INFO_URL)}${uuid}/`);
}

function editUserInfo(userInfo: UserInfoWriteUI): Promise<UserInfo> {
  userInfo.district_id = userInfo.district!.code;
  userInfo.khoroo_id = userInfo.khoroo!.code;

  let userInfoId = storageService.getUserInfoId();

  if (userInfoId !== null) {
    userInfo.id = userInfoId;
  }

  return api.put(
    `${smokConstants.getUserInfoUrl(USER_INFO_URL)}${userInfo.id}/`,
    userInfo
  );
}

async function addUserInfo(userInfo: UserInfoWriteUI): Promise<UserInfo> {
  userInfo.district_id = userInfo.district!.code;
  userInfo.khoroo_id = userInfo.khoroo!.code;

  let userInfoId = storageService.getUserInfoId();

  if (userInfoId !== null) {
    userInfo.id = userInfoId;
  }

  let response: UserInfo = await api.post(
    smokConstants.getUserInfoUrl(USER_INFO_URL),
    userInfo
  );

  return response;
}

function createOrder(order: Order): Promise<Order> {
  let userInfoId = storageService.getUserInfoId();

  if (userInfoId !== null) {
    order.user_info_id = userInfoId;
  }

  return api.post(smokConstants.getOrderUrl(ORDER_URL), order);
}

function getLoanCredit(phoneNumber: string): Promise<LoanCredit> {
  return api.get(`${smokConstants.getOrderUrl(ORDER_URL)}loan-credit/`, {
    params: {
      phone_number: phoneNumber,
    },
  });
}

export const orderService = {
  getUserInfo,
  editUserInfo,
  addUserInfo,
  createOrder,
  getOrders,
  getOrderProducts,
  getLoanCredit
};
