import { useEffect, useState } from "react";

export enum CartProductCounterType {
  ProductDetail,
  CartProductItem,
  CartTable,
}

export interface CartProductCounterProps {
  value?: number;
  disabled: boolean;
  max?: number;
  onChange?: (count: number) => void;
  type?: CartProductCounterType;
}

function CartProductCounter(props: CartProductCounterProps) {
  const [count, setCount] = useState(props.value ?? 1);

  useEffect(() => {
    if (props.value) setCount(props.value);
  }, [props.value]);

  const onChange = (value: number) => {
    if (value < 1) {
      return;
    }

    if (props.max !== undefined && value > props.max) {
      return;
    }

    setCount(value);
    props.onChange && props.onChange(value);
  };

  if (props.type === CartProductCounterType.ProductDetail) {
    return (
      <div className="input-group position-relative w-100">
        <a
          className="position-absolute pos-fixed-left-center pl-4 z-index-2"
          onClick={() => onChange(count - 1)}
        >
          <i className="far fa-minus" />
        </a>
        <input
          type="number"
          className="form-control w-100 px-6 text-center input-quality text-secondary h-60 fs-18 font-weight-bold border-0"
          value={count}
          onChange={(e) => onChange(Number(e.target.value))}
        />
        <a
          className="position-absolute pos-fixed-right-center pr-4 z-index-2"
          onClick={() => onChange(count + 1)}
        >
          <i className="far fa-plus" />
        </a>
      </div>
    );
  } else if (props.type === CartProductCounterType.CartTable) {
    if (props.disabled === false) {
      return (
        <>
          <a
            className="down position-absolute pos-fixed-left-center pl-2 z-index-2"
            onClick={() => onChange(count - 1)}
          >
            <i className="far fa-minus" />
          </a>
          <input
            type="number"
            className="form-control form-control-sm px-6 fs-16 text-center input-quality border-0 h-35px"
            value={count}
            onChange={(e) => onChange(Number(e.target.value))}
          />
          <a
            className="up position-absolute pos-fixed-right-center pr-2 z-index-2"
            onClick={() => onChange(count + 1)}
          >
            <i className="far fa-plus" />
          </a>
        </>
      );
    } else {
      return (
        <input
          type="number"
          className="form-control form-control-sm px-6 fs-16 text-center input-quality border-0 h-35px"
          value={count}
          readOnly
          onChange={(e) => onChange(Number(e.target.value))}
        />
      );
    }
  } else {
    if (props.disabled === false) {
      return (
        <>
          <a
            className="position-absolute pos-fixed-left-center pl-2"
            onClick={() => onChange(count - 1)}
          >
            <i className="far fa-minus" />
          </a>
          <input
            type="number"
            className="number-cart w-90px px-6 text-center h-40px bg-input border-0"
            value={count}
            onChange={(e) => onChange(Number(e.target.value))}
          />
          <a
            className="position-absolute pos-fixed-right-center pr-2"
            onClick={() => onChange(count + 1)}
          >
            <i className="far fa-plus" />
          </a>
        </>
      );
    } else {
      return (
        <input
          type="number"
          readOnly
          className="number-cart w-90px px-6 text-center h-40px bg-input border-0"
          value={count}
          onChange={(e) => onChange(Number(e.target.value))}
        />
      );
    }
  }
}

export default CartProductCounter;
