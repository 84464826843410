import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import { Pagination } from "../../../../helper/shared.model";
import { SmokStore } from "../model/store.model";

const STORE_URL = "/stores/";

function getStores(): Promise<Pagination<SmokStore>> {
  return api.get(smokConstants.getStoreUrl(STORE_URL));
}

function getStoresAll(): Promise<SmokStore[]> {
  return api.get(`${smokConstants.getStoreUrl(STORE_URL)}all/`, {
    params: {
      is_active: true,
    },
  });
}

export const storeService = {
  getStores,
  getStoresAll,
};
