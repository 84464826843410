import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Empty from "../../../../component/Empty";
import { smokConstants } from "../../../../constant/constants";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions } from "../../../../helper/spinner.reducer";
import { SmokImageReadUI, SmokImageType } from "../../store/model/image.model";
import { getProductDetailPath } from "../../store/screen/ProductDetail";
import { imageService } from "../../store/service/image.service";

export const emptyBrandPath = `/brand`;
export const brandPath = `/brand/:sku`;
export const getBrandPath = (sku: string) => brandPath.replaceAll(":sku", sku);

function Brand() {
  const { sku } = useParams();
  const [images, setImages] = useState<SmokImageReadUI[]>([]);
  const { dispatch } = useContext(SpinnerContext);

  useEffect(() => {
    dispatch({
      type: actions.BLOCK,
    });

    if (sku) {
      imageService
        .getImages({ type: SmokImageType.Catalog, product_id: sku })
        .then((_images) => {
          setImages(_images);
        })
        .finally(() => {
          dispatch({
            type: actions.UNBLOCK,
          });
        });
    }
  }, [sku]);

  if (images.length == 0) return <Empty />;

  return (
    <>
      {images.map((img, index) => (
        <div>
          {index === 0 && (
            <div
              style={{
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))`,
                height: "40%",
                width: "100%",
                position: "absolute",
              }}
            ></div>
          )}
          <img
            key={index}
            src={smokConstants.getAbsoluteUrl(img.image)}
            className="w-100"
          />
        </div>
      ))}
      <div className="fixed-bottom text-right mb-10 p-6">
        {sku && (
          <Link
            className="btn text-light bg-dark bg-hover-secondary hover-white shadow-1 rounded border-0"
            to={getProductDetailPath(sku)}
          >
            Худалдан авах
          </Link>
        )}
      </div>
    </>
  );
}

export default Brand;
