import React from "react";

function ComingSoon() {
  return (
    <div className="coming-soon">
      <img src="/glowing/images/logo.png" className="logo" />
      <img src="/glowing/images/brand.png" className="brand" />
      <h1 className="center">Вэбийн шинэчлэл хийгдэж байна.</h1>
      <img src="/glowing/images/bottom-corner.png" className="bottom-corner" />
      <img src="/glowing/images/top-corner.png" className="top-corner" />
    </div>
  );
}

export default ComingSoon;
