import { Form } from "antd";
import { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { smokToast } from "../../../../helper/toast";
import { ResetPassword } from "../../../private/user/model/auth.model";
import { authService } from "../../../private/user/service/auth.service";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import { getHomeTabPath, homePath } from "../../../private/home/screen/Home";
import { Tab } from "../../../../component/AuthWrapper";

export const resetPasswordConfirmPath = `/users/reset/password/confirm/:uid/:token`;
export const getResetPasswordConfirmPath = (uid: string, token: string) => {
  resetPasswordConfirmPath.replaceAll(":uid", uid);
  resetPasswordConfirmPath.replaceAll(":token", token);
};

function ResetPasswordConfirm() {
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const { uid, token } = useParams();
  const [isSuccessful, setSuccessful] = useState<boolean | undefined>(
    undefined
  );

  const onFinish = (values: ResetPassword) => {
    if (uid && token) {
      dispatchSpinner({
        type: spinnerAction.BLOCK,
      });

      values = {
        ...values,
        uid,
        token,
      };

      authService
        .resetPasswordConfirm(values)
        .then((response) => {
          smokToast.success("Нууц үгийг амжилттай шинэчиллээ.");
          setSuccessful(true);
        })
        .catch((err) => {
          setSuccessful(false);
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    } else {
      smokToast.success("Нууц үгийг шинэчлэхэд алдаа гарлаа.");
    }
  };

  return (
    <div className="modal-content">
      <div className="modal-body px-md-9 pb-8">
        <h4 className="text-center py-6 mb-0">Нууц үг шинэчлэх</h4>
        {isSuccessful === true && (
          <p className="text-center fs-16 mb-7">
            Нэвтрэх дэлгэц рүү{" "}
            <Link
              to={getHomeTabPath(Tab.SignIn)}
              className="text-secondary border-bottom text-decoration-none"
            >
              энд
            </Link>{" "}
            дарж үсэрнэ үү.
          </p>
        )}
        {isSuccessful === false && (
          <p className="text-center fs-16 mb-7">
            Нууц үгээ шинэчиллэх дэлгэц рүү{" "}
            <Link
              to={getHomeTabPath(Tab.SignIn)}
              className="text-secondary border-bottom text-decoration-none"
            >
              энд
            </Link>{" "}
            дарж үсэрнэ үү.
          </p>
        )}
        <Form onFinish={onFinish}>
          <Form.Item
            name="new_password"
            className="mb-6"
            rules={[
              {
                required: true,
                message: "Нууц үгийг оруулна уу.",
              },
            ]}
          >
            <input
              type="password"
              className="form-control border-0"
              placeholder="Нууц үг"
            />
          </Form.Item>
          <Form.Item
            name="re_password"
            className="mb-6"
            rules={[
              {
                required: true,
                message: "Нууц үгийг давтан оруулна уу.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Нууц үгээс өөр байна."));
                },
              }),
            ]}
          >
            <input
              type="password"
              className="form-control border-0"
              placeholder="Нууц үг давтах"
            />
          </Form.Item>
          <button
            type="submit"
            className="btn btn-secondary btn-block bg-hover-primary border-hover-primary"
          >
            Үргэлжлүүлэх
          </button>
        </Form>
      </div>
    </div>
  );
}

export default ResetPasswordConfirm;
