import { Form } from "antd";
import { useContext } from "react";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import { Coupon } from "../../order/model/order.model";
import { couponService } from "../../order/service/coupon.service";
import { ProductType } from "../../store/model/product.model";
import { CartContext } from "../../store/service/cart.context";
import { cartService } from "../../store/service/cart.service";
import { refreshCart } from "../screen/Cart";

function AddCoupon() {
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const { dispatch } = useContext(CartContext);

  const onAddCoupon = (value: Coupon) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    couponService
      .getCoupon(value.id)
      .then((coupon) => {
        cartService
          .addCartProduct({
            type: ProductType.Coupon,
            count: 1,
            coupon_id: value.id,
          })
          .then((response) => {
            refreshCart(dispatchSpinner, dispatch);
          })
          .finally(() => {
            dispatchSpinner({
              type: spinnerAction.UNBLOCK,
            });
          });
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  return (
    <Form onFinish={onAddCoupon} className="w-100">
      <Form.Item
        name="id"
        rules={[
          {
            required: true,
            message: "Купон кодыг оруулна уу.",
          },
        ]}
      >
        <input
          className="form-control border-0"
          placeholder="Купоны кодыг оруулна уу."
        />
      </Form.Item>
      <button
        type="submit"
        className="btn btn-secondary bg-hover-primary border-hover-primary mt-4"
      >
        Купон оруулах
      </button>
    </Form>
  );
}

export default AddCoupon;
