import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import { District } from "../model/pick-pack.model";

const DISTRICT_URL = "/districts/";

async function getDistricts(): Promise<District[]> {
  let districts: District[] = await api.get(
    smokConstants.getPickPackUrl(DISTRICT_URL)
  );

  districts.forEach((district) => {
    district._id = district.code.toString();
    district._label = district.name;

    district.quarter.forEach((_quarter) => {
      _quarter._id = _quarter.code.toString();
      _quarter._label = _quarter.name;
    });
  });

  return districts;
}

export const pickPackService = {
  getDistricts,
};
