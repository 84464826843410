import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AgeWarning from "../../component/AgeWarning";
import AuthWrapper, { Tab } from "../../component/AuthWrapper";
import BackToTop from "../../component/BackToTop";
import CanvasMenu from "../../component/CanvasMenu";
import CartCanvas from "../../component/CartCanvas";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import SearchPopup from "../../component/SearchPopup";
import SvgIcons from "../../component/SvgIcons";
import { helper } from "../../helper/helper";
import { MenuItem } from "../../helper/shared.model";
import { SpinnerContext } from "../../helper/spinner.context";
import storageService from "../../helper/storage";
import AboutUs, { aboutUsPath } from "./about-us/screen/AboutUs";
import Brand, { brandPath, getBrandPath } from "./brand/screen/Brand";
import Cart, { cartPath, refreshCart } from "./cart/screen/Cart";
import ContactUs, { contactUsPath } from "./contact-us/screen/ContactUs";
import Home, {
  getHomeTabPath,
  homePath,
  homeTabPath,
} from "./home/screen/Home";
import SimpleMap, { locatorPath, locatorPathWithParam } from "./locator/screen/Locator";
import Checkout, { checkoutPath } from "./order/screen/Checkout";
import PrivaryPolicy, {
  privacyPolicyPath,
} from "./privacy-policy/PrivaryPolicy";
import { settingsService } from "./settings/service/settings.service";
import ProductDetail, { productDetailPath } from "./store/screen/ProductDetail";
import Store, {
  getStoreCategoryPath,
  storeCategoryPath,
  storePath,
} from "./store/screen/Store";
import { CartContext } from "./store/service/cart.context";
import { productService } from "./store/service/product.service";
import TermsAndCondition, {
  termsAndConditionPath,
} from "./terms-and-condition/screen/TermsAndCondition";
import UserOrders, { userOrdersPath } from "./user/screen/UserOrders";
import UserProfile, { userProfilePath } from "./user/screen/UserProfile";
import { authService } from "./user/service/auth.service";

function PrivateRoutes() {
  const location = useLocation();
  const { dispatch } = useContext(CartContext);
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const [tab, setTab] = useState<Tab | undefined>(undefined);

  useEffect(() => {
    init();

    settingsService
      .getSystemSettings()
      .then((x) => storageService.setSettings(x));

    switch (location.pathname) {
      case getHomeTabPath(Tab.SignIn):
        setTab(Tab.SignIn);
        break;
      case getHomeTabPath(Tab.SignUp):
        setTab(Tab.SignUp);
        break;
      case getHomeTabPath(Tab.ResetPassword):
        setTab(Tab.ResetPassword);
        break;

      default:
        break;
    }
  }, [location.pathname]);

  const [menus, setMenus] = useState<MenuItem[]>([
    {
      title: "Нүүр",
      path: homePath,
      children: [],
    },
    {
      title: "Онлайн шоп",
      path: storePath,
      children: [],
    },
    {
      title: "Брэнд",
      path: "/brand",
      children: [],
    },
    {
      title: "Дэлгүүрүүд",
      path: locatorPath,
      children: [],
    },
    {
      title: "Бидний тухай",
      path: aboutUsPath,
      children: [],
    },
    {
      title: "Холбоо барих",
      path: contactUsPath,
      children: [],
    },
    {
      title: "email",
      path: "/user",
      view: "slider-menu",
      authenticated: true,
      children: [
        {
          title: "Хувийн мэдээлэл",
          path: "/user/profile",
          children: [],
        },
        {
          title: "Захиалгын жагсаалт",
          path: "/user/orders",
          children: [],
        },
      ],
    },
  ]);

  const init = () => {
    (window as any).app.init();
    (window as any).app.animation.init();
    (window as any).app.slickSlider.init();
    (window as any).app.canvas.init();
  };

  useEffect(() => {
    refreshCart(dispatchSpinner, dispatch);

    productService.getCategories().then((categories) => {
      let _menus: MenuItem[] = [...menus];
      let menu = _menus.find((m) => m.path === storePath);

      if (menu) {
        menu.children = [];

        menu.children.push({
          title: "Бүгд",
          path: storePath,
          children: [],
        });

        menu.path = "#";
        menu.children = menu.children.concat(
          categories.map((_category) => {
            return {
              title: _category.name,
              path: getStoreCategoryPath(_category.id),
              children: [],
            };
          })
        );
      }

      setMenus(_menus);
    });

    productService.getProducts().then((_products) => {
      let _menus: MenuItem[] = [...menus];
      let menu = _menus.find((m) => m.title === "Брэнд");

      if (menu) {
        menu.path = "#";
        menu.children = _products.results
          .filter((x) => x.is_brand_product === true)
          .map((_product) => {
            return {
              title: _product.name,
              path: getBrandPath(_product.sku),
              children: [],
            };
          });
      }

      setMenus(_menus);
    });

    if (helper.isAuthenticated()) {
      authService.getUser().then((_user) => {
        let _menus: MenuItem[] = [...menus];
        storageService.setUser(_user);

        let menu = _menus.find((m) => m.title === "email");

        if (menu) {
          menu.title = _user.email;
        }

        setMenus(_menus);
      });
    }
  }, []);

  return (
    <>
      <AgeWarning />
      <Header menus={menus} />
      <main id="content">
        <Routes>
          <Route path={homePath} element={<Home />} />
          <Route path={homeTabPath} element={<Home />} />
          <Route path={storeCategoryPath} element={<Store />} />
          <Route path={storePath} element={<Store />} />
          <Route path={productDetailPath} element={<ProductDetail />} />
          <Route path={cartPath} element={<Cart />} />
          <Route path={checkoutPath} element={<Checkout />} />
          <Route path={locatorPath} element={<SimpleMap />} />
          <Route path={locatorPathWithParam} element={<SimpleMap />} />
          <Route path={brandPath} element={<Brand />} />
          <Route path={aboutUsPath} element={<AboutUs />} />
          <Route path={contactUsPath} element={<ContactUs />} />
          <Route path={userProfilePath} element={<UserProfile />} />
          <Route path={userOrdersPath} element={<UserOrders />} />
          <Route path={termsAndConditionPath} element={<TermsAndCondition />} />
          <Route path={privacyPolicyPath} element={<PrivaryPolicy />} />
          <Route path="*" element={<Navigate to={homePath} replace />} />
        </Routes>
      </main>
      <Footer />
      {/* Scripts are included in index.html */}
      <SvgIcons />
      <BackToTop />
      <CartCanvas />
      <SearchPopup />
      <CanvasMenu menus={menus} />
      <AuthWrapper tab={tab} />
    </>
  );
}

export default PrivateRoutes;
