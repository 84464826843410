import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import SmokSpinner from "./component/SmokSpinner";
import { SpinnerProvider } from "./helper/spinner.context";
import ComingSoon from "./module/comingsoon/ComingSoon";
import PrivateRoutes from "./module/private/PrivateRoutes";
import { CartProvider } from "./module/private/store/service/cart.context";
import PublicRoutes from "./module/public/PublicRoutes";
import LotteryReader from "./module/private/lottery/screen/LotteryReader";

function App() {
  const location = useLocation();

  useEffect(() => {
    (window as any).gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
  
  return (
    <GoogleOAuthProvider clientId="170335393738-jqp0kskl6gre2ap0vpa381qq89cdi2dh.apps.googleusercontent.com">
      <SpinnerProvider>
        <SmokSpinner />
        <ToastContainer />
        <CartProvider>
          <Routes>
            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="/qr-reader" element={<LotteryReader />} />
            <Route path="/*" element={<PrivateRoutes />} />
            <Route path="/auth/*" element={<PublicRoutes />} />
          </Routes>
        </CartProvider>
      </SpinnerProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
