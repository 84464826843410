import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { smokToast } from "../../../../helper/toast";
import { utils } from "../../../../helper/utils";
import { Order, OrderStatus } from "../model/order.model";
import { orderService } from "../service/order.service";
import OrderStatusStepper from "./OrderStatusStepper";

export interface ConfirmationModalProps {
  order?: Order;
  onOrderChange: (order: Order) => void;
}

function ConfirmationModal(props: ConfirmationModalProps) {
  const [searchParams] = useSearchParams();

  let openLinkRef = useRef<HTMLAnchorElement>(null);
  let closeButtonRef = useRef<HTMLButtonElement>(null);
  let intervalId = useRef<any>(null);

  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState<boolean>(true);
  const [isRunning, setIsRunning] = useState<boolean>(false)

  useEffect(() => {
    let status = searchParams.get("status_code");
    let invoice_id = searchParams.get("invoice");

    if (status === "000") {
      setActiveStep(utils.getStep(OrderStatus.Callback));

      if (invoice_id !== null) {
        checkOrder({ invoice_id });
      }
    } else if (status != null) {
      setSuccess(false);
      setActiveStep(utils.getStep(OrderStatus.Callback));
    }
  }, [searchParams]);

  useEffect(() => {
    if (props.order) {
      updateOrderStatus(props.order);

      console.log("Interval created.");
      intervalId.current = setInterval(() => {
        checkOrder({ id: props.order!.id });
      }, 5000);

      setIsRunning(true);
      return () => {
        console.log("Interval deleted.");
        clearInterval(intervalId.current);
        setIsRunning(false);
      };
    }
  }, [props.order]);

  useEffect(() => {
    if (activeStep > 1) {
      openLinkRef.current?.click();
    }
  }, [activeStep]);

  const checkOrder = (query: { id?: number; invoice_id?: string }) => {
    orderService.getOrders({ ...query, page: 1 }).then((orders) => {
      if (orders.results.length > 0) {
        let tempOrder = orders.results[0];
        updateOrderStatus(tempOrder);
      } else {
        smokToast.error("Захиалга олдсонгүй.");
      }
    });
  };

  const updateOrderStatus = (tempOrder: Order) => {
    console.log("tempOrder:", tempOrder);

    if (tempOrder?.status) {
      setActiveStep(utils.getStep(tempOrder?.status));
    }

    let _success = tempOrder.reason === undefined || tempOrder.reason === null;

    setSuccess(_success);

    if (tempOrder.status === OrderStatus.ReadyToDeliver || _success === false) {
      console.log("Interval deleted.");
      clearInterval(intervalId.current);
      setIsRunning(false);
    }

    props.onOrderChange(tempOrder);
  };

  const getStatusHeader = () => {
    if (isRunning) {
      return (
        <div>
          <h3 className="text-center text-primary mt-7 mb-4">
            Та түр хүлээнэ үү.
          </h3>
          <div className="text-center mt-6 mb-4">
            <BeatLoader color="#36d7b7" size={20} />
          </div>
        </div>
      );
    } else {
      if (success) {
        return (
          <h3 className="text-center text-success mt-7 mb-4">Захиалга амжилттай боллоо!</h3>
        );
      } else {
        return (
          <h3 className="text-center text-muted mt-7 mb-4">Захиалга амжилтгүй боллоо.</h3>
        );
      }
    }
  };

  return (
    <>
      <a ref={openLinkRef} data-toggle="modal" data-target="#confirmation" />
      <div
        className="modal"
        id="confirmation"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <button
          ref={closeButtonRef}
          type="button"
          className="close d-none"
          data-dismiss="modal"
        ></button>

        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body pt-0 px-6">
              {getStatusHeader()}
              <OrderStatusStepper activeStep={activeStep} success={success} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmationModal;
