import { Link } from "react-router-dom";
import { aboutUsPath } from "../module/private/about-us/screen/AboutUs";
import { contactUsPath } from "../module/private/contact-us/screen/ContactUs";
import { privacyPolicyPath } from "../module/private/privacy-policy/PrivaryPolicy";
import { termsAndConditionPath } from "../module/private/terms-and-condition/screen/TermsAndCondition";
import SocialNetworkLinks from "./SocialNetworkLinks";

function Footer() {
  return (
    <footer className="py-8 py-lg-6 footer">
      <div className="border-bottom pb-6 pb-lg-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-12 mb-lg-0 mb-6">
              <ul className="list-inline fs-15 text-center text-lg-left mb-0">
                <li className="list-inline-item mr-2 mr-sm-6">
                  <Link
                    to={termsAndConditionPath}
                    className="text-secondary lh-175"
                  >
                    Үйлчилгээний нөхцөл
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={privacyPolicyPath}
                    className="text-secondary lh-175"
                  >
                    Нууцлалын журам
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-12 mb-lg-0 mb-6">
              <div className="mw-184px mx-auto text-center">
                <img src="/glowing/images/smok-logo-black-2.png" alt="SMOK" />
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <ul className="list-inline fs-15 text-center text-lg-right mb-0">
                <li className="list-inline-item mr-6">
                  <Link to={aboutUsPath} className="text-secondary lh-175">
                    Бидний тухай
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={contactUsPath} className="text-secondary lh-175">
                    Холбоо барих
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6 pt-lg-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
              <p className="mb-0">© 2023 Tanco International LLC</p>
            </div>
            <div className="col-12 col-md-6 mt-2 d-flex align-items-center justify-content-center justify-content-md-end">
              <ul className="list-inline fs-24 ml-3 mb-0 pr-5">
                <SocialNetworkLinks />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
