import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { utils } from "../../../../helper/utils";
import { SmokStore } from "../../locator/model/store.model";
import {
  getLocatorPathWithParam,
  locatorPath,
} from "../../locator/screen/Locator";
import { storeService } from "../../locator/service/store.service";

function NearestShop() {
  const [store, setStore] = useState<SmokStore>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        let currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        let pagination = await storeService.getStoresAll();
        let _stores = pagination;

        _stores.forEach(
          (x) =>
            (x.distance = utils.calcCrow(
              x.latitude,
              x.longitude,
              currentLocation.lat,
              currentLocation.lng
            ))
        );

        _stores = _stores.sort((l1, l2) => {
          let d1 = l1.distance;
          let d2 = l2.distance;

          if (d1 && d2) {
            return d1 - d2;
          } else {
            return 1;
          }
        });

        if (_stores.length > 0) {
          setStore(_stores[0]);
        }

        setLoading(false);
      },
      (err) => {
        setLoading(false);

        throw err;
      }
    );
  }, []);

  if (loading) {
    return (
      <div
        className={`banner text-light text-center fs-${
          isMobile ? "11" : "14"
        } cursor-pointer p-2`}
      >
        Хамгийн ойр дэлгүүрийн байршлыг тооцоолж байна...
      </div>
    );
  }

  return (
    <Link
      className="text-underline text-light"
      to={store ? getLocatorPathWithParam(store.id) : locatorPath}
    >
      <div
        className={`banner text-light text-center fs-${
          isMobile ? "11" : "14"
        } cursor-pointer p-2`}
      >
        {!store ? (
          <span>
            Та энд дарж өөртөө хамгийн ойр байгаа дэлгүүрүүдийг харна уу.
          </span>
        ) : (
          <span>
            SMOK брэнд борлуулагч{" "}
            <span className="font-weight-bold">{store.name}</span> нь{" "}
            <span className="font-weight-bold">
              {store.distance.toFixed(3)}км
            </span>{" "}
            зайд байна. Та дэлгүүрийн байршлыг энд дарж харна уу.
          </span>
        )}
      </div>
    </Link>
  );
}

export default NearestShop;
