import { useContext } from "react";
import { Link } from "react-router-dom";
import { smokConstants } from "../constant/constants";
import { SpinnerContext } from "../helper/spinner.context";
import { actions as spinnerAction } from "../helper/spinner.reducer";
import { refreshCart } from "../module/private/cart/screen/Cart";
import { getPrice } from "../module/private/home/component/ProductItem";
import { CartProductReadUI } from "../module/private/store/model/cart.model";
import { ProductType } from "../module/private/store/model/product.model";
import { getProductDetailPath } from "../module/private/store/screen/ProductDetail";
import { CartContext } from "../module/private/store/service/cart.context";
import { cartService } from "../module/private/store/service/cart.service";
import CartProductCounter from "./CartProductCounter";
import { utils } from "../helper/utils";

export interface CartProductProps {
  key?: string;
  cartProduct: CartProductReadUI;
  cartProducts?: CartProductReadUI[];
}

export const getNameWithoutLink = (cartProduct: CartProductReadUI) => {
  if (cartProduct.product.type === ProductType.Variation) {
    return `${cartProduct.product.name.toUpperCase()} - ${
      cartProduct.product_variation?.variation.name
    }`;
  } else {
    return `${cartProduct.product.name.toUpperCase()}`;
  }
};

export const getName = (cartProduct: CartProductReadUI) => {
  if (cartProduct.product.type === ProductType.Variation) {
    return (
      <Link to={getProductDetailPath(cartProduct.product.sku)}>
        {`${cartProduct.product.name.toUpperCase()} - ${
          cartProduct.product_variation?.variation.name
        }`}
      </Link>
    );
  } else {
    return (
      <Link to={getProductDetailPath(cartProduct.product.sku)}>
        {`${cartProduct.product.name.toUpperCase()}`}
      </Link>
    );
  }
};

export const getImageUrl = (cartProduct: CartProductReadUI) => {
  if (cartProduct?.product.type === ProductType.Variation) {
    return smokConstants.getAbsoluteUrl(
      cartProduct.product_variation?.image ?? ""
    );
  } else {
    return smokConstants.getAbsoluteUrl(cartProduct.product.image);
  }
};

export const getTotalPrice = (cartProduct: CartProductReadUI) => {
  if (utils.isProductWithSale(cartProduct.product)) {
    return cartProduct.product.sale_price! * cartProduct.count;
  } else {
    return cartProduct.product.price * cartProduct.count;
  }
};

function CartProductItem(props: CartProductProps) {
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const { dispatch } = useContext(CartContext);

  const editCartProduct = (count: number) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    cartService
      .editCartProduct({ ...props.cartProduct, count })
      .then((x) => {
        refreshCart(dispatchSpinner, dispatch);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  const deleteCartProduct = () => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    cartService
      .deleteCartProduct(props.cartProduct)
      .then((x) => {
        refreshCart(dispatchSpinner, dispatch);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  const getStock = () => {
    if (props.cartProduct.type === ProductType.Regular) {
      return props.cartProduct.product.stock;
    } else if (props.cartProduct.type === ProductType.Variation) {
      return props.cartProduct.product_variation?.stock;
    } else {
      if (props.cartProduct.bundled_product === null) {
        let cartProducts = props.cartProducts?.filter(
          (x) => x.bundled_product?.product_id === props.cartProduct.product_id
        );

        if (cartProducts) {
          let min = 0;

          if (cartProducts[0]?.product.type === ProductType.Variation) {
            if (cartProducts[0]?.product_variation?.stock) {
              min = cartProducts[0].product_variation?.stock;
            }
          } else if (cartProducts[0]?.product.type === ProductType.Regular) {
            if (cartProducts[0]?.product?.stock) {
              min = cartProducts[0].product?.stock;
            }
          }

          cartProducts.forEach((element) => {
            if (element.product.type === ProductType.Variation) {
              if (
                element.product_variation?.stock &&
                min > element.product_variation?.stock
              ) {
                min = element.product_variation.stock;
              }
            } else if (element.product.type === ProductType.Regular) {
              if (element.product?.stock && min > element.product?.stock) {
                min = element.product.stock;
              }
            }
          });

          return min;
        }
      }
    }

    return undefined;
  };

  return (
    <div
      className={`mb-4 d-flex ${
        props.cartProduct.bundled_product_id !== null ? "opacity-5" : ""
      }`}
    >
      <a className="d-flex align-items-center mr-2 text-muted">
        {props.cartProduct.bundled_product_id === null ? (
          <i className="fal fa-times" onClick={() => deleteCartProduct()} />
        ) : (
          <i className="mr-2"></i>
        )}
      </a>
      <div className="media w-100">
        <div className="w-60px mr-3">
          <img
            src={getImageUrl(props.cartProduct)}
            alt={props.cartProduct.product.name}
          />
        </div>
        <div className="media-body d-flex">
          <div className="cart-price pr-0 pr-md-6">
            <p className="fs-14 font-weight-bold text-secondary mb-1">
              {getPrice(props.cartProduct.product)}
            </p>
            {getName(props.cartProduct)}
          </div>
          <div className="position-relative ml-auto">
            <div className="input-group">
              <CartProductCounter
                max={getStock()}
                value={props.cartProduct.count}
                disabled={props.cartProduct.bundled_product_id !== null}
                onChange={(value: number) => editCartProduct(value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartProductItem;
