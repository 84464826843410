import Empty, { EmptySize } from "../../../../component/Empty";
import { SmokStore } from "../model/store.model";
import StoreLocation from "./StoreLocation";
import StoreSummary from "./StoreSummary";

export interface LocationNavigatorProps {
  count: number;
  stores: SmokStore[];
  onSelectedStoreChange: (value: SmokStore) => void;
}

function LocationNavigator(props: LocationNavigatorProps) {
  return (
    <div
      className="w-100 overflow-y-auto store-location-list"
      style={{
        maxHeight: "calc(100vh - 250px)",
      }}
    >
      <StoreSummary count={props.count} />
      {props.stores.length > 0 ? props.stores.map((_store) => (
        <StoreLocation
          key={_store.id.toString()}
          store={_store}
          onClick={() => props.onSelectedStoreChange(_store)}
        />
      )): <Empty size={EmptySize.Small} message="Дэлгүүр олдсонгүй."/>}
    </div>
  );
}

export default LocationNavigator;
