import { CartProductReadUI, CartSummary } from "../model/cart.model";
import { ProductType } from "../model/product.model";

export interface CartState {
  cartCoupon?: CartProductReadUI;
  cartProducts?: CartProductReadUI[];
  summary?: CartSummary;
}

export interface CartAction {
  type: string;
  _id?: string;
  cartProducts?: CartProductReadUI[];
  summary?: CartSummary;
}

export const initialState = {
  cartProducts: [],
  summary: {
    summary: 0.0,
    sale: 0.0,
    delivery: 0.0,
    total: 0.0,
  },
};

export const actions = {
  SET_CART_PRODUCTS: "SET_CART_PRODUCTS",
  SET_SUMMARY: "SET_SUMMARY",
};

export const reducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case actions.SET_CART_PRODUCTS:
      return {
        ...state,
        cartProducts: action.cartProducts?.filter(
          (_cartProduct) => _cartProduct.type !== ProductType.Coupon
        ),
        cartCoupon: action.cartProducts?.find(
          (_cartProduct) => _cartProduct.type === ProductType.Coupon
        ),
      };
    case actions.SET_SUMMARY:
      return { ...state, summary: action.summary };
    default:
      return state;
  }
};
