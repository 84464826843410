import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext } from "react";
import { SpinnerContext } from "../helper/spinner.context";
import { smokToast } from "../helper/toast";
import { SmokCredential } from "../module/private/user/model/auth.model";
import { authService } from "../module/private/user/service/auth.service";
import { actions as spinnerAction } from "../helper/spinner.reducer";
import { Tab } from "./AuthWrapper";
import storageService from "../helper/storage";
import SocialButtons from "./SocialButtons";
import { useNavigate } from "react-router-dom";
import { homePath } from "../module/private/home/screen/Home";

export interface SignInProps {
  onChangeTab: (tab: Tab) => void;
  onComplete: () => void;
}

function SignIn(props: SignInProps) {
  let [form] = useForm();
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  let navigate = useNavigate();

  const onFinish = (values: SmokCredential) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    authService
      .signIn(values)
      .then((x) => {
        authService.getUser().then((user) => {
          smokToast.success("Амжилттай нэвтэрлээ.");

          if (user.info_id) {
            storageService.setUserInfoId(user.info_id);
          }

          props.onComplete();
          navigate(homePath);

          window.location.reload();
        });
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  return (
    <div>
      <h4 className="fs-34 text-center mb-6">Нэвтрэх</h4>
      <p className="text-center fs-16 mb-7">
        Та бүртгүүлсэн үү?{" "}
        <a
          className="text-secondary border-bottom text-decoration-none"
          onClick={() => props.onChangeTab(Tab.SignUp)}
        >
          Бүртгүүлэх
        </a>
      </p>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          className="mb-6"
          rules={[
            {
              required: true,
              message: "И-мэйл хаягийг оруулна уу.",
            },
          ]}
        >
          <input
            type="email"
            className="form-control border-0"
            placeholder="И-мэйл хаяг"
          />
        </Form.Item>
        <Form.Item
          name="password"
          className="mb-6"
          rules={[
            {
              required: true,
              message: "Нууц үгийг оруулна уу.",
            },
          ]}
        >
          <input
            type="password"
            className="form-control border-0"
            placeholder="Нууц үг"
          />
        </Form.Item>
        <div className="d-flex align-items-center justify-content-between mt-5 mb-4">
          <a
            className="text-secondary"
            onClick={() => props.onChangeTab(Tab.ResetPassword)}
          >
            Нууц үгээ мартсан уу?
          </a>
        </div>
        <button
          type="submit"
          className="btn btn-secondary btn-block bg-hover-primary border-hover-primary"
        >
          Нэвтрэх
        </button>
        <div className="border-bottom mt-6" />
        <div className="text-center mt-n2 lh-1 mb-4">
          <span className="fs-14 bg-white lh-1 mt-n2 px-4">
            сошиал хаягуудаар нэвтрэх
          </span>
        </div>
      </Form>
      <SocialButtons onComplete={props.onComplete} />
    </div>
  );
}

export default SignIn;
