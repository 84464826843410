import moment from "moment";
import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import { Pagination } from "../../../../helper/shared.model";
import { smokToast } from "../../../../helper/toast";
import {
  Coupon,
  CouponQuery,
  CouponType,
  CouponWriteUI,
} from "../model/coupon.model";

const COUPON_URL = "/coupons/";

function getCoupons(params?: CouponQuery): Promise<Pagination<Coupon>> {
  if (params?.dates) {
    params.start_date__gte = params.dates[0]!.toDate();
    params.end_date__lte = params.dates[1]!.toDate();
  }

  delete params?.dates;

  return api.get(`${smokConstants.getOrderUrl(COUPON_URL)}`, { params });
}

function convertWriteUI(coupon: CouponWriteUI) {
  coupon.start_date = coupon.start_moment.toDate();
  coupon.end_date = coupon.end_moment.toDate();

  if (coupon.type === CouponType.Fixed) {
    coupon.sale_percentage = null;
  } else {
    coupon.sale_amount = null;
  }
}

function convertReadUI(coupon: CouponWriteUI) {
  coupon.start_moment = moment(coupon.start_date);
  coupon.end_moment = moment(coupon.end_date);
}

async function getCoupon(id: string): Promise<CouponWriteUI> {
  try {
    let coupon: CouponWriteUI = await api.get(
      `${smokConstants.getOrderUrl(COUPON_URL)}${id}/`
    );

    convertReadUI(coupon);
    return coupon;
  } catch (e: any) {
    smokToast.error(e.detail);

    throw e;
  }
}

function addCoupon(coupon: CouponWriteUI): Promise<Coupon> {
  convertWriteUI(coupon);
  return api.post(`${smokConstants.getOrderUrl(COUPON_URL)}`, coupon);
}

function editCoupon(coupon: CouponWriteUI): Promise<Coupon> {
  convertWriteUI(coupon);
  return api.put(
    `${smokConstants.getOrderUrl(COUPON_URL)}${coupon.id}/`,
    coupon
  );
}

function deleteCoupon(id: string): Promise<Coupon> {
  return api.delete(`${smokConstants.getOrderUrl(COUPON_URL)}${id}/`);
}

export const couponService = {
  getCoupons,
  getCoupon,
  addCoupon,
  editCoupon,
  deleteCoupon,
};
