import { SmokOption } from "../../../../helper/shared.model";
import {
  BundledProductReadUI,
  ProductReadUI,
  ProductType,
  ProductVariationReadUI,
} from "../../store/model/product.model";
import { CouponType } from "./coupon.model";
import { District, Quarter } from "./pick-pack.model";

export interface UserInfo {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  address: string;
  additional_info: string;
  district_id: number;
  khoroo_id: number;
}

export interface UserInfoWriteUI extends UserInfo {
  district?: District;
  khoroo?: Quarter;
}

export enum PaymentType {
  QPAY = "QPAY",
  SOCIAL_PAY = "SOCI",
  CARD_PAY = "CARD",
  STORE_PAY = "SPAY",
}

export interface Order {
  id?: number;
  qr_img?: string;
  qr_text?: string;
  created_date?: Date;
  status?: OrderStatus;
  pick_pack_order_id?: any;
  payment_type: PaymentType;
  invoice_id?: any;
  total_amount?: any;
  user_info_id: string;
  coupon_id?: any;
  coupon?: any;
  user_info?: UserInfo;
  user_id?: any;
  reason?: string;
  store_pay_phone_number?: string;
}

export interface UserInfoQuery {
  user_info_id?: string;
  email?: string;
}

export interface Coupon {
  id: string;
  type: CouponType;
  sale_amount: number;
  sale_percentage: number;
  stock: number;
  start_date: Date;
  end_date: Date;
  is_refund: boolean;
}

export interface OrderProduct {
  id: number;
  order: Order;
  product: ProductReadUI;
  product_variation?: ProductVariationReadUI;
  bundled_product?: BundledProductReadUI;
  type: ProductType;
  count: number;
  price: number;
}

export interface OrderQuery {
  id?: number;
  status?: string;
  dates?: moment.Moment[];
  id__icontains?: string;
  created_date__gte?: Date;
  created_date__lte?: Date;
  page: number;
  invoice_id?: string;
  user_info_id?: string;
}

export enum OrderStatus {
  Error = "ERR",
  New = "NEW",
  Callback = "CAL",
  Approved = "APP",
  Declined = "DEC",
  PickPackFailed = "PPF",
  PickPackSuccess = "PPS",
  EbarimtFailed = "EBF",
  ReadyToDeliver = "RTD",
  Shipped = "SHI",
  Delivered = "DEL",
  Completed = "COM",
  Cancelled = "CAN",
}

export interface BankPayment extends SmokOption {
  link: string;
}

export interface LoanCredit {
  value: number;
  msgList: MsgList[];
  status: string;
}

export interface MsgList {
  code: string;
}
