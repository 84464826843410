import React from "react";
import { Link } from "react-router-dom";
import { getStoreCategoryPath, storePath } from "../../store/screen/Store";

export interface Slogan {
  key?: string;
  title: string;
  description?: string;
  bulletPoints?: string[];
  image: string;
  link?: string;
}

export interface SloganItemProps {
  key: string;
  slogan: Slogan;
}

const slogans = [
  {
    title: "Зориулалтын нээлтэй REFILL под",
    description: "Нэг подыг 4-5 цэнэглэж ашиглах боломжтой.",
    image: "/glowing/images/smok-image-box-2.png",
    link: getStoreCategoryPath(2),
  },
  {
    title: "Хэмнэлттэй хэрэглээ",
    image: "/glowing/images/smok-image-box-1.png",
    description: "Нэг дүүргэлтээр 6,000 - 9,000₮ хэмнээрэй.",
    link: storePath,
  },
  {
    title: "Alchemaster брэнд",
    image: "/glowing/images/smok-image-box-3.png",
    description: "+200 төрлийн амтны сонголттой.",
    link: getStoreCategoryPath(4),
  },
];

function SloganItem(props: SloganItemProps) {
  return (
    <div className="col-lg-4 mb-6 mb-lg-0" key={props.slogan.key}>
      <div className="card border-0 text-center">
        <div className="mw-140 mx-auto">
          <img src={props.slogan.image} alt={props.slogan.title} />
        </div>
        <div className="card-body px-0 pt-4 pb-0">
          <h3 className="fs-18 mb-3">{props.slogan.title}</h3>
          {props.slogan.description ? (
            <p className="mb-0 px-lg-6 font-weight-400 fs-14 mb-3">
              {props.slogan.description}
            </p>
          ) : (
            <ul
              className="font-weight-400 p-0 fs-14"
              style={{
                listStyleType: "none",
              }}
            >
              {props.slogan.bulletPoints?.map((bp, index) => (
                <li key={bp} className="mb-2">
                  {bp}
                </li>
              ))}
            </ul>
          )}
          {props.slogan.link && (
            <Link to={props.slogan.link}>
              Дэлгэрэнгүй{" "}
              <svg
                className="icon icon-arrow-right"
                style={{
                  marginBottom: "2px",
                }}
              >
                <use xlinkHref="#icon-arrow-right"></use>
              </svg>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

function Slogans() {
  return (
    <div className="container mt-10">
      <div className="row">
        {slogans.map((_slogan,) => (
          <SloganItem slogan={_slogan} key={_slogan.image} />
        ))}
      </div>
    </div>
  );
}

export default Slogans;
