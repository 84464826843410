import storageService from "./storage";

function isAuthenticated(): boolean {
  const token = storageService.getToken();
  return token?.is_guest === false && token !== undefined;
}

export const helper = {
  isAuthenticated,
};
