import { toast, ToastOptions } from "react-toastify";

const option: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "dark",
};

function success(message: string, _option?: ToastOptions) {
  toast.success(message, { ...option, ..._option });
}

function error(message: string, _option?: ToastOptions) {
  toast.error(message, { ...option, ..._option });
}

function warning(message: string, _option?: ToastOptions) {
  toast.warn(message, { ...option, ..._option });
}

export const smokToast = {
  success,
  error,
  warning
};
