const TOKEN_KEY = "token";
const USER_KEY = "user";
const SHOW_AGE_RESTRICTION_KEY = 'age_restriction';
const USER_INFO_KEY = "user_info_id";
const SYSTEM_SETTINGS = "system_settings";
const EMAIL_KEY = "email";
const API_URL = process.env.REACT_APP_API_URL;
const PRODUCT_URL = "/pr";
const ORDER_URL = "/or";
const PICK_PACK_URL = "/pp";
const STORE_URL = "/st";
const USER_INFO_URL = "/au";
const SETTINGS_URL = "/se";
const LOTTERY_URL = "/lo";
const AUTH_URL = "/auth";
const DATE_FORMAT = "YYYY-MM-DD";
const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
const BANNERS = {
  HOME_PAGE: "HOME_PAGE",
};

const PAGE_SIZE = 10;

const getProductUrl = (url: string) => PRODUCT_URL + url;
const getStoreUrl = (url: string) => STORE_URL + url;
const getOrderUrl = (url: string) => ORDER_URL + url;
const getPickPackUrl = (url: string) => PICK_PACK_URL + url;
const getUserInfoUrl = (url: string) => USER_INFO_URL + url;
const getAbsoluteUrl = (url: string) => API_URL + url;
const getSettingsUrl = (url: string) => SETTINGS_URL + url;
const getAuthUrl = (url: string) => AUTH_URL + url;
const getLotteryUrl = (url: string) => LOTTERY_URL + url;

export const smokConstants = {
  TOKEN_KEY,
  USER_KEY,
  USER_INFO_KEY,
  EMAIL_KEY,
  API_URL,
  SHOW_AGE_RESTRICTION_KEY,
  PRODUCT_URL,
  ORDER_URL,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  SYSTEM_SETTINGS,
  getProductUrl,
  getStoreUrl,
  getOrderUrl,
  getAbsoluteUrl,
  getPickPackUrl,
  getUserInfoUrl,
  getSettingsUrl,
  getAuthUrl,
  PAGE_SIZE,
  BANNERS,
  getLotteryUrl
};
