import InfoBoxes from "../component/InfoBoxes";
import Slider from "../component/Slider";
import SpecialOffer from "../component/SpecialOffer";
import Testimonies from "../component/Testimonies";

import { Tab } from "../../../../component/AuthWrapper";
import Slogans from "../component/Slogans";
import SpecialProducts from "../component/SpecialProducts";

export const homePath = "/home";
export const homeTabPath = "/home/:tab";

export const getHomeTabPath = (tab: Tab) =>
  homeTabPath.replaceAll(":tab", tab.valueOf().toString().toLowerCase());

function Home() {
  return (
    <>
      <Slider />
      <SpecialProducts />
      <Slogans />
      <img src="/glowing/images/smok-lottery.png" className="w-100" />
      <Testimonies />
      <SpecialOffer />
      <InfoBoxes />
    </>
  );
}

export default Home;
