import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import { LotteryRecord } from "../model/lottery.model";

function getLotteryRecord(b_code: string): Promise<LotteryRecord> {
  return api.get(smokConstants.getLotteryUrl("/lottery-records"), {
    params: {
      b_code,
    },
  });
}

export const lotteryService = {
  getLotteryRecord,
};
