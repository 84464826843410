import { useEffect, useRef, useState } from "react";
import { SmokOption } from "../helper/shared.model";

export interface CustomDropDownProps<T extends SmokOption> {
  placeholder?: string;
  value?: T;
  onChange?: (value?: T) => void;
  options?: T[];
  height?: string;
  imageHeight?: string;
  magnify?: boolean;
}

function CustomDropDown<T extends SmokOption>(props: CustomDropDownProps<T>) {
  let closeButtonRef = useRef<HTMLButtonElement>(null);
  let openLinkRef = useRef<HTMLAnchorElement>(null);
  const [toggle, setToggle] = useState(false);

  const getSelectedOption = (options?: T[], value?: T) => {
    if (value === undefined) return undefined;

    let selectedOption = options?.find((o) => o._id === value._id);

    return selectedOption;
  };

  const [selectedOption, setSelectedOption] = useState<T | undefined>(
    getSelectedOption(props.options, props.value)
  );

  useEffect(() => {
    setSelectedOption(getSelectedOption(props.options, props.value));
  }, [props.value]);

  const open = () => {
    if (openLinkRef.current !== null) {
      openLinkRef.current.click();
    }
  };

  return (
    <div
      className="custom-dropdown btn-group"
    >
      <button
        type="button"
        className="btn dropdown-toggle w-100"
        style={{
          minWidth: "250px",
          height: props.height ?? "100px",
        }}
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {selectedOption ? (
          <div
            className="d-flex"
            style={{
              whiteSpace: "normal",
              alignItems: "center",
            }}
          >
            <div
              className="mr-2 "
              style={{
                flex: `0 0 ${props.imageHeight ?? "60px"}`,
              }}
              onClick={(e) => {
                if (props.magnify) {
                  open();
                  e.stopPropagation();
                }
              }}
            >
              {props.magnify === true ? (
                <div className="sk-image-overlay">
                  <img
                    src={selectedOption._image}
                    alt={selectedOption?._label}
                  />
                  <div className="sk-overlay">
                    <div className="mt-3">
                      <i className="fal fa-search"></i>
                    </div>
                    <div className="overlay-text">Томруулах</div>
                  </div>
                </div>
              ) : (
                <img src={selectedOption._image} alt={selectedOption?._label} />
              )}
            </div>
            <div className="media-body text-secondary text-left fs-14">
              {selectedOption?._label}
              <div className="text-muted fs-11">
                {selectedOption?._description}
              </div>
            </div>
          </div>
        ) : (
          <div className="media align-items-center">
            <div className="media-body text-secondary text-left fs-14 pl-4 text-muted">
              {props.placeholder}
            </div>
          </div>
        )}
      </button>
      <div
        className="dropdown-menu"
        style={{
          maxHeight: "300px",
          overflowY: "scroll",
          display: toggle ? "block" : "none",
        }}
      >
        {props.options?.map((_option) => (
          <div
            key={_option._id}
            className="dropdown-item d-flex align-items-center"
            style={{
              whiteSpace: "normal",
              alignItems: "center",
              height: props.height ?? "100px",
            }}
            onClick={(e) => {
              setSelectedOption(_option);
              setToggle(false);
              props.onChange && props.onChange(_option);
            }}
          >
            <div
              className="mr-2"
              style={{
                flex: `0 0 ${props.imageHeight ?? "60px"}`,
              }}
            >
              <img src={_option._image} alt={_option._label} />
            </div>
            <div className="text-secondary text-left fs-13">
              {_option._label}
              {_option?._description && (
                <div className="text-muted fs-11 mt-2">
                  {_option?._description}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <a
        ref={openLinkRef}
        data-toggle="modal"
        data-target={`#magnify-${selectedOption?._id}`}
      />

      <div
        className="modal"
        id={`magnify-${selectedOption?._id}`}
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <img src={selectedOption?._image} />
          </div>
        </div>
        <button
          ref={closeButtonRef}
          type="button"
          className="d-none"
          data-dismiss="modal"
        ></button>
      </div>
    </div>
  );
}

export default CustomDropDown;
