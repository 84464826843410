import React from "react";

export enum EmptySize {
  Small,
  Medium,
}

export interface EmptyProps {
  message?: string;
  size?: EmptySize;
}

function Empty(props: EmptyProps) {
  return (
    <div
      className={`card bg-transparent border-0 align-items-center text-center ${
        props.size === undefined || props.size === EmptySize.Medium
          ? "p-15"
          : "p-10"
      }`}
    >
      <div className="card-img">
        <svg className="icon icon-box-01 fs-40 text-muted">
          <use xlinkHref={`#icon-empty`} />
        </svg>
      </div>
      <div className="card-body text-center">
        <h3 className="card-title fs-15 text-muted font-weight-500">
          {props.message ?? "Бичлэг олдсонгүй."}
        </h3>
      </div>
    </div>
  );
}

export default Empty;
