import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import { Banner, Page, PageQuery, SystemSettings } from "../model/settings.model";

const BANNER_URL = "/banners/";
const PAGE_URL = "/pages/";
const SETTINGS_URL = "/system-settings/";

function getBanners(): Promise<Banner[]> {
  return api.get(smokConstants.getSettingsUrl(BANNER_URL));
}

function getBanner(key: string): Promise<Banner> {
  return api.get(`${smokConstants.getSettingsUrl(BANNER_URL)}${key}/`);
}

function getPages(params: PageQuery): Promise<Page[]> {
  return api.get(smokConstants.getSettingsUrl(PAGE_URL), { params });
}

function getSystemSettings(): Promise<SystemSettings[]> {
  return api.get(smokConstants.getSettingsUrl(SETTINGS_URL));
}

export const settingsService = {
  getBanners,
  getBanner,
  getPages,
  getSystemSettings,
};
