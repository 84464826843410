import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext } from "react";
import { SpinnerContext } from "../helper/spinner.context";
import { actions as spinnerAction } from "../helper/spinner.reducer";
import { smokToast } from "../helper/toast";
import { Resend } from "../module/private/user/model/auth.model";
import { authService } from "../module/private/user/service/auth.service";
import { Tab } from "./AuthWrapper";

export interface ResetPasswordProps {
  onChangeTab: (tab: Tab) => void;
  onComplete: () => void;
}

function ResetPassword(props: ResetPasswordProps) {
  let [form] = useForm();
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);

  const onFinish = (values: Resend) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    authService
      .resetPassword(values)
      .then((x) => {
        smokToast.success(
          "И-мэйл рүү нууц үг шинэчлэх мэйлийг амжилттай илгээлээ."
        );
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  return (
    <div>
      <h4 className="fs-34 text-center mb-6">Нууц үгээ шинэчлэх</h4>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          className="mb-6"
          rules={[
            {
              required: true,
              message: "И-мэйл хаягийг оруулна уу.",
            },
          ]}
        >
          <input
            type="email"
            className="form-control border-0"
            placeholder="И-мэйл хаяг"
          />
        </Form.Item>

        <button
          type="submit"
          className="btn btn-secondary btn-block bg-hover-primary border-hover-primary"
        >
          Үргэлжлүүлэх
        </button>
      </Form>
    </div>
  );
}

export default ResetPassword;
