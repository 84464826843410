import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ResetPasswordConfirm, { resetPasswordConfirmPath } from "./auth/screen/ResetPasswordConfirm";
import UserActivation, {
  userActivationPath,
} from "./auth/screen/UserActivation";

function PublicRoutes() {
  return (
    <main id="content">
      <div
        className="modal"
        style={{
          display: "block",
          backgroundColor: "#0f1214",
        }}
      >
        <div
          className="modal-dialog"
          style={{
            pointerEvents: "unset",
          }}
        >
          <div className="navbar-brand text-center d-block mb-5">
            <img src="/glowing/images/smok-logo-white.png" alt="SMOK" />
          </div>
          <Routes>
            <Route path={resetPasswordConfirmPath} element={<ResetPasswordConfirm />} />
            <Route path={userActivationPath} element={<UserActivation />} />
          </Routes>
        </div>
      </div>
    </main>
  );
}

export default PublicRoutes;
