import { Link } from "react-router-dom";
import CrossFadingImage from "../../../../component/CrossFadingImage";
import { smokConstants } from "../../../../constant/constants";
import storageService from "../../../../helper/storage";
import { utils } from "../../../../helper/utils";
import { Product } from "../../store/model/product.model";
import { getProductDetailPath } from "../../store/screen/ProductDetail";

export enum ProductItemType {
  Home,
  SpecialOffer,
  Store,
  SimiliarProduct,
}

export interface ProductItemProps {
  key?: string;
  product: Product;
  type: ProductItemType;
}

export const getPrice = (_product: Product) => {
  if (utils.isProductWithSale(_product)) {
    return (
      <>
        <span className="fs-13 font-weight-500 text-decoration-through text-body pr-1">
          {utils.formatCurrency(_product.price)}
        </span>
        <span>{utils.formatCurrency(_product.sale_price)}</span>
      </>
    );
  } else {
    return <span>{utils.formatCurrency(_product.price)}</span>;
  }
};

export const getName = (_product: Product) => {
  return _product.name.toUpperCase();
};

export const getStockLabel = (stock: number) => {
  const showStockMin = storageService.getSetting("stock-min");

  if (stock > Number(showStockMin?.value)) {
    return <span className="text-success">Бэлэн</span>;
  } else if (stock === 0) {
    return <span className="text-danger">Дууссан</span>;
  } else {
    return <span className="text-danger">{stock} ширхэг</span>;
  }
};

function ProductItem(props: ProductItemProps) {
  if (props.type === ProductItemType.Home) {
    return (
      <div className="col-lg-3 col-sm-6 mb-5">
        <Link to={getProductDetailPath(props.product.sku)}>
          <div className="card border-0 product">
            <div className="position-relative">
              <img
                className="mx-auto"
                style={{
                  height: "440px",
                  width: "fit-content",
                }}
                src={
                  props.product.image
                    ? smokConstants.getAbsoluteUrl(props.product.image)
                    : ""
                }
                alt={props.product.name}
              />
              {utils.isProductWithSale(props.product) && (
                <div className="card-img-overlay d-flex p-3">
                  <span className="badge badge-primary">
                    {props.product.sale_price &&
                      `- ${utils.getSalePercentage(props.product)}% OFF`}
                  </span>
                </div>
              )}
            </div>
            <div className="card-body px-0 pt-4 text-center">
              <p className="card-text font-weight-bold fs-16 mb-1 text-secondary">
                {getPrice(props.product)}
              </p>
              <h2 className="card-title fs-15 font-weight-500 mb-2">
                {getName(props.product)}
              </h2>
            </div>
          </div>
        </Link>
      </div>
    );
  } else if (props.type === ProductItemType.SpecialOffer) {
    return (
      <Link to={getProductDetailPath(props.product.sku)}>
        <div className="card border-0 product bg-transparent">
          <img
            className="mx-auto pb-5"
            style={{
              height: "350px",
              objectFit: "contain",
              width: "auto",
            }}
            src={
              props.product.image
                ? smokConstants.getAbsoluteUrl(
                    props.product.special_offer_image
                  )
                : ""
            }
            alt={props.product.name}
          />
          <div className="card-img-overlay d-flex">
            {utils.isProductWithSale(props.product) && (
              <div>
                <span className="badge badge-primary">
                  {props.product.sale_price &&
                    `- ${utils.getSalePercentage(props.product)}% OFF`}
                </span>
              </div>
            )}
          </div>
          <div className="card-body text-center p-0">
            <p className="card-text font-weight-bold fs-16 mb-1 text-secondary text-light">
              {getPrice(props.product)}
            </p>
            <h2 className="card-title fs-15 font-weight-500 mb-2 text-light">
              {getName(props.product)}
            </h2>
          </div>
        </div>
      </Link>
    );
  } else if (props.type === ProductItemType.Store) {
    return (
      <div className="col-xl-3 col-lg-4 col-md-6">
        <Link to={getProductDetailPath(props.product.sku)}>
          <div className="card border-0 product mb-6">
            <CrossFadingImage
              firstUrl={props.product.image}
              secondUrl={props.product.image2}
              height="370px"
            />
            <div className="card-body pt-4 text-center px-0">
              <p className="card-text font-weight-bold fs-16 mb-1 text-secondary">
                {getPrice(props.product)}
              </p>
              <h2 className="card-title fs-15 font-weight-500 mb-2">
                {getName(props.product)}
              </h2>
            </div>
          </div>
        </Link>
      </div>
    );
  } else {
    return (
      <Link to={getProductDetailPath(props.product.sku)}>
        <div className="card border-0 product">
          <div className="position-relative">
            <img
              className="mx-auto"
              style={{
                height: "300px",
                width: "fit-content",
              }}
              src={
                props.product.image
                  ? smokConstants.getAbsoluteUrl(props.product.image)
                  : ""
              }
              alt={props.product.name}
            />
          </div>
          <div className="card-body pt-4 text-center">
            <p className="card-text font-weight-bold fs-16 mb-1 text-secondary">
              {getPrice(props.product)}
            </p>
            <h2 className="card-title fs-15 font-weight-500 mb-2">
              {getName(props.product)}
            </h2>
          </div>
        </div>
      </Link>
    );
  }
}

export default ProductItem;
