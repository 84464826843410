import React from "react";
import { utils } from "../../../../helper/utils";
import { SmokStore } from "../model/store.model";

export interface StoreInfoWindowProps {
  store: SmokStore;
  onClick: () => void;
}

function StoreInfoWindow(props: StoreInfoWindowProps) {
  return (
    <div
      className="p-2 pl-3"
      style={{
        width: "200px",
      }}
    >
      <div className="text-center">
        <img
          style={{
            width: "80px",
          }}
          src="/glowing/images/smok-logo-black-2.png"
          alt="SMOK"
          className="mb-2"
        />
        <div className="fs-12 text-secondary mb-4 text-uppercase font-weight-500 mt-2">
          {props.store.name}
        </div>
      </div>

      <a
        className="btn btn-secondary btn-block btn-sm bg-hover-primary border-hover-primary mt-3"
        onClick={props.onClick}
      >
        Google map нээх
      </a>
    </div>
  );
}

export default StoreInfoWindow;
