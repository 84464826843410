import { useEffect, useState } from "react";

interface Testimony {
  index?: number;
  image: string;
  text: string;
}

export interface TestimonyLogoItemProps {
  selectedIndex?: number;
  key: string;
  testimony: Testimony;
  onPress?: () => void;
}

const testimoniesData: { index?: number; image: string; text: string }[] = [
  {
    image: "/glowing/images/cert-logo-SGS.png",
    text: "ISO 9001 чанарийн стандарт хангасан",
  },
  {
    image: "/glowing/images/cert-logo-ROHS.png",
    text: "ROHS Certificate стандарт хангасан баталгаа",
  },
  {
    image: "/glowing/images/cert-logo-CE.png",
    text: "Certificate of Conformity буюу Европийн зах зээлд хүлээн зөвшөөрөгдсөн",
  },
  {
    image: "/glowing/images/cert-logo-UL.png",
    text: "MSDS Certification материалийн аюулгүй байдал",
  },
  {
    image: "/glowing/images/cert-logo-EMC.png",
    text: "EMC Test Report",
  },
];

function TestimonyLogoItem(props: TestimonyLogoItemProps) {
  return (
    <div className="box col-4 col-sm-2" onClick={props.onPress}>
      <div className="d-block mw-150 mx-auto">
        <img
          src={props.testimony.image}
          alt={`Testominy-${props.testimony.index}`}
          className={`${
            props.selectedIndex !== props.testimony.index ? "opacity-3" : ""
          } opacity-hover-10 cursor-pointer`}
          style={{
            width: "120px",
            margin: "0 auto",
          }}
        />
      </div>
    </div>
  );
}

function TestimonyTextItem(props: TestimonyLogoItemProps) {
  return (
    <div className="box" style={{ height: "80px" }}>
      <h4 className="mx-auto text-center mw-750 mb-5 fs-18 text-uppercase">
        {props.testimony.text}
      </h4>
    </div>
  );
}

function Testimonies() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [testimonies, setTestimonies] = useState<Testimony[]>([]);

  useEffect(() => {
    testimoniesData.forEach((element, index) => {
      element.index = index;
    });

    setTestimonies(testimoniesData);
  }, []);

  return (
    <section className="my-10">
      {testimonies.length > 0 && (
        <div className="container">
          <div>
            <TestimonyTextItem
              key={testimoniesData[selectedIndex].image}
              testimony={testimoniesData[selectedIndex]}
            />
          </div>
          <div className="row">
            <div className="box col-sm-1 col-2"></div>
            {testimonies.map((_testimony) => (
              <TestimonyLogoItem
                selectedIndex={selectedIndex}
                key={_testimony.image}
                testimony={_testimony}
                onPress={() => {
                  setSelectedIndex(_testimony.index ?? 0);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
}

export default Testimonies;
