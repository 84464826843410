import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions } from "../../../../helper/spinner.reducer";
import { smokToast } from "../../../../helper/toast";
import { utils } from "../../../../helper/utils";
import CurrentLocationSearchBox from "../component/CurrentLocationSearchBox";
import LocationNavigator from "../component/LocationNavigator";
import StoreInfoWindow from "../component/StoreInfoWindow";
import { SmokStore } from "../model/store.model";
import { storeService } from "../service/store.service";
import { useParams } from "react-router-dom";

export const locatorPathWithParam = "/locator/:id";
export const getLocatorPathWithParam = (id: number) =>
  locatorPathWithParam.replaceAll(":id", id.toString());
export const locatorPath = "/locator";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 250px)",
};

const center: google.maps.LatLngLiteral = {
  lat: 47.91955140596552,
  lng: 106.91732424796884,
};

export const googleMapUrl = (
  origin?: google.maps.LatLngLiteral,
  destination?: google.maps.LatLngLiteral
) =>
  `https://www.google.com/maps/dir/?api=1${
    origin ? `&origin=${origin.lat},${origin.lng}` : ""
  }${
    destination ? `&destination=${destination.lat},${destination.lng}` : ""
  }&travelmode=walking`;

const googleMapsApiKey = "AIzaSyDH5Ot4MbgshjtJtws4A9DTUOOGcVPPEso";

function Locator() {
  const [count, setCount] = useState(0);
  const [location, setLocation] = useState<google.maps.LatLngLiteral>();
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [stores, setStores] = useState<SmokStore[]>([]);
  const [selectedStore, setSelectedStore] = useState<SmokStore>();
  const { dispatch } = useContext(SpinnerContext);
  const [reset, setReset] = useState(false);
  const { id } = useParams();
  const mapDiv = useRef<HTMLDivElement>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"],
  });

  useEffect(() => {
    if (id && map && stores.length > 0) {
      let _store = stores.find((x) => x.id === Number(id));

      if (_store) {
        onStoreSelect(_store).then((x) => {});
      }
    }
  }, [id, stores, map]);

  useEffect(() => {
    if (map != null) {
      onLocationFound();
    }
  }, [map]);

  const onLocationFound = () => {
    dispatch({
      type: actions.BLOCK,
    });

    setReset(!reset);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        let currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        onCurrentLocationLocated(currentLocation);
      },
      (err) => {
        smokToast.warning(
          "Байршлын мэдээллийг уншихад алдаа гарлаа. Та одоо байгаа байршлаа доорх талбар бичиж оруулна уу."
        );

        storeService.getStoresAll().then((_stores) => {
          _stores.forEach((x) => (x.distance = 0));

          setStores(_stores);
        });

        dispatch({
          type: actions.UNBLOCK,
        });

        throw err;
      }
    );
  };

  const onCurrentLocationLocated = async (
    currentLocation: google.maps.LatLngLiteral
  ) => {
    setLocation(currentLocation);

    if (map !== null) map.setZoom(1);

    let pagination = await storeService.getStoresAll();
    let _stores = pagination;

    setCount(pagination.length);

    _stores.forEach(
      (x) =>
        (x.distance = utils.calcCrow(
          x.latitude,
          x.longitude,
          currentLocation.lat,
          currentLocation.lng
        ))
    );

    _stores = _stores.sort((l1, l2) => {
      let d1 = l1.distance;
      let d2 = l2.distance;

      if (d1 && d2) {
        return d1 - d2;
      } else {
        return 1;
      }
    });

    setStores(_stores);

    const bounds = new window.google.maps.LatLngBounds(currentLocation);

    map!.fitBounds(bounds);

    dispatch({
      type: actions.UNBLOCK,
    });
  };

  const onInfoWindowClick = () => {
    if (selectedStore) {
      let url = googleMapUrl(location, {
        lat: selectedStore?.latitude,
        lng: selectedStore?.longitude,
      });

      window.open(url, "_blank");
    }
  };

  const onStoreSelect = async (_store: SmokStore) => {
    const directionService = new google.maps.DirectionsService();

    if (location) {
      try {
        const result = await directionService.route({
          origin: location,
          destination: {
            lat: _store.latitude,
            lng: _store.longitude,
          },
          travelMode: google.maps.TravelMode.WALKING,
        });

        _store.directionResponse = result;
        setSelectedStore(_store);

        mapDiv.current?.scrollIntoView();
      } catch (err) {
        // TODO: show error dialog
        console.log(JSON.stringify(err));
      }
    }
  };

  const onLoad = useCallback((map: any) => setMap(map), []);

  const onUnmount = React.useCallback(function callback(_map: any) {
    setMap(null);
  }, []);

  return (
    <div className="row no-gutters">
      <div className="col-md-4 col-lg-3 location-navigator">
        <div>
          <LocationNavigator
            count={count}
            stores={stores}
            onSelectedStoreChange={onStoreSelect}
          />
        </div>
      </div>
      <div className="col-md-8 col-lg-9" ref={mapDiv}>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={location}
            zoom={14}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              maxZoom: 17,
              minZoom: 13,
            }}
          >
            <CurrentLocationSearchBox
              onPlaceSelected={onCurrentLocationLocated}
              onLocationClicked={onLocationFound}
              reset={reset}
            />
            {stores.map((_store) => {
              let image = {
                url: "/glowing/images/smok-image-marker.png",
                scaledSize: new google.maps.Size(40, 40),
              };

              return (
                <Marker
                  key={`marker-${_store.id}`}
                  clickable
                  position={{ lat: _store.latitude, lng: _store.longitude }}
                  onClick={() => onStoreSelect(_store)}
                  icon={image}
                >
                  {selectedStore?.id === _store.id && (
                    <InfoWindow key={`info-window-${_store.id}`}>
                      <StoreInfoWindow
                        store={_store}
                        onClick={onInfoWindowClick}
                      />
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}

            {location && (
              <InfoWindow
                options={{ position: location }}
                key={`info-window-here`}
              >
                <div
                  className="font-weight-600 fs-13 text-secondary"
                  style={{
                    marginLeft: "7px",
                  }}
                >
                  Та энд байна.
                </div>
              </InfoWindow>
            )}

            {selectedStore?.directionResponse && (
              <DirectionsRenderer
                directions={selectedStore?.directionResponse}
              />
            )}
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default React.memo(Locator);
