import React, { ReactNode, useReducer } from "react";
import { initialState, CartState, reducer, CartAction } from "./cart.reducer";

export const CartContext = React.createContext<{
  state: CartState;
  dispatch: React.Dispatch<CartAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export interface ProviderProps {
  children: ReactNode;
}

export const CartProvider = (props: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CartContext.Provider>
  );
};
