import React, { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions } from "../../../../helper/spinner.reducer";
import { utils } from "../../../../helper/utils";
import { googleMapUrl } from "../../locator/screen/Locator";
import { Page } from "../../settings/model/settings.model";
import { settingsService } from "../../settings/service/settings.service";
import storageService from "../../../../helper/storage";

export const contactUsPath = `/contact-us`;

function ContactUs() {
  const [pages, setPages] = useState<Page[]>([]);
  const [coordinates, setCoordinates] = useState<number[]>([
    47.9186349, 106.9162924,
  ]);
  const { dispatch } = useContext(SpinnerContext);

  useEffect(() => {
    let _coordinates = storageService
      .getSetting("coordinates")
      ?.value.split(",")
      .map((x) => Number(x));

    if (_coordinates && _coordinates.length > 0) {
      let isNanExists = _coordinates?.filter((x) => isNaN(x)).length > 0;

      if (!isNanExists) {
        setCoordinates(_coordinates);
      }
    }

    dispatch({
      type: actions.BLOCK,
    });

    settingsService
      .getPages({
        type__in: ["Address", "Contact", "Timetable"].join(","),
      })
      .then((_pages) => {
        setPages(_pages);
      })
      .finally(() => {
        dispatch({
          type: actions.UNBLOCK,
        });
      });
  }, []);

  return (
    <section className="pt-9">
      <div className="container">
        <h2 className="text-center mb-6" data-animate="fadeInUp">
          Холбоо барих
        </h2>
        <p
          className="text-center mb-12 mt-8 mx-auto fs-16 font-weight-400"
          data-animate="fadeInUp"
          dangerouslySetInnerHTML={{
            __html: utils.getHtml(pages, "Contact-Title"),
          }}
        ></p>
        <div className="row mb-10">
          <div className="col-md-4 mb-md-0 mb-8" data-animate="fadeInUp">
            <div className="media">
              <div className="icon pr-lg-6 pr-5">
                <svg className="icon icon-box-07 fs-40 text-primary">
                  <use xlinkHref="#icon-box-07" />
                </svg>
              </div>
              <div className="media-body">
                <h3 className="fs-20 mb-3">Хаяг</h3>
                <p
                  className="fs-16 font-weight-400"
                  dangerouslySetInnerHTML={{
                    __html: utils.getHtml(pages, "Address"),
                  }}
                ></p>
                <a
                  href={googleMapUrl(undefined, {
                    lat: coordinates[0],
                    lng: coordinates[1],
                  })}
                  className="btn btn-link p-0 border-bottom border-0 border-secondary rounded-0"
                >
                  Google map - руу үсрэх
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-md-0 mb-8" data-animate="fadeInUp">
            <div className="media">
              <div className="icon pr-lg-6 pr-5">
                <svg className="icon icon-box-05 fs-40 text-primary">
                  <use xlinkHref="#icon-box-05" />
                </svg>
              </div>
              <div className="media-body">
                <h3 className="fs-20 mb-3">Утас, и-мэйл</h3>
                <p
                  className="fs-16 font-weight-400"
                  dangerouslySetInnerHTML={{
                    __html: utils.getHtml(pages, "Contact"),
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-md-0 mb-8" data-animate="fadeInUp">
            <div className="media">
              <div className="icon pr-lg-6 pr-5">
                <svg className="icon icon-box-06 fs-40 text-primary">
                  <use xlinkHref="#icon-box-06" />
                </svg>
              </div>
              <div className="media-body">
                <h3 className="fs-20 mb-3">Ажиллах цагийн хуваарь</h3>
                <p
                  className="fs-16 font-weight-400"
                  dangerouslySetInnerHTML={{
                    __html: utils.getHtml(pages, "Timetable"),
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
