import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CartProductCounter, {
  CartProductCounterType,
} from "../../../../component/CartProductCounter";
import {
  getImageUrl,
  getName,
  getTotalPrice,
} from "../../../../component/CartProductItem";
import { SpinnerContext } from "../../../../helper/spinner.context";
import {
  actions as spinnerAction,
  SpinnerAction,
} from "../../../../helper/spinner.reducer";
import { utils } from "../../../../helper/utils";
import { getPrice } from "../../home/component/ProductItem";
import { checkoutPath } from "../../order/screen/Checkout";
import { CartProductReadUI } from "../../store/model/cart.model";
import { storePath } from "../../store/screen/Store";
import { CartContext } from "../../store/service/cart.context";
import { actions, CartAction } from "../../store/service/cart.reducer";
import { cartService } from "../../store/service/cart.service";
import AddCoupon from "../component/AddCoupon";

export const refreshCart = (
  dispatchSpinner: React.Dispatch<SpinnerAction>,
  dispatch: React.Dispatch<CartAction>
) => {
  dispatchSpinner({
    type: spinnerAction.BLOCK,
  });

  cartService
    .getCartProducts()
    .then((cartProducts) => {
      dispatch({
        type: actions.SET_CART_PRODUCTS,
        cartProducts: cartProducts,
      });
    })
    .finally(() => {
      dispatchSpinner({
        type: spinnerAction.UNBLOCK,
      });
    });

  cartService.getCartSummary().then((x) => {
    dispatch({
      type: actions.SET_SUMMARY,
      summary: x,
    });
  });
};

export const cartPath = `/cart`;

function Cart() {
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const { state, dispatch } = useContext(CartContext);
  let navigate = useNavigate();

  const editCartProduct = (cartProduct: CartProductReadUI, count: number) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    cartService
      .editCartProduct({ ...cartProduct, count })
      .then((x) => {
        refreshCart(dispatchSpinner, dispatch);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  const deleteCartProduct = (cartProduct: CartProductReadUI) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    cartService
      .deleteCartProduct(cartProduct)
      .then((x) => {
        refreshCart(dispatchSpinner, dispatch);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  const clearCart = () => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    cartService
      .clearCart()
      .then((x) => {
        refreshCart(dispatchSpinner, dispatch);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  };

  const checkout = () => {
    navigate(checkoutPath);
  };

  return (
    <section className="pb-11 pb-lg-13">
      <div className="container">
        <h2 className="text-center mt-9 mb-8">Сагс</h2>
        <div className="pb-8 pb-lg-10">
          <div className="table-responsive">
            <table className="table border">
              <thead style={{ backgroundColor: "#F5F5F5" }}>
                <tr className="fs-15 letter-spacing-01 font-weight-600 text-uppercase text-secondary">
                  <th scope="col" className="border-1x pl-7">
                    Бүтээгдэхүүн
                  </th>
                  <th scope="col" className="border-1x">
                    Тоо ширхэг
                  </th>
                  <th colSpan={2} className="border-1x">
                    Үнэ
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.cartProducts !== undefined &&
                state.cartProducts.length > 0 ? (
                  state.cartProducts?.map((_cartProduct) => (
                    <tr key={_cartProduct.id} className="position-relative">
                      <th scope="row" className="w-xl-695 pl-xl-5 py-4">
                        <div className="media align-items-center">
                          <div className="ml-3 mr-4">
                            <img
                              src={getImageUrl(_cartProduct)}
                              alt={_cartProduct.product.name}
                              className="mw-75px"
                            />
                          </div>
                          <div className="media-body w-128px">
                            <p className="font-weight-500 mb-1 text-secondary">
                              {getName(_cartProduct)}
                            </p>
                            <p className="card-text font-weight-bold fs-14 mb-1 text-secondary">
                              {getPrice(_cartProduct.product)}
                            </p>
                          </div>
                        </div>
                      </th>
                      <td className="align-middle">
                        <div className="input-group position-relative w-128px">
                          <CartProductCounter
                            value={_cartProduct.count}
                            disabled={_cartProduct.bundled_product_id !== null}
                            type={CartProductCounterType.CartTable}
                            onChange={(value: number) =>
                              editCartProduct(_cartProduct, value)
                            }
                          />
                        </div>
                      </td>
                      <td className="align-middle">
                        {_cartProduct.bundled_product_id === null && (
                          <p className="mb-0 text-secondary font-weight-bold mr-xl-11">
                            {utils.formatCurrency(getTotalPrice(_cartProduct))}
                          </p>
                        )}
                      </td>
                      <td className="align-middle text-right pr-5">
                        {_cartProduct.bundled_product_id === null && (
                          <a
                            className="d-block"
                            onClick={() => deleteCartProduct(_cartProduct)}
                          >
                            <i className="fal fa-times text-body" />
                          </a>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center p-6" colSpan={3}>
                      Бүтээгдэхүүн олдсонгүй.
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    className="pb-6 pl-0 position-relative bg-white"
                    style={{ left: "-1px" }}
                  >
                    <button
                      type="submit"
                      value="Countinue Shopping"
                      className="btn btn-outline-secondary border-2x border mr-5 border-hover-secondary my-3"
                      onClick={() => navigate(storePath)}
                    >
                      Бүтээгдэхүүн нэмэх
                    </button>
                    <button
                      className="btn btn-link p-0 border-0 border-bottom border-secondary rounded-0 my-3"
                      onClick={() => clearCart()}
                    >
                      <i className="fal fa-times mr-2 text-secondary" />
                      Сагс цэвэрлэх
                    </button>
                  </td>

                  <td
                    colSpan={3}
                    className="text-right pb-6 pr-0 position-relative bg-white"
                    style={{ right: "-2px" }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 pt-2">
            <h4 className="fs-24 mb-3">Купон</h4>
            <p>Доор талбарт купоны дугаарыг оруулна уу.</p>
            <AddCoupon />
          </div>
          <div className="col-lg-4 pt-lg-2 pt-10"></div>
          <div className="col-lg-4 pt-lg-0 pt-11">
            <div
              className="card border-0"
              style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)" }}
            >
              <div className="card-body px-6 pt-5">
                <div className="d-flex align-items-center mb-2">
                  <span>Нийт дүн:</span>
                  <span className="d-block ml-auto text-secondary font-weight-bold">
                    {utils.formatCurrency(state.summary?.summary)}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span>Хүргэлт:</span>
                  <span className="d-block ml-auto text-secondary font-weight-bold">
                    {utils.formatCurrency(
                      state.cartProducts && state.cartProducts?.length > 0
                        ? state.summary?.delivery
                        : 0
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span>Хямдрал:</span>
                  <span className="d-block ml-auto text-danger font-weight-bold">
                    - {utils.formatCurrency(state.summary?.sale)}
                  </span>
                </div>
              </div>
              <div className="card-footer bg-transparent px-0 pb-4 mx-6">
                <div className="d-flex align-items-center font-weight-bold mb-3">
                  <span className="text-secondary">Төлөх ёстой дүн:</span>
                  <span className="d-block ml-auto text-secondary fs-24 font-weight-bold">
                    {utils.formatCurrency(
                      state.cartProducts && state.cartProducts?.length > 0
                        ? state.summary?.total
                        : 0
                    )}
                  </span>
                </div>
                <button
                  onClick={checkout}
                  disabled={Number(state.summary?.summary) == 0.0}
                  type="submit"
                  className="btn btn-secondary btn-block bg-hover-primary border-hover-primary"
                >
                  Баталгаажуулах
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cart;
