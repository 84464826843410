import { useContext, useEffect, useState } from "react";
import { smokConstants } from "../../../../constant/constants";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions } from "../../../../helper/spinner.reducer";
import { utils } from "../../../../helper/utils";
import { SmokImageReadUI, SmokImageType } from "../../store/model/image.model";
import { imageService } from "../../store/service/image.service";

function Slider() {
  const [images, setImages] = useState<SmokImageReadUI[]>([]);
  const { dispatch } = useContext(SpinnerContext);

  useEffect(() => {
    dispatch({
      type: actions.BLOCK,
    });

    imageService
      .getImages({
        type: SmokImageType.Banner,
        banner_id: smokConstants.BANNERS.HOME_PAGE,
      })
      .then((_images) => {
        setImages(_images);
        utils.initSlickSlider();
      })
      .finally(() => {
        dispatch({
          type: actions.UNBLOCK,
        });
      });
  }, []);

  const sliderOptions = {
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    dots: true,
    arrows: false,
    fade: true,
    cssEase: "ease-in-out",
    speed: 1500,
  };

  return (
    <section
      className="bg-secondary"
      style={{
        backgroundColor: "#000",
        width: "100%",
        minHeight: "100px",
      }}
    >
      {images.length > 0 && (
        <div
          className="slick-slider mx-0 slider slick-dots-light dots-inner-center"
          data-slick-options={JSON.stringify(sliderOptions)}
        >
          {images.map((_image) => (
            <div key={_image.id} className="box px-0">
              <div
                style={{
                  background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))`,
                  height: "40%",
                  width: "100%",
                  position: "absolute",
                }}
              ></div>
              <img src={smokConstants.getAbsoluteUrl(_image.image)} />
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

export default Slider;
