import { useEffect, useRef, useState } from "react";
import {
  isIOS,
  isMacOs,
  isMobile, isTablet
} from "react-device-detect";
import CustomDropDown from "../../../../component/CustomDropDown";
import { BankPayment, Order, PaymentType } from "../model/order.model";

export interface QPayModalProps {
  order?: Order;
  show: boolean;
  paymentType?: PaymentType;
}

function QPayModal(props: QPayModalProps) {
  let openLinkRef = useRef<HTMLAnchorElement>(null);
  let closeButtonRef = useRef<HTMLButtonElement>(null);
  const [selectedBankPayment, setSelectedBankPayment] = useState<BankPayment>();

  const banks: BankPayment[] = [
    {
      _id: "1",
      _label: "Хаан банк",
      _image: "https://qpay.mn/q/img/1.png",
      link: "khanbank://q?qPay_QRcode=",
    },
    {
      _id: "2",
      _label: "Худалдаа хөгжлийн банк",
      _image: "https://qpay.mn/q/img/2.png",
      link: "tdbbank://q?qPay_QRcode=",
    },
    {
      _id: "3",
      _label: "Төрийн банк",
      _image: "https://qpay.mn/q/img/3.png",
      link: "statebank://q?qPay_QRcode=",
    },
    {
      _id: "4",
      _label: "Хас банк",
      _image: "https://qpay.mn/q/img/4.png",
      link: "xacbank://q?qPay_QRcode=",
    },
    {
      _id: "5",
      _label: "Капитрон банк",
      _image: "https://qpay.mn/q/img/5.png",
      link: "capitronbank://q?qPay_QRcode=",
    },
    {
      _id: "6",
      _label: "Чингис хаан банк",
      _image: "https://qpay.mn/q/img/6.png",
      link: "ckbank://q?qPay_QRcode=",
    },
    {
      _id: "7",
      _label: "Богд банк",
      _image: "https://qpay.mn/q/img/7.png",
      link: "bogdbank://q?qPay_QRcode=",
    },
    {
      _id: "8",
      _label: "Үндэсний хөрөнгө оруулалтын банк",
      _image: "https://qpay.mn/q/img/8.jpeg",
      link: "nibank://q?qPay_QRcode=",
    },
    {
      _id: "9",
      _label: "Most money",
      _image: "https://qpay.mn/q/img/9.png",
      link: "most://q?qPay_QRcode=",
    },
    {
      _id: "10",
      _label: "Тээвэр хөгжлийн банк",
      _image: "https://qpay.mn/q/img/10.png",
      link: "transbank://q?qPay_QRcode=",
    },
    {
      _id: "11",
      _label: "M банк",
      _image: "https://qpay.mn/q/img/11.png",
      link: "mbank://q?qPay_QRcode=",
    },
    {
      _id: "12",
      _label: "Ард апп",
      _image: "https://qpay.mn/q/img/12.png",
      link: "ard://q?qPay_QRcode=",
    },
  ];

  useEffect(() => {
    if (props.show) {
      openLinkRef.current?.click();
    } else {
      closeButtonRef.current?.click();
    }
  }, [props.show]);

  useEffect(() => {
    return () => {
      (window as any).app.closeAllModals();
    };
  }, []);

  return (
    <>
      <a ref={openLinkRef} data-toggle="modal" data-target="#qpay" />
      <div
        className="modal"
        id="qpay"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <button
          ref={closeButtonRef}
          type="button"
          className="close d-none"
          data-dismiss="modal"
        ></button>

        <div className="modal-dialog modal-dialog-centered modal-sm">
          {props.paymentType === PaymentType.QPAY && (
            <div className="modal-content">
              {props.order?.qr_img && (
                <img
                  className="w-100"
                  src={`data:image/png;base64,${props.order?.qr_img}`}
                />
              )}
              {(isMobile || isTablet) && (
                <div className="modal-body pt-0">
                  <CustomDropDown
                    placeholder="Төлбөр төлөх банкны апп"
                    options={banks}
                    value={selectedBankPayment}
                    onChange={setSelectedBankPayment}
                    height="60px"
                    imageHeight="40px"
                  />
                  <div className="pt-2">
                    <button
                      type="submit"
                      disabled={selectedBankPayment === undefined}
                      className="btn btn-secondary btn-block"
                      onClick={() => {
                        if (selectedBankPayment?.link) {
                          console.log("Jump to bank application: ");
                          window.open(
                            selectedBankPayment.link + props.order?.qr_text,
                            "_blank"
                          );
                        }
                      }}
                    >
                      Төлбөр төлөх
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {(isIOS || isMacOs) &&
            props.paymentType === PaymentType.SOCIAL_PAY && (
              <div className="modal-content">
                <div className="modal-body">
                  <p className="text-center">
                    Доорх товч дээр дарж Social Pay - руу шилжинэ үү.
                  </p>
                  <a
                    className="btn btn-secondary btn-block"
                    href={`https://ecommerce.golomtbank.com/socialpay/mn/${props.order?.invoice_id}`}
                    target="_blank"
                    onClick={() => {
                      closeButtonRef.current?.click();
                    }}
                  >
                    Social Pay нээх
                  </a>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default QPayModal;
