import { useContext } from "react";
import { FadeLoader } from "react-spinners";
import { SpinnerContext } from "../helper/spinner.context";

function SmokSpinner() {
  const { state } = useContext(SpinnerContext);

  if (state.spinning === false) {
    return <></>;
  }

  return (
    <div className="smok-overlay">
      <FadeLoader className="smok-spinner" color="#36d7b7" />
    </div>
  );
}

export default SmokSpinner;
