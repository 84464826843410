import { useGoogleLogin } from "@react-oauth/google";
import storageService from "../helper/storage";
import { smokToast } from "../helper/toast";
import { authService } from "../module/private/user/service/auth.service";

export interface SocialButtonsProps {
  onComplete: () => void;
}

function SocialButtons(props: SocialButtonsProps) {
  const facebookLogin = (response: any) => {
    login(response.accessToken, "facebook");
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => login(response.access_token, "google-oauth2"),
  });

  const login = (token: string, backend: string) => {
    authService.socialSign(token, backend).then((token) => {
      token.is_guest = false;
      storageService.setToken(token);

      authService.getUser().then((user) => {
        smokToast.success("Амжилттай нэвтэрлээ.");

        if (user.info_id) {
          storageService.setUserInfoId(user.info_id);
        }

        props.onComplete();
        window.location.reload();
      });
    });
  };

  return (
    <div className="d-flex align-items-center">
    </div>
  );
}

export default SocialButtons;
