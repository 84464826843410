import React, { useState } from "react";
import { smokConstants } from "../../../../constant/constants";

export interface SmokPaginationProps {
  current: number;
  count: number;
  pageSize?: number;
  onChange: (page: number) => void;
}

function SmokPagination(props: SmokPaginationProps) {
  const [current, setCurrent] = useState(props.current);

  const getPages = () => {
    let pages = [];

    for (let index = 0; index < getMaxPage(); index++) {
      pages.push(index + 1);
    }

    return pages;
  };

  const getMaxPage = () => {
    return Math.ceil(props.count / (props.pageSize ?? smokConstants.PAGE_SIZE));
  };

  const onChange = (page: number) => {
    if (page < 1) return;
    if (page > getMaxPage()) return;

    setCurrent(page);
    props.onChange(page);
  };

  return (
    <ul className="pagination justify-content-center align-items-center mb-0 fs-16 font-weight-600">
      <li className="page-item fs-18 d-none d-sm-block">
        <a
          className="page-link rounded-circle w-40px h-40 p-0 justify-content-center align-items-center d-flex"
          tabIndex={-1}
          onClick={() => current > 0 && onChange(current - 1)}
        >
          <i className="far fa-angle-double-left" />
        </a>
      </li>
      {getPages().map((page) => (
        <li
          key={page}
          className={`page-item ${page === current ? "active" : ""}`}
        >
          <a
            className="page-link rounded-circle w-40px h-40 p-0 justify-content-center align-items-center d-flex"
            onClick={() => onChange(page)}
          >
            {page}
          </a>
        </li>
      ))}
      <li className="page-item fs-18 d-none d-sm-block">
        <a
          className="page-link rounded-circle w-40px h-40 p-0 justify-content-center align-items-center d-flex"
          onClick={() => current < props.count && onChange(current + 1)}
        >
          <i className="far fa-angle-double-right" />
        </a>
      </li>
    </ul>
  );
}

export default SmokPagination;
