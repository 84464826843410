import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Empty from "../../../../component/Empty";
import { Pagination } from "../../../../helper/shared.model";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions } from "../../../../helper/spinner.reducer";
import ProductItem, { ProductItemType } from "../../home/component/ProductItem";
import SmokPagination from "../component/SmokPagination";
import { Product, ProductQuery } from "../model/product.model";
import { productService } from "../service/product.service";

export const storeCategoryPath = `/store/categories/:id`;
export const storePath = `/store`;
export const getStoreCategoryPath = (id: number) =>
  storeCategoryPath.replaceAll(":id", id.toString());

function Store() {
  const { id } = useParams();
  const location = useLocation();
  const [products, setProducts] = useState<Pagination<Product>>();
  const [query, setQuery] = useState<ProductQuery>({ page: 1 });
  const { dispatch } = useContext(SpinnerContext);


  useEffect(() => {
    if (id) {
      getProducts({
        ...query,
        category_id: Number(id),
      });
    } else {
      getProducts({
        ...query,
      });
    }
  }, [id, location.pathname]);

  const getProducts = (_query: ProductQuery) => {
    setQuery(_query);

    dispatch({
      type: actions.BLOCK,
    });

    productService.getProducts(_query).then((_products) => {
      setProducts(_products);

      dispatch({
        type: actions.UNBLOCK,
      });
    });
  };

  if (products?.results === undefined || products?.results.length == 0)
    return <Empty message="Бүтээгдэхүүн олдсонгүй." />;

  return (
    <>
      <section>
        <div className="container container-xl">
          <h4 className="text-center mt-8 mb-2">Бүтээгдэхүүнүүд</h4>
          <p className="mb-8 fs-18 text-center">
            Ангилал - {products?.results[0].category.name}
          </p>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <p
              className="fs-18 font-weight-500 my-lg-0 my-2"
              style={{ color: "#696969" }}
            >
              Нийт
              <strong className="font-weight-bold text-secondary">
                {` ${products?.count ?? 0} `}
              </strong>
              бүтээгдэхүүн байна.
            </p>
          </div>
        </div>
      </section>

      <section className="mt-7">
        <div className="container container-xl">
          <div className="row">
            {products?.results.map((product) => (
              <ProductItem
                key={product.sku}
                type={ProductItemType.Store}
                product={product}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="pb-lg-14 pb-11">
        <nav className="pt-2">
          {products?.count && products?.count > 0 ? (
            <SmokPagination
              current={query.page ?? 1}
              count={products?.count}
              pageSize={products.page_size}
              onChange={(page) => getProducts({ ...query, page })}
            />
          ) : (
            <></>
          )}
        </nav>
      </section>
    </>
  );
}

export default Store;
