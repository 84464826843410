import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { BehaviorSubject, first } from "rxjs";
import { smokConstants } from "../constant/constants";
import { authService } from "../module/private/user/service/auth.service";
import storageService from "./storage";
import { smokToast } from "./toast";

export const api = axios.create({
  baseURL: smokConstants.API_URL,
});

let isRefreshing = false;

const tokenSubject = new BehaviorSubject<string | null>(null);

const tokenExcludeUrls: string[] = ["/auth/token/login/"];

const addPublicTokenToSubject = () => {
  if (!isRefreshing) {
    isRefreshing = true;
    tokenSubject.next(null);

    authService
      .signInAsGuest()
      .then((_token) => {
        tokenSubject.next(_token.auth_token);
        isRefreshing = false;
      })
      .catch((err) => {
        tokenSubject.next(null);
        isRefreshing = false;
      });
  }
};

const buildRequestConfigWithPublicToken = (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  addPublicTokenToSubject();
  return new Promise((resolve, reject) => {
    readPublicTokenFromSubject().subscribe((token: string | null) => {
      config.headers!["Authorization"] = `Token ${token}`;
      resolve(config);
    });
  });
};

const readPublicTokenFromSubject = () => {
  return tokenSubject.pipe(first((x: string | null) => x != null));
};

const retryRequestWithPublicToken = (error: AxiosError) => {
  addPublicTokenToSubject();
  return new Promise((resolve, reject) => {
    readPublicTokenFromSubject().subscribe((token: string | null) => {
      error.config!.headers = {
        Authorization: `Token ${token}`,
      };

      api
        .request(error.config!)
        .then((_response: any) => resolve(_response))
        .catch((err: any) => {
          reject(err);
        });
    });
  });
};

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = storageService.getToken();

    if (
      token &&
      config.headers != null &&
      !tokenExcludeUrls.includes(config.url!)
    ) {
      config.headers["Authorization"] = `Token ${token?.auth_token}`;
    } else {
      if (tokenExcludeUrls.includes(config.url!)) {
        return config;
      }

      return buildRequestConfigWithPublicToken(config);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response.data;
  },
  (error: AxiosError<any>) => {
    if (error.response?.status === 401) {
      return retryRequestWithPublicToken(error);
    } else if (
      error.response &&
      error.response?.status !== 404 &&
      error.response?.status <= 500
    ) {
      let description = "";
      let _response: any = error.response?.data;

      for (const key in _response) {
        if (Object.hasOwn(_response, key)) {
          const element: string[] = _response[key];

          description += element.join();
        }
      }

      smokToast.error(description);
    } else {
      console.table(error);
      if (error.response?.status && error.response?.status !== 404) {
        smokToast.error("Интернет холболтоо шалгана уу.");
      }
    }

    return Promise.reject(error.response?.data);
  }
);
