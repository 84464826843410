import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useContext, useEffect, useState } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import { Link } from 'react-router-dom';
import {
  getImageUrl,
  getNameWithoutLink,
  getTotalPrice,
} from '../../../../component/CartProductItem';
import CustomCheckBox from '../../../../component/CustomCheckBox';
import { helper } from '../../../../helper/helper';
import { SpinnerContext } from '../../../../helper/spinner.context';
import { actions as spinnerAction } from '../../../../helper/spinner.reducer';
import { smokToast } from '../../../../helper/toast';
import { utils } from '../../../../helper/utils';
import AddCoupon from '../../cart/component/AddCoupon';
import { refreshCart } from '../../cart/screen/Cart';
import { getProductDetailPath } from '../../store/screen/ProductDetail';
import { CartContext } from '../../store/service/cart.context';
import { termsAndConditionPath } from '../../terms-and-condition/screen/TermsAndCondition';
import ConfirmationModal from '../component/ConfirmationModal';
import QPayModal from '../component/QPayModal';
import UserInfoForm from '../component/UserInfoForm';
import { Order, PaymentType, UserInfo } from '../model/order.model';
import { orderService } from '../service/order.service';

export const checkoutPath = '/checkout';

function Checkout() {
  const { state, dispatch } = useContext(CartContext);
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);

  const [payment, setPayment] = useState<PaymentType>(PaymentType.QPAY);

  const [form] = useForm();
  const [paymentForm] = useForm();

  const [modalShow, setModalShow] = useState(false);
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    refreshCart(dispatchSpinner, dispatch);
  }, []);

  const onUserInfoFormFinish = (userInfo: UserInfo) => {
    let order: Order = {
      payment_type: payment,
      user_info_id: userInfo.id,
    };

    if (payment === PaymentType.STORE_PAY) {
      order.store_pay_phone_number = paymentForm.getFieldValue('phoneNumber');
    }

    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    orderService
      .createOrder(order)
      .then((order) => {
        setOrder(order);

        if (order.payment_type === PaymentType.SOCIAL_PAY) {
          if (isIOS || isMacOs) {
            setModalShow(true);
          } else {
            window.open(
              `https://ecommerce.golomtbank.com/socialpay/mn/${order.invoice_id}`,
              '_blank'
            );
          }
        } else if (order.payment_type === PaymentType.CARD_PAY) {
          document.location.href = `https://ecommerce.golomtbank.com/payment/mn/${order.invoice_id}`;
        } else if (order.payment_type === PaymentType.QPAY) {
          setModalShow(true);
        }
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });

        refreshCart(dispatchSpinner, dispatch);
      });
  };

  const checkLoanCredit = (phoneNumber: string) => {
    if (payment === PaymentType.STORE_PAY) {
      dispatchSpinner({
        type: spinnerAction.BLOCK,
      });

      orderService
        .getLoanCredit(phoneNumber)
        .then((loanCredit) => {
          console.log(loanCredit);
          if (loanCredit.status === 'Success') {
            if (Number(loanCredit.value) > 0) {
              form.submit();
            } else {
              smokToast.error('Таны зээлийн эрхийн хэмжээ 0₮ байна.');
            }
          } else {
            smokToast.error(
              loanCredit.msgList.map((msg) => msg.code).join('. ')
            );
          }
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    } else {
      form.submit();
    }
  };

  return (
    <section className="pb-lg-13 pb-11">
      <ConfirmationModal
        order={order}
        onOrderChange={(order) => {
          if (order.status && utils.getStep(order.status) > 1) {
            setModalShow(false);
          }
        }}
      />
      <div className="container">
        <h2 className="text-center my-9">Захиалга баталгаажуулах</h2>
        <div className="row">
          <div className="col-lg-4 pb-lg-0 pb-11 order-lg-last">
            <div
              className="card border-0"
              style={{ boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)' }}
            >
              <div className="card-header px-0 mx-6 bg-transparent py-5">
                <h4 className="fs-24 mb-5">Захиалгын дэлгэрэнгүй</h4>
                {state.cartProducts?.map((_cartProduct) => (
                  <div
                    key={_cartProduct.id}
                    className={`media w-100 mb-4 ${
                      _cartProduct.bundled_product_id !== null
                        ? 'opacity-5'
                        : ''
                    }`}
                  >
                    <div className="w-60px mr-3">
                      <img
                        src={getImageUrl(_cartProduct)}
                        alt={_cartProduct.product.name}
                      />
                    </div>
                    <div
                      className="media-body d-flex"
                      style={{
                        width: 'calc(100% - 100px)',
                      }}
                    >
                      <div className="cart-price">
                        <Link
                          to={getProductDetailPath(_cartProduct.product.sku)}
                          className="text-secondary pr-6"
                        >
                          {getNameWithoutLink(_cartProduct)}
                          <span className="text-body ml-1">
                            x{_cartProduct.count}
                          </span>
                        </Link>
                        <p className="fs-14 text-secondary mb-0 mt-1">
                          Төрөл:
                          <span className="text-body ml-1">
                            {_cartProduct.product.category.name}
                          </span>
                        </p>
                      </div>
                      <div className="ml-auto">
                        {_cartProduct.bundled_product_id === null ? (
                          <p className="fs-14 text-secondary mb-0 font-weight-bold">
                            {utils.formatCurrency(getTotalPrice(_cartProduct))}
                          </p>
                        ) : (
                          <p style={{ width: '100px' }}></p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="card-body px-6 pt-5">
                <div className="d-flex align-items-center mb-2">
                  <span>Нийт дүн:</span>
                  <span className="d-block ml-auto text-secondary font-weight-bold">
                    {utils.formatCurrency(state.summary?.summary)}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span>Хүргэлт:</span>
                  <span className="d-block ml-auto text-secondary font-weight-bold">
                    {utils.formatCurrency(
                      state.cartProducts && state.cartProducts?.length > 0
                        ? state.summary?.delivery
                        : 0
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span>Хямдрал:</span>
                  <span className="d-block ml-auto text-danger font-weight-bold">
                    - {utils.formatCurrency(state.summary?.sale)}
                  </span>
                </div>
              </div>
              <div className="card-footer bg-transparent px-0 pb-1 mx-6">
                <div className="d-flex align-items-center font-weight-bold mb-3">
                  <span className="text-secondary">Төлвөл зохих:</span>
                  <span className="d-block ml-auto text-secondary fs-24 font-weight-bold">
                    {utils.formatCurrency(
                      state.cartProducts && state.cartProducts?.length > 0
                        ? state.summary?.total
                        : 0
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 pr-xl-15 order-lg-first form-control-01">
            <div className="card-body mb-5 px-6 border">
              <p className="card-text text-secondary">
                Купонтой бол доорх талбарт оруулна уу.
              </p>
              <div className="input-group">
                <AddCoupon />
              </div>
            </div>
            {!helper.isAuthenticated() && (
              <p className="mb-2">
                Бүртгэлтэй хэрэглэгч эсэх?{' '}
                <a
                  className="text-muted"
                  data-toggle="modal"
                  data-target="#sign-in"
                >
                  Энд дарж нэвтэрнэ үү.
                </a>
              </p>
            )}
            <h4 className="fs-24 pt-1 mb-4">Хүргэлтийн мэдээлэл</h4>
            <UserInfoForm onFinish={onUserInfoFormFinish} form={form} />
            <h4 className="fs-24 mb-5 pt-lg-1 payment">Төлбөрийн мэдээлэл</h4>
            <div className="row">
              <div className="col-md-4">
                <a
                  className={`btn bg-gray-2 btn-payment payment-paylay py-3 mb-5 w-100 ${
                    payment === PaymentType.QPAY ? 'active' : ''
                  }`}
                  data-var="q-pay"
                  onClick={() => setPayment(PaymentType.QPAY)}
                >
                  <svg
                    className="bi me-2"
                    width="60"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 186.7 73.6"
                  >
                    <path d="M111.4 13.5c3.1 2.8 4.7 7 4.7 12.4s-1.6 9.5-4.7 12.4-7.3 4.3-12.4 4.3h-5.9V58c-1.8.3-3.6.5-5.5.4-1.8 0-3.6-.1-5.4-.4V10l.5-.5c7.9-.2 13.3-.3 16.2-.3 5.2.1 9.4 1.5 12.5 4.3zm-18.3 4v17c1.4 0 3-.1 4.9-.1 1.6.1 3.2-.4 4.5-1.5 1-.8 1.7-1.9 2.1-3.1.3-1.5.5-2.9.5-4.4 0-1.9-.5-3.7-1.5-5.2-1.1-1.7-3-2.7-5-2.5-2.4-.1-4.2-.1-5.5-.2zM150.9 53.2c-.3 1.1-.7 2.1-1.3 3-.5.9-1.2 1.7-2 2.4-2.9 0-5.7-1.4-7.4-3.8-2.6 2.7-6.2 4.2-10 4.2-3.7 0-6.4-1.1-8.3-3.3-1.8-2-2.8-4.7-2.8-7.4 0-4 1.3-7 4-9.1 2.9-2.2 6.4-3.3 10-3.1 1.6 0 3.3.1 5.3.2v-1.5c0-3.1-1.6-4.7-4.8-4.7-2 0-5.5.8-10.6 2.3-1.4-1.6-2.3-4.1-2.6-7.4 2.5-1 5.2-1.8 7.9-2.3 2.6-.5 5.3-.8 8-.8 3.2-.1 6.4.9 8.9 2.9 2.4 2 3.6 5 3.6 9.1v14.4c.1 2.4.7 3.9 2.1 4.9zm-18.1-1.9c2.1-.1 4-.9 5.5-2.3v-5.9c-1 0-2.4-.1-4.2-.1-3.2 0-4.7 1.6-4.7 4.7 0 .9.4 1.8 1 2.5s1.5 1.1 2.4 1.1zM186.7 23.9l-8.9 33.2c-1.5 5.5-3.4 9.7-5.9 12.4-2.5 2.7-6.2 4.1-11.2 4.1-2.8 0-5.7-.5-8.4-1.4v-1.3c.1-1 .2-2 .5-3 .3-1.2.8-2.3 1.6-3.2 2 .6 4.1 1 6.2 1.1 3.5 0 5.9-2.1 7.1-6.2l.3-1c-4-.1-6.3-1.6-7.1-4.4l-8.4-30.6c1.8-.9 3.8-1.4 5.8-1.4 1.4-.1 2.8.2 3.9 1 1 1 1.6 2.2 1.8 3.5l3.5 14c.4 1.4.9 4.5 1.7 9.4 0 .3.3.5.6.5l6.4-27.8c1.5-.3 3.1-.5 4.6-.4 1.8 0 3.5.2 5.2.8l.7.7zM52.6 52.2l3.8 3.8c1.5-1.5 2.8-3.1 3.9-4.8l-4.4-2.9c-1 1.4-2.1 2.7-3.3 3.9z"></path>
                    <path d="M65.8 32.9v-.2c0-2.1-.2-4.1-.7-6.2V26C61.9 11 48.7.2 33.3 0h-.4C14.7 0 0 14.7 0 32.9s14.7 32.9 32.9 32.9c3.3 0 6.5-.5 9.6-1.4l1.1-.3 2.1-.8 2.6 1 8.5 3.3c1.4.6 1.8 0 .9-1.2L44.1 48c-1-1.3-2.7-1.7-4.1-1-2.2 1.1-4.6 1.7-7 1.8-8.8 0-16-7.1-16.1-15.9s7.1-16 15.9-16.1c7.6 0 14.2 5.3 15.7 12.7v.5c.2.9.2 1.8.3 2.7v.3c0 1-.1 1.9-.3 2.9l5.4 1c.2-1.3.4-2.6.4-3.9v-.2h6.2v.2c0 1.7-.1 3.4-.4 5.1v.2h-.2l-5.6-1.1c-.3 1.3-.7 2.6-1.2 3.9l-.1.2-.2-.1-5-2.1c-.4.9-.8 1.7-1.4 2.5l-.1.2c-.5.8-1.2 1.5-1.8 2.2l3.9 3.8c.9-.9 1.8-2 2.5-3.1l.1-.2.2.1 4.8 3.2c.9-1.4 1.7-2.9 2.4-4.5l.1-.2.2.1 4.9 2c.8-1.9 1.4-3.9 1.8-5.9v-.2c.2-2.1.4-4.1.4-6.2z"></path>
                  </svg>
                </a>
              </div>
              <div className="col-md-4">
                <a
                  className={`btn bg-gray-2 btn-payment payment-paylay py-3 mb-5 w-100 ${
                    payment === PaymentType.CARD_PAY ? 'active' : ''
                  }`}
                  data-var="card-pay"
                  onClick={() => setPayment(PaymentType.CARD_PAY)}
                >
                  <img
                    src="/glowing/images/payment-cards-logo.png"
                    style={{
                      width: '130px',
                    }}
                  />
                </a>
              </div>
              <div className="col-md-4">
                <a
                  className={`btn bg-gray-2 btn-payment payment-paylay py-3 mb-5 w-100 ${
                    payment === PaymentType.STORE_PAY ? 'active' : ''
                  }`}
                  data-var="card-pay"
                  onClick={() => setPayment(PaymentType.STORE_PAY)}
                >
                  <img
                    src="/glowing/images/store-pay-logo.png"
                    style={{
                      width: '130px',
                    }}
                  />
                </a>
              </div>
            </div>
            <Form
              form={paymentForm}
              onFinish={(value: { phoneNumber: string }) => {
                checkLoanCredit(value.phoneNumber);
              }}
            >
              {payment === PaymentType.STORE_PAY && (
                <div className="mb-5">
                  <label className="mb-2 fs-13 letter-spacing-01 font-weight-600 text-uppercase">
                    Storepay - д бүртгэлтэй утасны дугаар{' '}
                    <span className="text-danger">*</span>
                  </label>

                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: 'Утасны дугаарыг оруулна уу.',
                      },
                      {
                        pattern: /\d{8}/,
                        message: 'Утасны дугаарыг 8 оронтой байх ёстой.',
                      },
                    ]}
                  >
                    <input
                      type="tel"
                      maxLength={8}
                      className="form-control border-0"
                      placeholder="Утасны дугаар"
                    />
                  </Form.Item>
                </div>
              )}
              <p className="fs-14 mb-5 text-secondary">
                Өглөөний <span className="font-weight-bold">10:00</span> цагаас
                өмнө баталгаажсан захиалгыг тухайн өдрийн{' '}
                <span className="font-weight-bold">18:00 - 00:00</span> цагийн
                хооронд, өглөөний{' '}
                <span className="font-weight-bold">10:00</span> цагаас хойших
                захиалгыг маргааш өдрийн{' '}
                <span className="font-weight-bold">10:00 - 00:00</span> цагийн
                хооронд тус тус хүргэнэ.
              </p>
              <p className="fs-14 mb-5 text-primary font-weight-bold">
              Амралтын өдөр хүргэлт хийгдэхгүй бөгөөд амралтын өдөр хийгдсэн захиалгыг даваа гарагийн 20:00 цагаас хойш хүргэж эхэлнэ.
              </p>
              <p className="fs-14 text-primary font-weight-bold">
                Зөвхөн хотын А бүсэд хүргэлт хийх бөгөөд хөдөө орон нутаг руу
                унаанд явуулах боломжгүй.
              </p>
              <div className="mb-6">
                <Form.Item
                  name="termsAndCondition"
                  rules={[
                    () => ({
                      validator(_, value: boolean) {
                        if (value !== true) {
                          return Promise.reject(
                            'Үйлчилгээний нөхцлийг зөвшөөрнө үү.'
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <CustomCheckBox>
                    <div>
                      <Link
                        className="text-decoration-underline"
                        to={termsAndConditionPath}
                      >
                        Үйлчилгээний нөхцөлийг
                      </Link>{' '}
                      зөвшөөрч байна.
                    </div>
                  </CustomCheckBox>
                </Form.Item>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <button
                    disabled={state.cartProducts?.length == 0}
                    type="submit"
                    className="btn btn-secondary bg-hover-primary border-hover-primary px-7 w-100"
                  >
                    Төлбөр төлөх
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <QPayModal
        show={modalShow}
        order={order}
        paymentType={order?.payment_type}
      />
    </section>
  );
}

export default Checkout;
