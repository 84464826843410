import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions } from "../../../../helper/spinner.reducer";
import { utils } from "../../../../helper/utils";
import Testimonies from "../../home/component/Testimonies";
import { Page } from "../../settings/model/settings.model";
import { settingsService } from "../../settings/service/settings.service";

export const aboutUsPath = `/about-us`;

function AboutUs() {
  const [pages, setPages] = useState<Page[]>([]);
  const { dispatch } = useContext(SpinnerContext);

  useEffect(() => {
    dispatch({
      type: actions.BLOCK,
    });

    settingsService
      .getPages({
        type__in: ["About-Us-1", "About-Us-2", "About-Us-3"].join(","),
      })
      .then((_pages) => {
        setPages(_pages);
      })
      .finally(() => {
        dispatch({
          type: actions.UNBLOCK,
        });
      });
  }, []);

  return (
    <main id="content">
      <div>
        <div
          style={{
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))`,
            height: "40%",
            width: "100%",
            position: "absolute",
          }}
        ></div>
        <img src="/glowing/images/about-us.png" className="w-100" />
      </div>
      <section className="pb-9 pt-7 pt-lg-12 pb-lg-15">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card bg-transparent border-0 text-center">
                <div
                  className="w-63px card-img mb-6 mb-md-10"
                  data-animate="fadeInUp"
                >
                  <img
                    src="/glowing/images/smok-about-logo.png"
                    style={{
                      width: "300px",
                    }}
                    alt=""
                  />
                </div>
                <div className="card-body px-0 pb-10 pb-lg-12 pt-1">
                  <h3
                    className="card-title fs-34 lh-129 mb-5 mx-auto"
                    style={{ maxWidth: "610px" }}
                    data-animate="fadeInUp"
                  >
                    Монголд албан ёсны эрхтэйгээр борлуулагдаж эхэллээ.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-9 mb-lg-13">
            <div className="col-12 col-lg-4 pr-lg-0">
              <div
                className="hover-shine hover-zoom-in"
                data-animate="fadeInUp"
              >
                <img
                  className="w-100"
                  src="/glowing/images/smok-about-us-2.png"
                />
              </div>
            </div>
            <div className="col-12 col-lg-8 pl-lg-13 mt-lg-0 mt-8">
              <h3 className="mr-lg-10 mb-5" data-animate="fadeInUp">
                Тэргүүлэгч брэнд
              </h3>
              <p
                className="mr-lg-10 fs-16 font-weight-400"
                data-animate="fadeInUp"
                dangerouslySetInnerHTML={{
                  __html: utils.getHtml(pages, "About-Us-1"),
                }}
              ></p>
            </div>
          </div>
          <div className="row align-items-center mb-9 mb-lg-13">
            <div className="col-12 col-lg-8 pl-lg-13 mt-lg-0 mt-8 order-lg-1 order-2">
              <h3 className="mr-lg-10 mb-5" data-animate="fadeInUp">
                Хэрэглэгчид өгөх үнэ цэнэ
              </h3>
              <p
                className="mr-lg-10 fs-16 font-weight-400"
                data-animate="fadeInUp"
                dangerouslySetInnerHTML={{
                  __html: utils.getHtml(pages, "About-Us-2"),
                }}
              ></p>
            </div>
            <div className="col-12 col-lg-4 pl-lg-0 order-lg-2 order-1">
              <div
                className="hover-shine hover-zoom-in"
                data-animate="fadeInUp"
              >
                <img
                  className="w-100"
                  src="/glowing/images/smok-about-us-3.png"
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-12 col-lg-4 pr-lg-0 text-center">
              <img
                style={{
                  height: "350px",
                }}
                src="/glowing/images/smok-about-us-4.png"
              />
            </div>
            <div className="col-12 col-lg-8 pl-lg-13 mt-lg-0 mt-8">
              <h3 className="mr-lg-10 mb-5" data-animate="fadeInUp">
                Чанарын хатуу бодлого
              </h3>
              <p
                className="mr-lg-10 fs-16 font-weight-400"
                data-animate="fadeInUp"
                dangerouslySetInnerHTML={{
                  __html: utils.getHtml(pages, "About-Us-3"),
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <h4
          className="mx-auto text-center mw-750 mb-10 fs-16"
          dangerouslySetInnerHTML={{
            __html: utils.getHtml(pages, "About-Us-4"),
          }}
        ></h4>
      </div>
    </main>
  );
}

export default AboutUs;
