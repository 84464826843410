import { useRef } from "react";
import { Link } from "react-router-dom";
import { helper } from "../helper/helper";
import { MenuItem } from "../helper/shared.model";
import { authService } from "../module/private/user/service/auth.service";
import SocialNetworkLinks from "./SocialNetworkLinks";

export interface CanvasMenuProps {
  menus: MenuItem[];
}

function CanvasMenu(props: CanvasMenuProps) {
  let closeButtonRef = useRef<HTMLButtonElement>(null);

  const close = () => {
    if (closeButtonRef.current !== null) {
      closeButtonRef.current.click();
    }
  };

  function getMenus() {
    return props.menus
      .filter(
        (menu) =>
          !menu.authenticated || menu.authenticated === helper.isAuthenticated()
      )
      .map((item, index) => {
        if (item.children.length > 0) {
          return (
            <li
              key={index}
              aria-haspopup="true"
              aria-expanded="false"
              className="nav-item dropdown py-1 px-0 my-2"
            >
              {item.path ? (
                <Link
                  className="nav-link dropdown-toggle p-0"
                  to={item.path}
                  data-toggle="dropdown"
                >
                  {item.title}
                  <span className="caret" />
                </Link>
              ) : (
                <a>{item.title}</a>
              )}
              <ul className="dropdown-menu py-3 pb-0 pb-xl-3 x-animated x-fadeInUp">
                {item.children.map((child) => {
                  return (
                    <li key={child.path} className="dropdown-item my-2">
                      {child.path ? (
                        <Link
                          className="dropdown-link"
                          to={child.path}
                          onClick={close}
                        >
                          {child.title}
                        </Link>
                      ) : (
                        <a>{child.title}</a>
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        } else {
          return (
            <li key={item.path} className="nav-item my-2">
              <Link
                className="nav-link py-1 px-0"
                to={item.path!}
                onClick={close}
              >
                {item.title}
              </Link>
            </li>
          );
        }
      });
  }

  return (
    <div className="sidenav canvas-sidebar bg-white">
      <div className="canvas-overlay"></div>
      <div className="pt-5 pb-7 card border-0 h-100">
        <div className="d-flex align-items-center card-header border-0 py-0 pl-8 pr-7 mb-9 bg-transparent">
          <Link to="/" className="d-block w-179px">
            <img src="/glowing/images/smok-logo-black-2.png" alt="SMOK" />
          </Link>
          <span
            className="canvas-close d-inline-block text-right fs-24 ml-auto lh-1 text-primary"
            ref={closeButtonRef}
          >
            <i className="fal fa-times" />
          </span>
        </div>
        <div className="overflow-y-auto pb-6 pl-8 pr-7 card-body pt-0">
          <ul className="navbar-nav main-menu px-0 ">
            {getMenus()}
            {helper.isAuthenticated() && (
              <li className="nav-item">
                <a
                  className="nav-link py-1 px-0 my-2"
                  onClick={() => {
                    close();
                    authService.logout();
                    window.location.reload();
                  }}
                >
                  Гарах
                </a>
              </li>
            )}
            {!helper.isAuthenticated() && (
              <li className="nav-item">
                <a
                  className="nav-link py-1 px-0 my-2"
                  data-toggle="modal"
                  data-target="#sign-in"
                  onClick={close}
                >
                  Нэвтрэх/Бүртгүүлэх
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="card-footer bg-transparent border-0 mt-auto pl-8 pr-7 pb-0 pt-4">
          <ul className="list-inline d-flex align-items-center mb-3">
            <SocialNetworkLinks />
          </ul>
          <p className="mb-0 text-gray">
            © 2023 Tanco International LLC.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CanvasMenu;
