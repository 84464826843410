
export interface StoreSummaryProps {
  count: number;
}

function StoreSummary(props: StoreSummaryProps) {
  return (
    <div className="bg-secondary p-4 text-light">
      Нийт {props.count} дэлгүүр байна.
    </div>
  );
}

export default StoreSummary;
