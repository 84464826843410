import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { smokConstants } from "../../../../constant/constants";
import { SpinnerContext } from "../../../../helper/spinner.context";
import { actions as spinnerAction } from "../../../../helper/spinner.reducer";
import { utils } from "../../../../helper/utils";
import { refreshCart } from "../../cart/screen/Cart";
import { CartProduct } from "../../store/model/cart.model";
import {
  Product,
  ProductReadUI,
  ProductType,
} from "../../store/model/product.model";
import { getProductDetailPath } from "../../store/screen/ProductDetail";
import { CartContext } from "../../store/service/cart.context";
import { cartService } from "../../store/service/cart.service";
import { productService } from "../../store/service/product.service";
import ProductItem, { ProductItemType } from "./ProductItem";

function SpecialOffer() {
  let navigate = useNavigate();
  const [products, setProducts] = useState<ProductReadUI[]>([]);
  const { dispatch } = useContext(CartContext);
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);

  useEffect(() => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    productService
      .getProducts({
        is_special_offer: true,
      })
      .then((_products) => {
        setProducts(_products.results);
      })
      .finally(() => {
        dispatchSpinner({
          type: spinnerAction.UNBLOCK,
        });
      });
  }, []);

  const slickSlideOption = {
    slidesToShow: 1,
    dots: true,
    arrows: true,
    dotsClass: "slick-dots dots-light",
  };

  const addCartProduct = (product: Product) => {
    if (product.type === ProductType.Regular) {
      let cartProduct: CartProduct = {
        product_id: product.sku,
        count: 1,
        type: ProductType.Regular,
      };

      dispatchSpinner({
        type: spinnerAction.BLOCK,
      });

      cartService
        .addCartProduct(cartProduct)
        .then((x) => {
          refreshCart(dispatchSpinner, dispatch);
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    } else {
      navigate(getProductDetailPath(product.sku));
    }
  };

  if (products.length === 0) {
    return <></>;
  }

  return (
    <section style={{ backgroundColor: "#181c20" }}>
      <div className="container container-xl px-xl-14 py-6">
        {products.length > 0 && (
          <div
            className="slick-slider custom-arrows-04 arrow-0 arrow-lg dot-lg-0"
            data-slick-options={JSON.stringify(slickSlideOption)}
          >
            {products.map((product, index) => (
              <div className="box" key={product.sku}>
                <div className="row align-items-center">
                  <div className="col-sm-7 col-lg-4">
                    <p className="text-light fs-15 mb-3 text-uppercase font-weight-600 brand-gradient">
                      SPECIAL OFFER
                    </p>
                    <h4 className="mb-8 fs-18 custom-font-02 font-weight-400 text-light text-uppercase">
                      {product.short_description}
                    </h4>
                    <p
                      className="fs-14 mb-8 text-light font-weight-light lh-21"
                      dangerouslySetInnerHTML={{
                        __html: product?.description,
                      }}
                    ></p>
                    <a
                      className="btn btn-light bg-white bg-hover-secondary hover-white shadow-1 rounded border-0"
                      onClick={() => addCartProduct(product)}
                    >
                      {product.type === ProductType.Regular
                        ? "Сагслах"
                        : "Төрөл сонгох"}
                    </a>
                  </div>
                  <div className="mt-6 mt-sm-0 col-sm-5 col-lg-3 px-xl-6">
                    <ProductItem
                      product={product}
                      type={ProductItemType.SpecialOffer}
                    />
                  </div>
                  <div className="col-5 pl-xl-11 d-none d-lg-block">
                    <img
                      style={{ height: "550px" }}
                      src={smokConstants.getAbsoluteUrl(
                        product.special_offer_image2
                      )}
                      alt={product.name}
                      onLoad={() => utils.initSlickSlider()}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default SpecialOffer;
