import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import storageService from "../../../../helper/storage";
import {
  Activation,
  NewPassword,
  Resend,
  ResetPassword,
  SmokCredential,
  SmokRegistration,
  SmokToken,
  SmokUser,
} from "../model/auth.model";

const TOKEN_URL = "/token/";
const USER_URL = "/users/";

async function signIn(credential: SmokCredential): Promise<SmokToken> {
  let token: SmokToken = await api.post(
    smokConstants.getAuthUrl(`${TOKEN_URL}login/`),
    credential
  );

  token.is_guest = credential.email === "guest@smok.mn";

  storageService.setToken(token);

  return token;
}

async function signUp(credential: SmokRegistration) {
  let userInfoId = storageService.getUserInfoId();

  if (userInfoId) {
    credential.info_id = userInfoId;
  }

  credential.username = credential.email;
  credential.re_password = credential.password;
  return await api.post(smokConstants.getAuthUrl(`${USER_URL}`), credential);
}

function signInAsGuest() {
  let guestCredentials: SmokCredential = {
    email: "guest@smok.mn",
    password: "Smok2022",
  };

  return authService.signIn(guestCredentials);
}

function logout() {
  storageService.clearAuth();
  storageService.clearUserInfoId();
}

function getUser(): Promise<SmokUser> {
  return api.get(`${smokConstants.getAuthUrl(USER_URL)}me/`);
}

function activate(userActivation: Activation): Promise<Activation> {
  return api.post(
    `${smokConstants.getAuthUrl(USER_URL)}activation/`,
    userActivation
  );
}

function resendActivation(resend: Resend): Promise<Resend> {
  return api.post(
    `${smokConstants.getAuthUrl(USER_URL)}resend_activation/`,
    resend
  );
}

function resetPassword(resend: Resend): Promise<Resend> {
  return api.post(
    `${smokConstants.getAuthUrl(USER_URL)}reset_password/`,
    resend
  );
}

function resetPasswordConfirm(
  resetPassword: ResetPassword
): Promise<ResetPassword> {
  return api.post(
    `${smokConstants.getAuthUrl(USER_URL)}reset_password_confirm/`,
    resetPassword
  );
}

function setNewPassword(newPassword: NewPassword): Promise<NewPassword> {
  return api.post(
    `${smokConstants.getAuthUrl(USER_URL)}set_password/`,
    newPassword
  );
}

function socialSign(token: string, backend: string): Promise<SmokToken> {
  return api.post(
    smokConstants.getAuthUrl(`/register-by-access-token/social/${backend}/`),
    {
      access_token: token,
    }
  );
}

export const authService = {
  signIn,
  signUp,
  logout,
  signInAsGuest,
  getUser,
  activate,
  resendActivation,
  resetPasswordConfirm,
  resetPassword,
  setNewPassword,
  socialSign,
};
