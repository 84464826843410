import { smokConstants } from "../../../../constant/constants";
import { api } from "../../../../helper/api";
import { Pagination } from "../../../../helper/shared.model";
import {
  BundledProductReadUI,
  Category,
  ProductQuery,
  ProductReadUI,
  ProductType,
  ProductVariationQuery,
  ProductVariationReadUI,
  ProductVariationWriteUI,
  ProductWriteUI,
} from "../model/product.model";
import { VariationReadUI } from "../model/variation.model";

const PRODUCT_URL = "/products/";
const PRODUCT_VARIATIONS_URL = "/product-variations/";
const BULK_PRODUCT_VARIATIONS_URL = PRODUCT_VARIATIONS_URL + "bulk-create/";
const CATEGORY_URL = "/categories/";
const BUNDLED_PRODUCT_URL = "/bundled-products/";

async function getProducts(
  params?: ProductQuery
): Promise<Pagination<ProductReadUI>> {
  if (params && params.types) params.type__in = params?.types?.join(",");

  let products: Pagination<ProductReadUI> = await api.get(
    smokConstants.getProductUrl(PRODUCT_URL),
    { params }
  );

  products.results.forEach((product, index) => {
    product.index = index + 1;
    product.key = product.sku;
  });

  return products;
}

function convertWriteUI(product: ProductWriteUI) {
  if (product.sale_dates) {
    product.sale_start_date = product.sale_dates[0]!.toDate();
    product.sale_end_date = product.sale_dates[1]!.toDate();
  } else {
    product.sale_start_date = null;
    product.sale_end_date = null;
  }
}

function addProduct(product: ProductWriteUI): Promise<ProductReadUI> {
  convertWriteUI(product);
  return api.post(smokConstants.getProductUrl(PRODUCT_URL), product);
}

function editProduct(product: ProductWriteUI): Promise<ProductReadUI> {
  convertWriteUI(product);

  return api.put(
    smokConstants.getProductUrl(`${PRODUCT_URL}${product.sku}/`),
    product
  );
}

function deleteProduct(sku: string): Promise<ProductReadUI> {
  return api.delete(`${smokConstants.getProductUrl(PRODUCT_URL)}${sku}/`);
}

async function getProduct(sku: string): Promise<ProductReadUI> {
  let product: ProductReadUI = await api.get(
    smokConstants.getProductUrl(`${PRODUCT_URL}${sku}/`)
  );

  if (product?.type === ProductType.Variation) {
    product.product_variations = await productService.getProductVariations({
      product_id: sku,
    });
  }

  return product;
}

function getCategories(): Promise<Category[]> {
  return api.get(smokConstants.getProductUrl(CATEGORY_URL));
}

async function getProductVariations(
  params: ProductVariationQuery
): Promise<ProductVariationReadUI[]> {
  let productVariations: ProductVariationReadUI[] = await api.get(
    smokConstants.getProductUrl(PRODUCT_VARIATIONS_URL),
    {
      params,
    }
  );

  productVariations.forEach((pv) => {
    pv._id = pv.id!.toString();
    pv._label = pv.variation.name;
    pv._description = `Үлдэгдэл: ${pv.stock.toString()}`;
    pv._image = pv.image ?? "";
  });
  return productVariations;
}

function addProductVariations(
  sku: string,
  variations: VariationReadUI[]
): Promise<ProductVariationReadUI[]> {
  let productVariations: ProductVariationWriteUI[] = [];

  variations.forEach((variation) => {
    productVariations.push({
      product_id: sku,
      variation_id: variation.id,
      is_primary: false,
      is_private: false,
      is_published: false,
      stock: 0,
    });
  });

  return api.post(
    smokConstants.getProductUrl(BULK_PRODUCT_VARIATIONS_URL),
    productVariations
  );
}

function editProductVariation(
  productVariation: ProductVariationWriteUI
): Promise<ProductVariationReadUI[]> {
  return api.put(
    `${smokConstants.getProductUrl(PRODUCT_VARIATIONS_URL)}${
      productVariation.id
    }/`,
    productVariation
  );
}

function deleteProductVariation(id: number): Promise<ProductVariationWriteUI> {
  return api.delete(
    `${smokConstants.getProductUrl(PRODUCT_VARIATIONS_URL)}${id}/`
  );
}

async function getBundledProducts(
  sku: string
): Promise<BundledProductReadUI[]> {
  let bundledProducts: BundledProductReadUI[] = await api.get(
    `${smokConstants.getProductUrl(BUNDLED_PRODUCT_URL)}`,
    {
      params: {
        product_id: sku,
      },
    }
  );

  for (const element of bundledProducts) {
    if (element.ref_product?.type === ProductType.Variation) {
      element.ref_product.product_variations =
        await productService.getProductVariations({
          product_id: element.ref_product_id,
        });
    }
  }

  return bundledProducts;
}

export const productService = {
  getProducts,
  addProduct,
  getProduct,
  getCategories,
  editProduct,
  deleteProduct,
  getProductVariations,
  addProductVariations,
  editProductVariation,
  deleteProductVariation,
  getBundledProducts,
};
