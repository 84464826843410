import { useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SpinnerContext } from "../helper/spinner.context";
import { actions as spinnerAction } from "../helper/spinner.reducer";
import { utils } from "../helper/utils";
import { cartPath, refreshCart } from "../module/private/cart/screen/Cart";
import { checkoutPath } from "../module/private/order/screen/Checkout";
import { CartProduct } from "../module/private/store/model/cart.model";
import { CartContext } from "../module/private/store/service/cart.context";
import { cartService } from "../module/private/store/service/cart.service";
import CartProductItem from "./CartProductItem";
import Empty, { EmptySize } from "./Empty";

function CartCanvas() {
  let closeButtonRef = useRef<HTMLButtonElement>(null);
  const { dispatch: dispatchSpinner } = useContext(SpinnerContext);
  const { state, dispatch } = useContext(CartContext);
  let navigate = useNavigate();

  const close = () => {
    if (closeButtonRef.current !== null) {
      closeButtonRef.current.click();
    }
  };

  const checkout = () => {
    navigate(checkoutPath);
    close();
  };

  const deleteCoupon = (cartProduct?: CartProduct) => {
    dispatchSpinner({
      type: spinnerAction.BLOCK,
    });

    if (cartProduct) {
      cartService
        .deleteCartProduct(cartProduct)
        .then((res) => {
          refreshCart(dispatchSpinner, dispatch);
        })
        .finally(() => {
          dispatchSpinner({
            type: spinnerAction.UNBLOCK,
          });
        });
    }
  };

  return (
    <div className="canvas-sidebar cart-canvas">
      <button
        className="canvas-close"
        ref={closeButtonRef}
        style={{ display: "none" }}
      ></button>
      <div className="card border-0 pt-4 pb-7 h-100">
        <div className="px-6 text-right">
          <span className="canvas-close d-inline-block fs-24 mb-1 ml-auto lh-1 text-primary">
            <i className="fal fa-times" />
          </span>
        </div>
        <div className="card-header bg-transparent p-0 mx-6">
          <h3 className="fs-24 mb-5">Сагс</h3>
          {state.cartCoupon && (
            <p className="fs-15 font-weight-500 text-body mb-5">
              <a
                className="mr-5"
                onClick={() => deleteCoupon(state.cartCoupon)}
              >
                <span className="d-inline-block text-muted mr-2">
                  <i className="fal fa-times"></i>
                </span>
              </a>
              Купон код:
              <span className="text-secondary ml-2">
                {state.cartCoupon?.coupon?.id} (
                {utils.formatCurrency(state.summary?.sale)} хямдрал)
              </span>
            </p>
          )}
        </div>
        {state.cartProducts === undefined || state.cartProducts?.length > 0 ? (
          <div className="card-body px-6 pt-7 overflow-y-auto">
            {state.cartProducts?.map((_cartProduct) => (
              <CartProductItem
                key={_cartProduct.id?.toString()}
                cartProduct={_cartProduct}
                cartProducts={state.cartProducts}
              />
            ))}
          </div>
        ) : (
          <Empty message="Сагс хоосон байна." size={EmptySize.Small} />
        )}
        <div className="card-footer mt-auto border-0 bg-transparent px-6 pb-0 pt-5">
          <div className="d-flex align-items-center mb-2">
            <span className="text-secondary fs-15">Нийт дүн:</span>
            <span className="d-block ml-auto fs-24 font-weight-bold text-secondary">
              {utils.formatCurrency(state?.summary?.summary)}
            </span>
          </div>
          <Link
            to={cartPath}
            className="btn btn-outline-secondary btn-block"
            onClick={close}
          >
            Дэлгэрэнгүй
          </Link>
          <button
            onClick={checkout}
            disabled={Number(state.summary?.summary) == 0.0}
            className="btn btn-secondary btn-block mb-3 bg-hover-primary border-hover-primary"
          >
            Баталгаажуулах
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartCanvas;
