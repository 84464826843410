import React, { useEffect, useRef, useState } from "react";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

export enum Tab {
  SignIn = 'signin',
  SignUp = 'signup',
  ResetPassword = 'resetpassword',
}

export interface AuthWrapperProps {
  tab?: Tab;
}

function AuthWrapper(props: AuthWrapperProps) {
  const [tab, setTab] = useState<Tab>(Tab.SignIn);
  let closeButtonRef = useRef<HTMLButtonElement>(null);
  let openLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (props.tab !== undefined) {
      setTab(props.tab);
      openLinkRef.current?.click();
    }
  }, [props.tab]);

  const close = () => {
    if (closeButtonRef.current !== null) {
      closeButtonRef.current.click();
    }
  };

  return (
    <>
      <a ref={openLinkRef} data-toggle="modal" data-target="#sign-in" />
      <div
        className="modal sign-in"
        id="sign-in"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 p-6">
              <nav className="w-100">
                <div className="nav nav-tabs">
                  <a
                    className={`nav-link ${tab === Tab.SignIn ? "active" : ""}`}
                    onClick={() => setTab(Tab.SignIn)}
                  >
                    Нэвтрэх
                  </a>
                  <a
                    className={`nav-link ${tab === Tab.SignUp ? "active" : ""}`}
                    onClick={() => setTab(Tab.SignUp)}
                  >
                    Бүртгүүлэх
                  </a>
                  <a
                    className={`nav-link ${
                      tab === Tab.ResetPassword ? "active" : ""
                    }`}
                    onClick={() => setTab(Tab.ResetPassword)}
                  >
                    Нууц үг
                  </a>
                </div>
              </nav>
              <button
                ref={closeButtonRef}
                type="button"
                className="close opacity-10 fs-32 pt-1 position-absolute"
                data-dismiss="modal"
                aria-label="Close"
                style={{ right: "30px" }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body px-md-9 pb-8">
              <div className="tab-content">
                {tab === Tab.SignIn && (
                  <div
                    className={`tab-pane ${
                      tab === Tab.SignIn ? "show active" : ""
                    }`}
                  >
                    <SignIn
                      onChangeTab={(tab: Tab) => setTab(tab)}
                      onComplete={() => close()}
                    />
                  </div>
                )}
                {tab === Tab.SignUp && (
                  <div
                    className={`tab-pane ${
                      tab === Tab.SignUp ? "show active" : ""
                    }`}
                  >
                    <SignUp
                      onChangeTab={(tab: Tab) => setTab(tab)}
                      onComplete={() => close()}
                    />
                  </div>
                )}
                {tab === Tab.ResetPassword && (
                  <div
                    className={`tab-pane ${
                      tab === Tab.ResetPassword ? "show active" : ""
                    }`}
                  >
                    <ResetPassword
                      onChangeTab={(tab: Tab) => setTab(tab)}
                      onComplete={() => close()}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthWrapper;
