import React from "react";
import LotteryQrReader from "../component/LotteryQrReader";

export const lotteryPath = "/lottery";

function LotteryReader() {
  return (
    <div
      style={{
        maxWidth: "500px",
      }}
      className="mx-auto w-100 text-center"
    >
      <img
        style={{
          width: "150px",
        }}
        src="/glowing/images/smok-logo-black-2.png"
        alt="SMOK"
        className="py-4"
      />
      <hr/>
      <p className="fs-15 text-dark py-4">Та доорх улаан дөрвөлжинд тааруулж төхөөрөмжийн "Code B" QR - ийг уншуулна уу.</p>
      <LotteryQrReader />
    </div>
  );
}

export default LotteryReader;
