import React, { ReactNode, useReducer } from "react";
import {
  initialState,
  reducer,
  SpinnerAction,
  SpinnerState,
} from "./spinner.reducer";

export const SpinnerContext = React.createContext<{
  state: SpinnerState;
  dispatch: React.Dispatch<SpinnerAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export interface ProviderProps {
  children: ReactNode;
}

export const SpinnerProvider = (props: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SpinnerContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SpinnerContext.Provider>
  );
};
