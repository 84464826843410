import { v4 as uuid } from "uuid";
import { smokConstants } from "../constant/constants";
import { SystemSettings } from "../module/private/settings/model/settings.model";
import { SmokToken, SmokUser } from "../module/private/user/model/auth.model";

function getToken(): SmokToken | undefined {
  const tokenString = localStorage.getItem(smokConstants.TOKEN_KEY);

  if (tokenString !== null) {
    return JSON.parse(tokenString);
  }

  return undefined;
}

function setToken(token: SmokToken) {
  localStorage.setItem(smokConstants.TOKEN_KEY, JSON.stringify(token));
}

function getUser(): SmokUser | undefined {
  const userString = localStorage.getItem(smokConstants.USER_KEY);

  if (userString !== null) {
    return JSON.parse(userString);
  }

  return undefined;
}

function setUser(user: SmokUser) {
  localStorage.setItem(smokConstants.USER_KEY, JSON.stringify(user));
}

function setShowAgeRestriction(show: boolean) {
  localStorage.setItem(smokConstants.SHOW_AGE_RESTRICTION_KEY, String(show));
}

function getShowAgeRestriction(): boolean {
  let showAgeRestriction = localStorage.getItem(
    smokConstants.SHOW_AGE_RESTRICTION_KEY
  );

  if (showAgeRestriction == null) {
    return true;
  }

  return showAgeRestriction === "true";
}

function clearAuth() {
  localStorage.removeItem(smokConstants.USER_KEY);
  localStorage.removeItem(smokConstants.TOKEN_KEY);
}

function getUserInfoId(): string | null {
  const userInfoId = localStorage.getItem(smokConstants.USER_INFO_KEY);

  if (userInfoId == null) {
    const id: string = uuid();
    setUserInfoId(id);

    return id;
  }

  return userInfoId !== null && userInfoId?.length > 0 ? userInfoId : null;
}

function setUserInfoId(userInfoId: string) {
  localStorage.setItem(smokConstants.USER_INFO_KEY, userInfoId);
}

function clearUserInfoId() {
  localStorage.removeItem(smokConstants.USER_INFO_KEY);
}

function clear() {
  localStorage.clear();
}

function setSettings(settings: SystemSettings[]) {
  localStorage.setItem(smokConstants.SYSTEM_SETTINGS, JSON.stringify(settings));
}

function getSettings(): SystemSettings[] | undefined {
  let settings = localStorage.getItem(smokConstants.SYSTEM_SETTINGS);

  if (settings == null) {
    return undefined;
  }

  return JSON.parse(settings);
}

function getSetting(key: string): SystemSettings | undefined {
  let settings = getSettings();

  return settings?.find((x) => x.key === key);
}

const storageService = {
  getUserInfoId,
  clearAuth,
  getToken,
  setToken,
  clear,
  getUser,
  setUser,
  clearUserInfoId,
  setUserInfoId,
  setSettings,
  getSetting,
  getSettings,
  setShowAgeRestriction,
  getShowAgeRestriction,
};

export default storageService;
