import { Product } from "./product.model";
import { Variation } from "./variation.model";

export enum SmokImageType {
  Product = "PR",
  Varation = "VA",
  Catalog = "CA",
  Banner = "BA",
}

export interface SmokImage {
  type: SmokImageType;
}

export interface SmokImageReadUI extends SmokImage {
  id: number;
  variation: Variation;
  product: Product;
  image: string;
}

export interface SmokImageWriteUI extends SmokImage {
  image: File;
  product_id?: string;
  variation_id?: number;
  banner_id?: string;
}

export interface SmokImageQuery {
  type: SmokImageType;
  product_id?: string;
  variation_id?: number;
  banner_id?: string;
}
